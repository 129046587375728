import styled, { keyframes, css } from 'styled-components'
import { media } from '../../style-utils/media-queries'

interface AsideHeightProps {
  height?: string
}
interface TitleProps {
  whitespace?: boolean
}
interface JustyfyContentOption {
  justifyContent?: boolean
}
interface ToggleProps {
  changeColor: string
}

export const Video = styled.video`
  filter: brightness(45%);
  object-fit: cover;
  position: absolute;
  top: 0;
  zindex: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  &::-webkit-media-controls {
    display: none !important;
  }
`
const slideUp = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
`

export const Nav = styled.nav<{ isVisible?: boolean }>`
  animation: ${props =>
    props.isVisible
      ? css`
          ${slideDown} 0.3s ease-in-out forwards
        `
      : css`
          ${slideUp} 0.3s ease-in-out forwards
        `};
  -webkit-animation: ${props =>
    props.isVisible
      ? css`
          ${slideDown} 0.3s ease-in-out forwards
        `
      : css`
          ${slideUp} 0.3s ease-in-out forwards
        `};
  position: ${props => (props.isVisible ? 'fixed' : 'absolute')};
  top: 0;
  overflow-x: hidden;
  .align-self-start {
    align-self: flex-start;
  }
  flex-grow: 1;
  align-self: start;
  align-items: center;
  backdrop-filter: blur(6px);
  padding: 24px 48px 24px 24px;
  z-index: 30;
  top: 0;
  left: 104px;
  right: 0;
  display: flex;
  justify-content: space-between;
  ${media.largeLaptop`
  left: 0px;
  `}
  ${media.phablet`
  padding: 24px;
  `}
`

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`

export const LogoContainer = styled.div`
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 9999999999;
  & p:first-child {
    line-height: 0.8;
    font-size: ${props => props.theme.sizes.extraLarge2};
    font-weight: ${props => props.theme.font_weights.bold};
    margin: 0;
    color: ${props => props.theme.colors.secondary};
    & span {
      font-weight: bolder;
      text-transform: uppercase;
      font-size: ${props => props.theme.sizes.extraLarge2};
      color: ${props => props.theme.colors.primary['500']};
    }
  }
  & p:nth-child(2) {
    text-align: end;
    margin: 0;
    font-size: ${props => props.theme.sizes.smallMedium};
    font-weight: ${props => props.theme.font_weights.medium};
    color: ${props => props.theme.colors.secondary};
    & span {
      font-size: ${props => props.theme.sizes.smallMedium};
      color: ${props => props.theme.colors.primary['500']};
    }
  }
`

export const MenuList = styled.ul`
  display: flex;
  margin: 0 0 0 auto;
  padding: 0;
  color: ${props => props.theme.colors.secondary};
  list-style-type: none;
  ${media.laptop`
  left:50%;
   transform:translateX(-50%);
   position:absolute;
  justify-content:center;
   flex-direction:column;
  `}
  & li {
    position: relative;
    font-size: ${props => props.theme.sizes.medium};
    z-index: 10;
    margin-right: 35px;
    ${media.laptop`
    width:max-content;
    margin:0 auto 20px;
    font-size:24px;
    `}
    &::after {
      content: '';
      width: 0%;
      top: 10px;
      left: 0;
      position: absolute;
      height: 10px;
      background-color: ${props => props.theme.colors.primary['500']};
      border-radius: 10px;
      ${media.laptop`
      top: 20px;
      `}
    }
  }
  & li:hover::after {
    position: absolute;
    width: 100%;
    z-index: 0;
    transition: 0.2s ease-in;
    transition-delay: 0.2s;
  }
  & a {
    position: relative;
    z-index: 10;
    display: block;
    font-weight: ${props => props.theme.font_weights.semiBold};
    text-decoration: none;
    ${media.laptop`
    top: 0px;
    `}
  }
  & li.bigger-link {
    position: relative;
    color: ${props => props.theme.colors.primary['500']};
    font-size: ${props => props.theme.sizes.medium};
    z-index: 10;
    margin-right: 35px;
    ${media.laptop`
    width:max-content;
    margin:0 auto 20px;
    font-size:24px;
    `}
    &::after {
      content: '';
      width: 0%;
      top: 10px;
      left: 0;
      position: absolute;
      height: 10px;
      background-color: ${props => props.theme.colors.light};
      border-radius: 10px;
      ${media.laptop`
      top: 20px;
      `}
    }
  }
  & li:hover::after {
    position: absolute;
    width: 100%;
    z-index: 0;
    transition: 0.2s ease-in;
    transition-delay: 0.2s;
  }
  & a {
    position: relative;
    z-index: 10;
    display: block;
    font-weight: ${props => props.theme.font_weights.semiBold};
    text-decoration: none;
    ${media.laptop`
    top: 0px;
    `}
  }
`
export const ContactInfoContainer = styled.div`
  padding-left: 24px;
  align-self: flex-start;
  margin-left: 24px;
  border-left: 1px solid ${props => props.theme.colors.secondary};

  & p,
  & a {
    display: block;
    margin-bottom: 4px;
    font-size: ${props => props.theme.sizes.smallMedium};
    color: ${props => props.theme.colors.secondary};
    &:hover {
      transform: scale(1.03);
    }
  }
  ${media.smallDesktop`
  display:none;`}
`

export const Aside = styled.aside<AsideHeightProps>`
  width: 104px;
  background: ${props => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  ${media.largeLaptop`
 width:0px;`}
`
export const ContainerWrapper = styled.section`
  display: flex;
  align-items: stretch;
  background-color: ${props => props.theme.colors.light};
`
export const SocialList = styled.ul`
  width: 100%;
  display: flex;
  margin-top: 600px;
  padding: 0;
  color: ${props => props.theme.colors.primary['500']};
  transform: rotate(270deg);
  list-style-type: none;
  & li {
    margin-right: 50px;
  }
  & li a:hover {
    transition: 0.4s ease-in-out;
    color: ${props => props.theme.colors.light};
  }
  ${media.largeLaptop`
 opacity:0;`}
`
export const PrimaryButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

export const PrimaryButton = styled.button`
  color: ${props => props.theme.colors.secondary};
  background: ${props => props.theme.colors.primary['500']};
  border-radius: 24px;
  padding: 16px 40px 16px 40px;
  outline: none;
  cursor: pointer;
  border: none;
  font-weight: ${props => props.theme.font_weights.semiBold};
  font-size: ${props => props.theme.sizes.mediumSmall};
  line-height: 17px;
  transition: 0.2s ease-in-out;
  &:hover {
    filter: brightness(95%);
    transform: scale(1.02);
  }
`
export const HeroSectionContainer = styled.div`
  text-align: center;
  z-index: 2;
  position: absolute;
  width: 55%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${media.smallDesktop`
  width: 75%;
  `}
  ${media.laptop`
  width: 85%;
  `}
  ${media.phablet`
  width: 95%;
  `}
`
export const HeroPretitle = styled.h2`
  margin-top: 0;
  color: ${props => props.theme.colors.secondary};
  letter-spacing: ${props => props.theme.negative_values.minusTwo};
  font-weight: ${props => props.theme.font_weights.regular};
  font-size: ${props => props.theme.sizes.extraLarge};
  line-height: 1.2;
  ${media.desktop`
  width:80%;
  margin:0 auto 18px;`}
`

export const HeroSubtitle = styled.h2`
  color: ${props => props.theme.colors.secondary};
  font-weight: ${props => props.theme.font_weights.regular};
  letter-spacing: ${props => props.theme.negative_values.minusTwo};
  font-size: ${props => props.theme.sizes.largeToExtra};
  line-height: 1.2;
`

export const HeroTitle = styled.h1<TitleProps>`
  color: ${props => props.theme.colors.secondary};
  white-space: ${props => (props.whitespace ? 'pre-line' : 'nowrap')};
  font-weight: ${props => props.theme.font_weights.extraBold};
  letter-spacing: ${props => props.theme.negative_values.minusFour};
  font-size: ${props => props.theme.sizes.extraLarge7};
  margin: 0;
  line-height: ${props => props.theme.sizes.extraLarge7};
  & span {
    color: ${props => props.theme.colors.primary['500']};
  }
  ${media.tabletToLaptop`
  margin-bottom:25px;
  `}
  ${media.phablet`
  line-height:1;
  margin-bottom:25px;
  `}
`
export const HeroTitleVariation = styled(HeroTitle)`
  width: 100%;
  font-weight: ${props => props.theme.font_weights.semiBold};
  font-size: ${props => props.theme.sizes.extraLarge6};
  text-align: center;
`

export const HeroTitleBlogVariation = styled(HeroTitle)`
  ${media.tabletToLaptop`
text-align:left;
padding-left:48px;
`}
  ${media.mobile`
padding-left:24px;
`}
`

export const HeroTitleProjectsVariation = styled(HeroTitle)`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  ${media.tabletToLaptop`
text-align:left;
padding-left:48px;
`}
  ${media.mobile`
padding-left:24px;
`}
`
export const HeroTitleAboutUs = styled(HeroTitleVariation)`
  text-align: left;
  ${media.laptop`
text-align:center;
`}
`
export const HeroBlogPostVariation = styled(HeroTitle)`
  font-size: ${props => props.theme.sizes.extraLarge4};
  font-weight: ${props => props.theme.font_weights.bold};
`
export const ProjectPageHeroContainer = styled.div<JustyfyContentOption>`
  position: relative;
  align-items: center;
  gap: 72px;
  width: ${props => (props.justifyContent ? '80%' : '90%')};
  margin: auto;
  display: flex;
  justify-content: center;
  @media (min-width: 1550px) {
    max-width: 1357px;
  }
  ${media.tabletToLaptop`
  top: 46%;
    gap:0;
  flex-direction:column;
  text-align:center;
  width:100%;
 `}
`
export const ServicePageHeroContainer = styled.div<JustyfyContentOption>`
  position: relative;
  width: ${props => (props.justifyContent ? '80%' : '90%')};
  margin: ${props => (props.justifyContent ? 'auto' : 'auto')};
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? 'space-between' : 'space-around'};

  & ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
    color: ${props => props.theme.colors.secondary};

    & li {
      position: relative;
      width: max-content;
      cursor: pointer;
      z-index: 110;

      font-weight: ${props => props.theme.font_weights.light};
      font-size: ${props => props.theme.sizes.extraLarge4};
      line-height: 1.2;
      &:hover span {
        left: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        transition: 0.4s ease-in;
        mix-blend-mode: multiply;
        width: 100%;
        border-radius: 30px;
        background-color: ${props => props.theme.colors.primary['500']};
        top: ${props => props.theme.sizes.extraLarge};
        height: ${props => props.theme.sizes.large};
      }
    }
    ${media.tablet`
      margin-bottom: 3px;
      `}

    @media(max-width:980px) {
      & li:hover span {
        top: 17px;
        height: 0px;
        width: 0px;
      }
    }
  }

  @media (min-width: 1550px) {
    max-width: 1357px;
    margin: auto;
  }

  ${media.tabletToLaptop`
  top: 46%;
  flex-direction:column;
  text-align:center;
  justify-content: center;
  width:100%;
  margin: 0;
  & ul{
    width:max-content;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    margin:0 auto;
  }
  `}
`
export const Toggler = styled.div<ToggleProps>`
  & .container {
    width: 140px;
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  & p {
    margin-top: 2rem;
    font-size: 0.75rem;
    text-align: center;
  }

  & .switches-container {
    width: 65px;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: white;
    line-height: 2rem;
    border-radius: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  & .switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
  }

  & .switches-container label {
    width: 10%;
    height: 30px;
    padding: 0;
    margin: 0;
    text-align: center;
    color: ${props => props.changeColor};
  }

  & .switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 25px;
    padding: 0.15rem;
    z-index: 3;
    transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    /* transition: transform 1s; */
  }

  /* switch box highlighter */
  & .switch {
    cursor: pointer;
    border-radius: 3rem;
    background: ${props => props.changeColor};
    height: 100%;
  }

  /* slide the switch box from right to left */
  & .switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
    transform: translateX(0%);
  }

  /* slide the switch box from left to right */
  & .switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
    transform: translateX(115%);
  }

  /* toggle the switch box labels - first checkbox:checked - show first switch div */
  &
    .switches-container
    input:nth-of-type(1):checked
    ~ .switch-wrapper
    .switch
    div:nth-of-type(1) {
    opacity: 1;
  }

  /* toggle the switch box labels - second checkbox:checked - show second switch div */
  &
    .switches-container
    input:nth-of-type(2):checked
    ~ .switch-wrapper
    .switch
    div:nth-of-type(2) {
    opacity: 1;
  }
`

export const HeroPretitleVariation = styled.h2`
  width: 80%;
  margin: 0 auto;
  margin-top: 0;
  text-shadow: 1px 2px 2px ${props => props.theme.colors.primary['500']};
  color: ${props => props.theme.colors.light};
  letter-spacing: ${props => props.theme.negative_values.minusTwo};
  font-weight: ${props => props.theme.font_weights.semiBold};
  font-size: ${props => props.theme.sizes.extraLarge3};
  text-align: center;
  line-height: 1.2;
  ${media.desktop`
  width:80%;
  margin:0 auto 18px;`}
`

export const CareerPageHeroContainer = styled.div<JustyfyContentOption>`
  position: relative;
  width: ${props => (props.justifyContent ? '80%' : '90%')};
  margin: ${props => (props.justifyContent ? 'auto' : 'auto')};
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? 'space-between' : 'space-around'};

  & ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
    color: ${props => props.theme.colors.secondary};

    & li {
      position: relative;
      width: max-content;
      cursor: pointer;
      z-index: 110;

      font-weight: ${props => props.theme.font_weights.light};
      font-size: ${props => props.theme.sizes.extraLarge4};
      line-height: 1.2;
      &:hover span {
        left: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        transition: 0.4s ease-in;
        mix-blend-mode: multiply;
        width: 100%;
        border-radius: 30px;
        background-color: ${props => props.theme.colors.primary['500']};
        top: ${props => props.theme.sizes.extraLarge};
        height: ${props => props.theme.sizes.large};
      }
    }
    ${media.tablet`
      margin-bottom: 3px;
      `}

    @media(max-width:980px) {
      & li:hover span {
        top: 17px;
        height: 0px;
        width: 0px;
      }
    }
  }

  @media (min-width: 1550px) {
    max-width: 1357px;
    margin: auto;
  }

  ${media.tabletToLaptop`
  top: 46%;
  flex-direction:column;
  text-align:center;
  justify-content: center;
  width:100%;
  margin: 0;
  & ul{
    width:max-content;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    margin:0 auto;
  }
  `}
`
