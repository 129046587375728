import { ScrollToTopContainer } from "./loading-component.styled";
import React, { useEffect, useState } from "react";

import arrowUp from "./../../img/up-arrow.svg";

export const ScrollToTopFunction = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 1200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds smooth scrolling animation
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ScrollToTopContainer
      style={{ display: isVisible ? "flex" : "none" }}
      onClick={scrollToTop}
    >
      <img src={arrowUp} alt="arrow-up-sign" />
    </ScrollToTopContainer>
  );
};
