import React from "react";
export interface IconProps {
  color?: string;
}
export const Quality = ({ color }: IconProps) => {
  return (
    <svg
      style={{ display: "block" }}
      width="100%"
      viewBox="0 0 42 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7 24.3999L0.5 13.1999L2.65 11.0499L11.7 20.0999L13.85 22.2499L11.7 24.3999ZM20.2 24.3999L9 13.1999L11.15 11.0499L20.2 20.0999L39.4 0.899902L41.55 3.0499L20.2 24.3999ZM20.2 15.8999L18.05 13.7499L30.9 0.899902L33.05 3.0499L20.2 15.8999Z"
        fill={color}
      />
    </svg>
  );
};
