import React, { useContext } from 'react'
import { Navigation } from '../components/navigation/Navigation'
import { HeroSection } from '../components/navigation/HeroSection'
import {
  HeroTitleProjectsVariation,
  ProjectPageHeroContainer
} from '../components/navigation/navigation.styled'
import { ProjectHeroParagraph } from '../components/project-components/projects.styled'
import { clipPathVariable } from '../constants/constants'
import { Context } from '../context/ProjectPageDataContext'
import { Context as ProjectsContext } from '../context/ProjectsContext'
import { GetAQuoteComponent } from '../components/contact-us-components/GetAQuoteComponent'
import { IndividualProjectVariation } from '../components/project-components/IndividualProjectVariation'
import { useMediaQuery } from 'react-responsive'
import { Helmet } from 'react-helmet'
export const ProjectsPage = () => {
  const breakpoint = useMediaQuery({ maxWidth: 850 })
  const { projects } = useContext(ProjectsContext)

  const { projectspage } = useContext(Context)

  return (
    <>
      <Helmet>
        <title>Moxelle | Projects</title>
        <meta
          name='description'
          content='Project goals encompass optimizing user-friendly booking processes, prioritizing peak performance, reinforcing data security, and seamless integration with relevant platforms. By establishing these objectives, the project ensures efficient bookings, heightened user confidence, and a foundation for scalability and success.'
        />
      </Helmet>
      <Navigation>
        <HeroSection
          clipPath={clipPathVariable}
          url={
            'https://res.cloudinary.com/dfolafoax/image/upload/v1695121078/projects-bg-img_btsquo.webp'
          }
        >
          <ProjectPageHeroContainer
            style={{
              justifyContent: breakpoint ? 'center' : 'space-between',
              marginTop: breakpoint ? '110px' : undefined,
              alignItems: breakpoint ? 'flex-start' : 'center'
            }}
          >
            <div>
              <HeroTitleProjectsVariation
                whitespace
                dangerouslySetInnerHTML={{
                  __html: projectspage ? projectspage.banner_title : ''
                }}
              />
            </div>
            <div>
              {projectspage && projectspage.banner_paragraphOne && (
                <>
                  <ProjectHeroParagraph
                    dangerouslySetInnerHTML={{
                      __html: projectspage.banner_paragraphOne
                    }}
                  ></ProjectHeroParagraph>
                </>
              )}
            </div>
          </ProjectPageHeroContainer>
        </HeroSection>
      </Navigation>
      {projects &&
        projects.map((project, i) => (
          <IndividualProjectVariation key={i} {...project} />
        ))}
      <GetAQuoteComponent />
    </>
  )
}
