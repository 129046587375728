import React from "react";
import styled from "styled-components";
import { COLORS } from "../../constants/constants";

export const ScrollToTopContainer = styled.div`
  border-radius: 50%;
  z-index: 99999999;
  -webkit-tap-highlight-color: transparent;
  border: 2px solid ${(props) => props.theme.sizes.dark};
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.light};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 25px;
  font-weight: bolder;
  position: fixed;
  bottom: 20px;
  right: 30px;
  & > img {
    width: 35%;
    color: ${(props) => props.theme.sizes.dark};
    margin: 0;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  @media(min-width:950px){
    &:hover img {
      animation: x 0.5s cubic-bezier(0, 4, 1, 4) infinite;
      @keyframes x {
        to {
          margin-bottom: 1px;
        }
      }
    }
  }

`;
export const SpinnerSvg = styled.svg`
  position: fixed;
  top: 40%;
  left: calc(50% - 32.5px);
  transform: translate(-50%, -50%);
  z-index: 999999;
  animation: rotator 1.4s linear infinite;

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }
`;

export const SpinnerPath = styled.circle`
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;

  @keyframes colors {
    0% {
      stroke: ${COLORS.primary["500"]};
    }
    25% {
      stroke: ${COLORS.secondary};
    }
    50% {
      stroke: ${COLORS.primary["500"]};
    }
    75% {
      stroke: ${COLORS.secondary};
    }
    100% {
      stroke: ${COLORS.primary["500"]};
    }
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }
`;
