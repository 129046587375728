import React, { useContext, useEffect, useRef } from 'react'
import { Navigation } from '../components/navigation/Navigation'
import { HeroSection } from '../components/navigation/HeroSection'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {
  ContainerWrapper,
  HeroTitle,
  PrimaryButton,
  ServicePageHeroContainer
} from '../components/navigation/navigation.styled'
import { ServiceListComponent } from '../components/services-components/ServiceListComponent'
import { useMediaQuery } from 'react-responsive'
import { clipPathVariable } from '../constants/constants'
import { CSSProperties } from 'styled-components'
import consultingDev from './../img/services-page-images/consultingDevelopment.svg'
import managementDev from './../img/services-page-images/managementDevelopment.svg'
import recruitmentDev from './../img/services-page-images/marketing.svg'
import mobileDev from './../img/services-page-images/mobileDevelopment.svg'
import qualityDev from './../img/services-page-images/qualityManagement.svg'
import webDev from './../img/services-page-images/webDevelopment.svg'
import solutionsDev from './../img/services-page-images/solutionsManagement.svg'
import uxDev from './../img/services-page-images/uxDevelopment.svg'
import { Context } from '../context/ServicesContext'
import { GetAQuoteComponent } from '../components/contact-us-components/GetAQuoteComponent'
import { AsideNoText } from '../components/services-components/services.styled'
import { SectionWrapper } from '../components/about-us-components/aboutus.styled'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

interface ServicesPageInt {
  currentService: string
  setCurrentService: (service: string) => void
}

export const ServicesPage = ({
  currentService,
  setCurrentService
}: ServicesPageInt) => {
  const { servicesPage } = useContext(Context)
  // const { homepage } = useContext(HomePageContext);
  const isTablet = useMediaQuery({ maxWidth: 900 })
  const isLargeTablet = useMediaQuery({ maxWidth: 850 })
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const development: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null)
  const design: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
  const management: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
  const consulting: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
  const custom_solutions: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null)
  const qa: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
  const recruitment: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null)
  const refArray: React.MutableRefObject<HTMLDivElement | null>[] = [
    recruitment,
    development,
    custom_solutions,
    consulting,
    design,
    management,
    qa
  ]

  useEffect(() => {
    AOS.init({
      duration: 350, // Animation duration in milliseconds
      once: true, // Whether the animation should only occur once
      easing: 'ease-out' // Animation easing (CSS transition timing function)
    })

    // Optional: Refresh AOS whenever new content is added dynamically
    AOS.refresh()

    // Clean up AOS on component unmount
    return () => {
      AOS.refresh()
    }
  }, [])

  const scrollToContainer = (index: number) => {
    const currentRef = refArray.find((_, i) => i === index)
    if (currentRef && currentRef.current) {
      currentRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const servicesArray: string[] = [
    'Recruitment',
    'Development',
    'Custom Solutions',
    'Consulting',
    'Design',
    'Management',
    'QA'
  ]

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (currentService && servicesPage) {
      timeout = setTimeout(() => {
        const currentEl = servicesArray.findIndex(
          el =>
            el.replaceAll(' ', '').toLowerCase() ===
            currentService.replaceAll(' ', '').toLowerCase()
        )

        const currentRef = refArray.find((_, i) => i === currentEl)
        if (currentRef && currentRef.current) {
          currentRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      }, 350)
    }

    return () => {
      clearTimeout(timeout)
      setCurrentService('')
    }
  }, [])

  const imagesArray = [
    recruitmentDev,
    mobileDev,
    webDev,
    solutionsDev,
    consultingDev,
    uxDev,
    managementDev,
    qualityDev
  ]
  const spaceBetweenRight = `0 0 0 ${isTablet ? '0' : '7%'}`
  const spaceBetweenLeft = `0 ${isTablet ? '0' : '7%'} 0 0`
  const flexDirection: CSSProperties = {
    display: 'flex',
    flexDirection: isTablet ? 'column' : 'row'
  }
  const flexDirectionReversed: CSSProperties = {
    flexDirection: isTablet ? 'column' : 'row-reverse',
    display: 'flex'
  }
  return (
    <>
      <Helmet>
        <title>Moxelle | Services</title>
        <meta
          name='description'
          content='Recruitment
          Development
          Custom Solutions
          Consulting
          Design
          Management
          QA'
        />
      </Helmet>
      <Navigation>
        <HeroSection
          clipPath={clipPathVariable}
          url={
            'https://res.cloudinary.com/dfolafoax/image/upload/v1695121128/services-bg-img_leib7n.webp'
          }
        >
          <ServicePageHeroContainer>
            <div style={{ marginTop: isLargeTablet ? '0px' : '50px' }}>
              <HeroTitle
                whitespace
                style={{
                  textAlign: 'left',
                  marginLeft: isMobile ? '12.5%' : isLargeTablet ? '25%' : '0px'
                }}
              >
                Our <br />
                Services
              </HeroTitle>
            </div>
            <div>
              <ul>
                {servicesArray.map((service, i) => (
                  <li onClick={() => scrollToContainer(i)} key={service}>
                    {service} <span></span>
                  </li>
                ))}
              </ul>
            </div>
          </ServicePageHeroContainer>
        </HeroSection>
      </Navigation>
      {servicesPage &&
        servicesPage
          .sort((a, b) => (parseInt(a.index) < parseInt(b.index) ? -1 : 1))
          .map((service, i) => (
            <div
              key={i}
              ref={i < 2 ? refArray[i] : i === 2 ? undefined : refArray[i - 1]}
            >
              <ServiceListComponent
                space_between={
                  i % 2 === 0 ? spaceBetweenRight : spaceBetweenLeft
                }
                css={i % 2 === 0 ? flexDirection : flexDirectionReversed}
                title={service.title}
                technologiesSection={
                  i === 1
                    ? 'mobile'
                    : i === 2
                    ? 'web'
                    : i === 5
                    ? 'design'
                    : i === 6
                    ? 'cloud'
                    : ''
                }
                expertise={service.expertise}
                question={service.question}
                answer={service.answer}
                img={imagesArray[i]}
              />
            </div>
          ))}
      <ContainerWrapper>
        <AsideNoText />
        <SectionWrapper style={{ display: 'flex', justifyContent: 'center' }}>
          <Link data-aos='fade-in' to='/projects'>
            <PrimaryButton style={{ padding: '16px 36px' }}>
              View Our Projects
            </PrimaryButton>
          </Link>
        </SectionWrapper>
      </ContainerWrapper>
      <GetAQuoteComponent />
      {/* <ContactUsComponent
        address={{
          macedonia: homepage ? homepage.address_mk : "",
          us: homepage ? homepage.address_us : "",
        }}
      /> */}
    </>
  )
}
