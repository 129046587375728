import React, { useContext } from "react";
import {
  FollowUsContainer,
  FooterLogoContainer,
  ListContainer,
  MoxelleCopyRightsText,
  StyledFooter,
  StyledFooterWrapper,
  TransitionContainer,
} from "./footer.styled";
import { Link } from "react-router-dom";
import { FacebookIcon } from "./social-icons/FacebookIcon";
import { TwitterIcon } from "./social-icons/Twitter";
import { InstagramIcon } from "./social-icons/InstagramIcon";
import { LinkedInIcon } from "./social-icons/LinkedInIcon";
import scratch from "./../../img/footer-line.svg";
import { footerScratchStyle } from "../../style-utils/inline-styles";
import logoDark from "./../../img/moxelle-logo/logo-dark.svg";
import logoLight from "./../../img/moxelle-logo/logo-light.svg";
import { ToggleContext } from "../../theme/ThemeContext";
import { useMediaQuery } from "react-responsive";

export const Footer = () => {
  const { toggleValue } = useContext(ToggleContext);
  const isTablet = useMediaQuery({ maxWidth: 1280, minWidth: 850 });
  const isMobile = useMediaQuery({ maxWidth: 849 });
  return (
    <StyledFooterWrapper>
      <StyledFooter>
        <FooterLogoContainer>
          <img src={toggleValue ? logoDark : logoLight} alt="moxelle-logo" />
        </FooterLogoContainer>
        <ListContainer>
          <ul className="contact" data-header="Contact">
            <li>
              <img
                loading="lazy"
                style={{
                  ...footerScratchStyle,
                  display: isTablet ? "none" : "block",
                  transform: isMobile ? "rotate(0deg)" : "rotate(-90deg)",
                  top: isMobile ? "95px" : "30px",
                  left: isMobile ? "-30px" : "-120px",
                }}
                src={scratch}
                alt="scretch_mark"
              />
            </li>
            <li>
              <a href="mailto:info@moxelle.com">info@moxelle.com</a>
            </li>
            <li>
              <a href="tel:+19543104844">{"+ 1 (954) 310-4844"}</a>
            </li>
          </ul>
          <ul className="company" data-header="Company">
            <li>
              <img
                loading="lazy"
                style={{
                  ...footerScratchStyle,
                  transform: isMobile ? "rotate(0deg)" : "rotate(-90deg)",
                  top: isMobile ? "115px" : "30px",
                  left: isMobile ? "-30px" : "-120px",
                }}
                src={scratch}
                alt="scretch_mark"
              />
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
          <ul className="links" data-header="Links">
            <li>
              <img
                loading="lazy"
                style={{
                  ...footerScratchStyle,
                  transform: isMobile ? "rotate(0deg)" : "rotate(-90deg)",
                  top: isMobile ? "85px" : "30px",
                  left: isMobile ? "-30px" : "-120px",
                }}
                src={scratch}
                alt="scretch_mark"
              />
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/career">Career</Link>
            </li>
          </ul>
        </ListContainer>
        <FollowUsContainer>
          <div className="flexible-div">
            <span>Follow us</span>
            <TransitionContainer>
              <FacebookIcon />
              <InstagramIcon />
              <LinkedInIcon />
              <TwitterIcon />
            </TransitionContainer>
            <MoxelleCopyRightsText>
              @Moxelle all rights reserved
            </MoxelleCopyRightsText>
          </div>
        </FollowUsContainer>
      </StyledFooter>
    </StyledFooterWrapper>
  );
};
