import React, { useEffect } from "react";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import {
  BodyContentWrapper,
  CheckboxContainer,
  CheckboxInput,
  CheckboxLabel,
  CustomBtn,
  ModalContent,
  ModalWrapper,
  ProgressBarContainer,
  RadioContainer,
  RadioInput,
  RadioLabel,
  StyledInput,
  StyledTextarea,
} from "./create-team.styled";
import {
  devRolesArrVariation,
  experienceLevel,
  industries,
  profiles,
} from "./servicesArray";
import { COLORS, apiRoute } from "../../../constants/constants";
import { useMediaQuery } from "react-responsive";
import { InputContainer } from "../../admin-panel/form.styled";
import { LoadingComponent } from "../../loading-component/LoadingComponent";

enum STEPS {
  ROLES = 1,
  PROFILES = 2,
  INDUSTRY = 3,
  EXPERIENCE = 4,
  SEND = 5,
}
interface FieldValues {
  type: string[];
  roles: string[];
  industry: string;
  experience: string;
  info: {
    last_name: string;
    first_name: string;
    email: string;
    company_role: string;
    company_name: string;
    phone: string;
    description: string;
  };
}

export const RecruitmentForm = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState<number>(STEPS.ROLES);
  const [otherType, setOthersType] = useState("");
  const [otherIndustry, setOtherIndustry] = useState("");
  const [selectedType, setSelectedTypes] = useState<string[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const breakpointSmall = useMediaQuery({ maxWidth: 600 });
  const breakpoint = useMediaQuery({ minWidth: 601, maxWidth: 800 });
  const breakpointLarge = useMediaQuery({ minWidth: 801, maxWidth: 1024 });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<FieldValues>({
    defaultValues: {
      type: [],
      roles: [],
      industry: "",
      experience: "",
      info: {
        last_name: "",
        first_name: "",
        email: "",
        company_role: "",
        company_name: "",
        phone: "",
        description: "",
      },
    },
  });

  const roles = watch("roles");
  const experience = watch("experience");
  const industry = watch("industry");
  const type = watch("type");

  const setCustomValue = (id: string, value: any) => {
    setValue(id as keyof FieldValues, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setErrorMessage("");
  };

  const handleTypeValues = (value: string) => {
    selectedType.includes(value)
      ? setSelectedTypes(selectedType.filter((item) => item !== value))
      : setSelectedTypes([...selectedType, value]);
  };
  const handleRoleValues = (value: string) => {
    selectedRoles.includes(value)
      ? setSelectedRoles(selectedRoles.filter((item) => item !== value))
      : setSelectedRoles([...selectedRoles, value]);
  };

  const onBack = () => {
    setStep((value) => value - 1);
    setErrorMessage("");
  };
  const onNext = () => {
    if (step === STEPS.ROLES && roles.length === 0) {
      setErrorMessage("Select one of the options to continue");
      return;
    } else if (step === STEPS.PROFILES && type.length === 0) {
      setErrorMessage("Select one of the options to continue");
      return;
    } else if (step === STEPS.INDUSTRY && industry === "") {
      setErrorMessage("Select one of the options to continue");
      return;
    } else if (step === STEPS.EXPERIENCE && experience === "") {
      setErrorMessage("Select one of the options to continue");
      return;
    }
    setStep((value) => value + 1);
  };
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {


    if (step !== STEPS.SEND) {
      return onNext();
    }
    setIsLoading(true);
    const modifiedData = {
      ...data,
      industry: otherIndustry !== "" ? otherIndustry : data.industry,
    };
    await axios
      .post(`${apiRoute}api/reqruitment`, modifiedData)
      .then(() => {
        toast.success("The team was created successfully");
        reset();
        setSelectedTypes([]);
        setSelectedRoles([]);
        setOtherIndustry("");
        setOthersType("");
        setStep(STEPS.ROLES);
      })
      .catch(() => {
        toast.error("Something went wront.Please try again");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    setCustomValue("roles", selectedRoles);
  }, [selectedRoles]);

  useEffect(() => {
    if (otherType.length < 51) {
      if (otherType !== "") {
        const mappedResult = selectedType.map((type) =>
          type === "Others" ? otherType : type
        );
        setCustomValue("type", mappedResult);
      } else {
        setCustomValue("type", selectedType);
      }
    }
  }, [selectedType, otherType]);

  let bodyContent = (
    <>
      <h2>What role you want to recruit for?</h2>
      <div>
        {devRolesArrVariation.map((dev, i) => (
          <CheckboxContainer key={i}>
            <CheckboxInput
              type="checkbox"
              id={dev}
              name="roles"
              value={dev}
              checked={selectedRoles.includes(dev)}
              onChange={(e) => handleRoleValues(e.target.value)}
            />
            <CheckboxLabel htmlFor={dev} />
            <p className="labels" onClick={() => handleRoleValues(dev)}>
              {dev.replace("and", "&")}
            </p>
          </CheckboxContainer>
        ))}
      </div>
    </>
  );

  if (step === STEPS.PROFILES) {
    bodyContent = (
      <>
        <h2>What profiles do you need?</h2>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {profiles.map((profile, i) => (
            <CheckboxContainer
              style={{
                flexBasis: breakpointSmall
                  ? "100%"
                  : breakpoint
                  ? "50%"
                  : breakpointLarge
                  ? "33.333%"
                  : "25%",
              }}
              key={i}
            >
              <CheckboxInput
                type="checkbox"
                id={profile}
                name="type"
                value={profile}
                checked={selectedType.includes(profile)}
                onChange={(e) => handleTypeValues(e.target.value)}
              />
              <CheckboxLabel htmlFor={profile} />
              <p className="labels" onClick={() => handleTypeValues(profile)}>
                {profile}
              </p>
            </CheckboxContainer>
          ))}
          {selectedType.includes("Others") && (
            <div style={{ flexGrow: 1, marginTop: "12px" }}>
              <InputContainer>
                <StyledTextarea
                  maxLength={50}
                  value={otherType}
                  onChange={(e) => setOthersType(e.target.value)}
                  rows={3}
                  placeholder="Please specify the profiles"
                />
              </InputContainer>
            </div>
          )}
        </div>
      </>
    );
  }
  if (step === STEPS.INDUSTRY) {
    bodyContent = (
      <>
        <h2>What is your business industry?</h2>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {industries.map((currentIndustry, i) => (
            <RadioContainer
              style={{
                flexBasis: breakpointSmall
                  ? "100%"
                  : breakpoint
                  ? "50%"
                  : breakpointLarge
                  ? "33.333%"
                  : "25%",
              }}
              key={i}
            >
              <RadioInput
                type="radio"
                id={currentIndustry}
                name="industry"
                value={currentIndustry}
                checked={industry === currentIndustry}
                onChange={(value) =>
                  setCustomValue("industry", value.target.value)
                }
              />
              <RadioLabel htmlFor={currentIndustry} />
              <p
                className="labels"
                onClick={() => setCustomValue("industry", currentIndustry)}
              >
                {currentIndustry.replace("and", "&")}
              </p>
            </RadioContainer>
          ))}
          {industry === "Others" && (
            <div style={{ flexGrow: 1, marginTop: "12px" }}>
              <InputContainer>
                <StyledTextarea
                  maxLength={50}
                  value={otherIndustry}
                  onChange={(e) => setOtherIndustry(e.target.value)}
                  rows={3}
                  placeholder="Please specify the industry"
                />
              </InputContainer>
            </div>
          )}
        </div>
      </>
    );
  }
  if (step === STEPS.EXPERIENCE) {
    bodyContent = (
      <>
        <h2>What level of experience you need for your team?</h2>
        <div>
          {experienceLevel.map(({ name, desc }, i) => (
            <RadioContainer key={i}>
              <RadioInput
                type="radio"
                id={name}
                name="radioOptions"
                value={name}
                checked={experience === name}
                onChange={(value) =>
                  setCustomValue("experience", value.target.value)
                }
              />
              <RadioLabel htmlFor={name} />
              <p
                className="labels"
                onClick={() => setCustomValue("experience", name)}
              >
                {name} {desc}
              </p>
            </RadioContainer>
          ))}
        </div>
      </>
    );
  }
  if (step === STEPS.SEND) {
    bodyContent = (
      <div>
        <h2>Let's kick off your project!</h2>
        <InputContainer>
          <div>
            <StyledInput
              {...register("info.first_name", { required: true })}
              type="text"
              placeholder="Your Name *"
            />
            {errors.info && errors.info.first_name && (
              <p>Please enter your name</p>
            )}
          </div>
          <div>
            <StyledInput
              {...register("info.last_name", { required: true })}
              type="text"
              placeholder="Your Surname *"
            />
            {errors.info && errors.info.last_name && (
              <p>Please enter your surname</p>
            )}
          </div>
        </InputContainer>
        <InputContainer>
          <div>
            <StyledInput
              {...register("info.email", { required: true })}
              type="email"
              placeholder="Your E-mail *"
            />
            {errors.info && errors.info.email && <p>Please enter your email</p>}
          </div>
          <div>
            <StyledInput
              {...register("info.phone", {
                required: true,
                pattern: /^[0-9+]+$/,
              })}
              type="phone"
              placeholder="Your Phone Number *"
            />
            {errors.info && errors.info.phone && (
              <p>Please enter your phone number</p>
            )}
          </div>
        </InputContainer>
        <InputContainer>
          <div>
            <StyledInput
              {...register("info.company_name")}
              type="text"
              placeholder="Your Company Name"
            />
            {/* {errors.info && errors.info.company_name && (
              <p>Please enter your company name</p>
            )} */}
          </div>
          <div>
            <StyledInput
              {...register("info.company_role")}
              type="text"
              placeholder="Your Company Role"
            />
          </div>
        </InputContainer>
        <StyledTextarea
          rows={4}
          {...register("info.description", { required: true })}
          placeholder="Tell us about your project or the challenge you have *"
        />
        {errors.info && errors.info.description && (
          <p style={{ color: COLORS.error }}>The input field is required</p>
        )}
      </div>
    );
  }
  const disableButtons = () => {
    switch (true) {
      case step === 1 && roles.length === 0:
        return true;
      case step === 2 && type.length === 0:
        return true;
      case step === 3 && industry === "":
        return true;
      case step === 4 && experience === "":
        return true;
    }
  };
  return (
    <ModalWrapper
      changeWidth={step === STEPS.INDUSTRY || step === STEPS.PROFILES}
    >
      {isLoading && <LoadingComponent />}
      <ModalContent>
        <h2>Recruitment</h2>
        <ProgressBarContainer>
          <div
            className="inner-bar"
            style={{
              width: `calc(${step * (100 / STEPS.SEND)}%)`,
              transition: "width 0.4s ease-in",
            }}
          ></div>
        </ProgressBarContainer>
        <div>
          {!breakpointSmall &&
            Object.entries(STEPS).map(
              (enum_step, i) =>
                i > 4 && (
                  <CustomBtn
                    disabled={i - 4 > step && disableButtons()}
                    style={{
                      backgroundColor:
                        i - 4 > step ? COLORS.light : COLORS.primary["500"],
                      color:
                        i - 4 > step ? COLORS.primary["500"] : COLORS.secondary,
                    }}
                    onClick={() => {
                      errorMessage && setErrorMessage("");
                      setStep(STEPS[enum_step[0] as keyof typeof STEPS]);
                    }}
                    key={i}
                  >
                    <span className="tooltiptext">
                      Select one of the options to continue
                    </span>
                    {enum_step[0]}
                  </CustomBtn>
                )
            )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BodyContentWrapper>
            {bodyContent}
            {errorMessage && (
              <span
                style={{
                  color: COLORS.error,
                  marginBottom: "16px",
                  display: "block",
                }}
              >
                {errorMessage}
              </span>
            )}
          </BodyContentWrapper>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {step > 1 && (
              <CustomBtn type="button" onClick={onBack}>
                Back
              </CustomBtn>
            )}
            <CustomBtn disabled={isLoading} type="submit">
              {step !== STEPS.SEND ? "Next" : "Submit request"}
            </CustomBtn>
          </div>
        </form>
        {isLoading && <LoadingComponent />}
      </ModalContent>
    </ModalWrapper>
  );
};
