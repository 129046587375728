import React from "react";
import { useMediaQuery } from "react-responsive";
interface Props {
  color: string;
}
export const LocationIcon = ({ color }: Props) => {
  const breakpoint = useMediaQuery({maxWidth:900})
  return (
    <svg
      style={{ flexGrow: 0, flexShrink: 0 }}
      width={breakpoint?"26":"32"}
      height={breakpoint?"34":"40"}
      viewBox="0 0 32 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0044 18.6346C16.8655 18.6346 17.6025 18.3267 18.2153 17.7109C18.8281 17.0951 19.1346 16.3567 19.1346 15.4955C19.1346 14.6344 18.8267 13.8974 18.2109 13.2846C17.5951 12.6718 16.8567 12.3654 15.9955 12.3654C15.1344 12.3654 14.3974 12.6732 13.7846 13.289C13.1718 13.9048 12.8654 14.6432 12.8654 15.5044C12.8654 16.3655 13.1732 17.1025 13.789 17.7153C14.4048 18.3281 15.1432 18.6346 16.0044 18.6346ZM15.9999 36.0885C20.382 32.1833 23.6826 28.4769 25.9019 24.9692C28.1211 21.4615 29.2307 18.4384 29.2307 15.8999C29.2307 11.9095 27.9566 8.64007 25.4084 6.0917C22.8603 3.54333 19.7241 2.26915 15.9999 2.26915C12.2758 2.26915 9.13962 3.54333 6.59145 6.0917C4.04325 8.64007 2.76915 11.9095 2.76915 15.8999C2.76915 18.4384 3.89737 21.4615 6.1538 24.9692C8.4102 28.4769 11.6922 32.1833 15.9999 36.0885ZM15.9999 39.1153C10.7871 34.5871 6.90062 30.3839 4.34035 26.5057C1.78012 22.6275 0.5 19.0922 0.5 15.8999C0.5 11.0538 2.06025 7.19228 5.18075 4.31535C8.30125 1.43845 11.9076 0 15.9999 0C20.0922 0 23.6986 1.43845 26.8191 4.31535C29.9396 7.19228 31.4999 11.0538 31.4999 15.8999C31.4999 19.0922 30.2198 22.6275 27.6596 26.5057C25.0993 30.3839 21.2127 34.5871 15.9999 39.1153Z"
        fill={color}
      />
    </svg>
  );
};
