import React, { MutableRefObject } from "react";
import {
  CategoryButton,
  MainCategoryButtonsContainer,
} from "./create-team.styled";
import { useMediaQuery } from "react-responsive";
interface IMainCategory {
  setMainCategory: React.Dispatch<React.SetStateAction<string>>;
  mainCategory: string;
}

export const MainOptionsCategory = ({
  setMainCategory,
  mainCategory,
}: IMainCategory) => {
  const breakpoint = useMediaQuery({ minWidth: 768 });
  return (
    <MainCategoryButtonsContainer>
      <CategoryButton
        selected={mainCategory === "DedicatedTeam"}
        onClick={() => setMainCategory("DedicatedTeam")}
      >
        {mainCategory === "DedicatedTeam" && <span className="dot"></span>}
        <p>Dedicated Team</p>
      </CategoryButton>
      <CategoryButton
        selected={mainCategory === "ManagedTeam"}
        onClick={() => setMainCategory("ManagedTeam")}
      >
        {mainCategory === "ManagedTeam" && <span className="dot"></span>}
        <p>Managed Project</p>
      </CategoryButton>
      <CategoryButton
        selected={mainCategory === "Recruitment"}
        onClick={() => setMainCategory("Recruitment")}
      >
        {mainCategory === "Recruitment" && <span className="dot"></span>}
        <p>Recruitment</p>
      </CategoryButton>
    </MainCategoryButtonsContainer>
  );
};
