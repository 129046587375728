import React, { useEffect, useRef, useState } from "react";
import {
  Aside,
  ContainerWrapper,
  PrimaryButton,
} from "../navigation/navigation.styled";
import { AboutUsSideText, SectionWrapper } from "./aboutus.styled";
import AOS from "aos";
import "aos/dist/aos.css";
import { StatsParagraphContainer } from "../project-components/projects.styled";
import { Link } from "react-router-dom";

interface AbotUsComponentProps {
  stats_paragraph_containerOne: string;
}

export const Stats = ({
  stats_paragraph_containerOne,
}: AbotUsComponentProps) => {
  const sideRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const elementRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const [sideHeight, setSideHeight] = useState(600);

  useEffect(() => {
    AOS.init({
      duration: 350,
      once: true,
      easing: "ease-out",
    });

    AOS.refresh();
    return () => {
      AOS.refresh();
    };
  }, []);

  useEffect(() => {
    if (elementRef.current) {
      setSideHeight(elementRef.current.clientHeight);
    }
  }, [sideHeight, stats_paragraph_containerOne]);

  return stats_paragraph_containerOne ? (
    <ContainerWrapper>
      <Aside ref={sideRef}>
        <AboutUsSideText margin={`calc(${sideHeight - 100}px)0 0 0`}>
          Why Us
        </AboutUsSideText>
      </Aside>
      <SectionWrapper>
        <StatsParagraphContainer>
          <div
            ref={elementRef}
            dangerouslySetInnerHTML={{
              __html: stats_paragraph_containerOne
                ? stats_paragraph_containerOne
                : "",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Link to="/get-a-quote">
              <PrimaryButton>Let's talk</PrimaryButton>
            </Link>
          </div>
        </StatsParagraphContainer>
      </SectionWrapper>
    </ContainerWrapper>
  ) : null;
};
