import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Aside, ContainerWrapper } from "../navigation/navigation.styled";
import {
  AboutUsSideText,
  SectionWrapper,
  SingleTechnologyIcon,
  TechnologyIconsContainer,
} from "../about-us-components/aboutus.styled";
import {
  IndividualProjectFlexContainer,
  IndividualProjectTextContainerVariation,
  ProjectImagesWrapper,
  ProjectParagraph,
  ProjectParagraphLarge,
  ProjectSubtitleDynamicText,
  ProjectsImageContainer,
} from "./projects.styled";
import { useMediaQuery } from "react-responsive";
import { DeviceDetector } from "./DeviceDetector";
import ImageModal from "./ImageModal";
interface Props {
  title: string;
  projectTitle?: string;
  sideText: string;
  projectsDescription: string;
  children?: ReactNode;
}
interface Technology {
  name: string;
  value: { public_id: string; url: string };
}
interface Images {
  public_id: string;
  url: string;
}

export interface ProjectsInterface {
  _id: string;
  title: string;
  side_text: string;
  company_profile: string;
  project_goals: string;
  technologies: Technology[];
  links: { ios: string; android: string; desktop: string };
  images: Images[];
}

export const IndividualProjectVariation = ({
  title,
  side_text,
  company_profile,
  project_goals,
  technologies,
  links,
  images,
}: ProjectsInterface) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const sideRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const elementRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const [sideHeight, setSideHeight] = useState(1000);
  const isSmallDesktop = useMediaQuery({ maxWidth: 1080 });
  const isMediumDesktop = useMediaQuery({ maxWidth: 1180 });
  const isLargeDesktop = useMediaQuery({ maxWidth: 1280 });
  const isExtraLargeDesktop = useMediaQuery({ maxWidth: 1400 });
  useEffect(() => {
    if (sideRef.current) {
      setSideHeight(sideRef.current.clientHeight);
    }
  }, [
    sideHeight,
    isSmallDesktop,
    isMediumDesktop,
    isLargeDesktop,
    isExtraLargeDesktop,
  ]);

  const openModal = (src: string) => {
    setImageSrc(src);
    setModalOpen(true);
  };

  const closeModal = () => {
    setImageSrc("");
    setModalOpen(false);
  };
  return (
    <ContainerWrapper>
      <Aside ref={sideRef}>
        <AboutUsSideText
          ref={elementRef}
          margin={`calc(${sideHeight - 100}px)0 0 0`}
        >
          {side_text}
        </AboutUsSideText>
      </Aside>
      <SectionWrapper>
        <ProjectSubtitleDynamicText
          data-aos="zoom-in"
          style={{ paddingTop: title === "Culture Trip" ? "78px" : "0" }}
        >
          {title}
          <span data-aos="zoom-in"></span>
        </ProjectSubtitleDynamicText>
        {company_profile && (
          <>
            <ProjectParagraphLarge data-aos="zoom-in">
              Company profile
            </ProjectParagraphLarge>
            <ProjectParagraph
              dangerouslySetInnerHTML={{ __html: company_profile }}
              data-aos="zoom-in"
            ></ProjectParagraph>
          </>
        )}
        <IndividualProjectFlexContainer style={{ padding: "0 0 12px 0" }}>
          <IndividualProjectTextContainerVariation>
            {project_goals && (
              <>
                <ProjectParagraphLarge data-aos="zoom-in">
                  Project Goals
                </ProjectParagraphLarge>
                <ProjectParagraph
                  data-aos="zoom-in"
                  dangerouslySetInnerHTML={{ __html: project_goals }}
                ></ProjectParagraph>
              </>
            )}
            {technologies &&
              technologies.length > 0 &&
              Array.isArray(technologies) && (
                <>
                  <ProjectParagraphLarge
                    style={{ lineHeight: "32px" }}
                    data-aos="zoom-in"
                  >
                    Technologies and Tools
                  </ProjectParagraphLarge>
                  <TechnologyIconsContainer data-aos="zoom-in">
                    {technologies.map((tech, index) =>
                      tech && tech.value && tech.value.url ? (
                        <SingleTechnologyIcon data-aos="fade-in" key={index}>
                          <img
                            src={tech.value.url}
                            alt={`tech-logo-${index}`}
                          />
                          <p>{tech.name}</p>
                        </SingleTechnologyIcon>
                      ) : null
                    )}
                  </TechnologyIconsContainer>
                </>
              )}
            {links && <DeviceDetector data-aos="zoom-in" links={links} />}
          </IndividualProjectTextContainerVariation>
          {images && images.length > 0 && Array.isArray(images) && (
            <ProjectImagesWrapper
              data-aos="zoom-in"
              // style={{
              //   transform: isSmallDesktop
              //     ? "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
              //     : "matrix3d(1, 0, 0, -0.001, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
              // }}
            >
              {images.map(
                (img, i) =>
                  i < 4 && (
                    <ProjectsImageContainer
                      key={i}
                      onClick={() => openModal(img.url)}
                    >
                      <img
                        src={img.url}
                        alt={`img-${i + 1}`}
                        style={{
                          objectFit: "cover",
                          height: "100%",
                          padding: "4px",
                          boxSizing: "border-box",
                        }}
                      />
                    </ProjectsImageContainer>
                  )
              )}
            </ProjectImagesWrapper>
          )}
          {modalOpen && <ImageModal imageUrl={imageSrc} onClose={closeModal} />}
        </IndividualProjectFlexContainer>
      </SectionWrapper>
    </ContainerWrapper>
  );
};
