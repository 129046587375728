import React, { useContext, useState } from "react";
import axios from "axios";
import { AddEditFormWrapper, Form, Input, TextArea } from "../form.styled";
import "react-quill/dist/quill.snow.css";
import { PrimaryButton } from "../../navigation/navigation.styled";
import { Context, TestimonialsInt } from "../../../context/TestimonialsContext";
import { LoadingComponent } from "../../loading-component/LoadingComponent";

import { apiRoute } from "../../../constants/constants";
import { Toaster, toast } from "react-hot-toast";
interface AddEditTestimonialProps {
  mode: string;
  setMode: React.Dispatch<React.SetStateAction<string>>;
  editTestimonial: TestimonialsInt | undefined;
  setEditTestimonial: React.Dispatch<
    React.SetStateAction<TestimonialsInt | undefined>
  >;
}
export const AddEditTestimonials = ({
  setEditTestimonial,
  editTestimonial,
  mode,
  setMode,
}: AddEditTestimonialProps) => {
  const { setTestimonials ,testimonials} = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [testimonialName, settestimonialName] = useState(
    editTestimonial && editTestimonial.testimonial_name
      ? editTestimonial.testimonial_name
      : ""
  );
  const [testimonialPosition, settestimonialPosition] = useState(
    editTestimonial && editTestimonial.testimonial_position
      ? editTestimonial.testimonial_position
      : ""
  );
  const [testimonialText, settestimonialText] = useState(
    editTestimonial && editTestimonial.testimonial_text
      ? editTestimonial.testimonial_text
      : ""
  );
  const [testimonialLogo, settestimonialLogo] = useState<any>(
    editTestimonial && editTestimonial.testimonial_logo
      ? editTestimonial.testimonial_logo
      : ""
  );
  const [testimonialImage, settestimonialImage] = useState<any>(
    editTestimonial && editTestimonial.testimonial_image
      ? editTestimonial.testimonial_image
      : ""
  );

  const handleLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setLogoToBase(file);
    }
  };
  const setLogoToBase = (file: File | Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      settestimonialLogo(reader.result);
    };
  };
  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setImageToBase(file);
    }
  };
  const setImageToBase = (file: File | Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      settestimonialImage(reader.result);
    };
  };
  const resetForm = () => {
    settestimonialName("");
    settestimonialPosition("");
    settestimonialText("");
    settestimonialLogo(undefined);
    settestimonialImage(undefined);
    setMode("");
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      testimonialName === "" ||
      testimonialPosition === "" ||
      testimonialText === "" ||
      testimonialImage === "" ||
      testimonialLogo === ""
    ) {
      toast.error("All input fields must be filled");
      return;
    }

    const postData = {
      testimonialName,
      testimonialPosition,
      testimonialLogo,
      testimonialImage,
      testimonialText,
      index:(testimonials.length + 1).toString(),
    };
    if (mode === "create") {
      setIsLoading(true);
      await axios
        .post(`${apiRoute}api/testimonials`, postData)
        .then((res) => {
          //  console.log(res);
          resetForm();
          setTestimonials(res.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else if (mode === "edit") {
      if (editTestimonial) {
        setIsLoading(true);
        await axios
          .put(`${apiRoute}api/testimonials/${editTestimonial._id}`, postData)
          .then((res) => {
            //  console.log(res);
            resetForm();
            setTestimonials(res.data);
          })
          .catch((err) => console.log(err))
          .finally(() => setIsLoading(false));
      }
    }
  };
  return (
    <>
      {isLoading && <LoadingComponent />}
      <AddEditFormWrapper>
        <button
          style={{
            zIndex:999999999,
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          onClick={() => {
            setMode("");
            setEditTestimonial(undefined);
          }}
        >
          <b>X</b>
        </button>
        <Form onSubmit={handleSubmit}>
          <h1>{editTestimonial ? "Edit" : "Add"} Testimonial</h1>
          <div>
            <label htmlFor="testimonialNameId">Testimonial Full Name:</label>
            <Input
              type="text"
              id="testimonialNameId"
              value={testimonialName}
              onChange={(e) => settestimonialName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="testimonialPositionId">Testimonial Position:</label>
            <Input
              id="testimonialPositionId"
              value={testimonialPosition}
              onChange={(e) => settestimonialPosition(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="testimonialTextId">Testimonial:</label>
            <TextArea
              rows={10}
              id="testimonialTextId"
              value={testimonialText}
              onChange={(e) => settestimonialText(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="testimonialLogoId">Testimonial Company Logo:</label>
            {testimonialLogo && testimonialLogo.url ? (
              <img src={testimonialLogo.url} alt="blog-img" />
            ) : testimonialLogo !== "" ? (
              <img src={testimonialLogo} alt="blog-img" />
            ) : null}
            <input
              accept="image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon"
              type="file"
              id="testimonialLogoId"
              onChange={handleLogo}
            />
          </div>
          <div>
            {testimonialImage && testimonialImage.url ? (
              <img src={testimonialImage.url} alt="blog-img" />
            ) : testimonialImage !== "" ? (
              <img src={testimonialImage} alt="blog-img" />
            ) : null}
            <label htmlFor="testimonialImageId">Testimonial Image:</label>
            <input
              accept="image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon"
              type="file"
              id="testimonialImageId"
              onChange={handleImage}
            />
          </div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </Form>
      </AddEditFormWrapper>
      <Toaster />
    </>
  );
};
