import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Form, Input } from "../form.styled";
import axios from "axios";
import { LoadingComponent } from "../../loading-component/LoadingComponent";
import {
  PrivacyPolicyInt,
  Context,
} from "../../../context/PrivacyPolicyDataContext";
import { apiRoute } from "../../../constants/constants";
import ReactQuill from "react-quill";

interface EditPrivacyPolicyPageProps {
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}

export const EditPrivacyPolicyPage = ({
  setCurrentTab,
}: EditPrivacyPolicyPageProps) => {
  const { privacyPolicy, setPrivacyPolicy } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const [description, setDescription] = useState(
    privacyPolicy ? privacyPolicy.text : ""
  );
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<PrivacyPolicyInt>({
    defaultValues: {
      title: privacyPolicy ? privacyPolicy.title : "",
      text: privacyPolicy ? privacyPolicy.text : "",
    },
  });

  const handleDescription = (content: string) => {
    setDescription(content);
  };

  useEffect(() => {
    register("text");
    setValue("text", description);
  }, [register, setValue, description]);

  const onSubmit: SubmitHandler<PrivacyPolicyInt> = async (data) => {
    if (privacyPolicy) {
      setIsLoading(true);
      await axios
        .put(`${apiRoute}api/policy/${privacyPolicy._id}`, data)
        .then((res) => {
          setPrivacyPolicy(res.data);
          reset();
          setCurrentTab("");
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <div style={{ display: "flex" }}>
          <Form style={{ maxWidth: "90%" }} onSubmit={handleSubmit(onSubmit)}>
            <div>
              <h3>Privacy Policy Title</h3>
              <label htmlFor="title">Title:</label>
              <Input id="title" {...register("title")} />
            </div>
            <div>
              <h3>Privacy Policy Description</h3>
              <label htmlFor="stats_paragraph_containerOne">Description</label>
              <ReactQuill
                style={{ minHeight: "250px" }}
                value={description}
                onChange={handleDescription}
              />
            </div>
            <Button type="submit">Submit Changes</Button>
          </Form>
        </div>
      )}
    </>
  );
};
