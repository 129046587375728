import styled from 'styled-components'
import { Subtitle } from '../about-us-components/aboutus.styled'
import { Aside } from '../navigation/navigation.styled'
import { media } from '../../style-utils/media-queries'

export const RelativeSubtitle = styled(Subtitle)`
  position: relative;
  width: max-content;
  z-index: 10;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    z-index: 9;
    mix-blend-mode: multiply;
    width: 210px;
    border-radius: 30px;
    background-color: ${props => props.theme.colors.parisian_green};
    top: 25px;
    height: ${props => props.theme.sizes.largeToExtra};
    ${media.largeLaptop`
   width: 190px;
   `}
    ${media.laptop`
   width: 180px;
   `}
  }
`

export const RelativeSubtitleVariation = styled.h2`
  position: relative;
  padding-bottom: ${props => props.theme.sizes.extraLarge4};
  font-size: 32px;
  width: max-content;
  z-index: 10;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    z-index: 9;
    mix-blend-mode: multiply;
    width: 180px;
    border-radius: 30px;
    background-color: ${props => props.theme.colors.parisian_green};
    top: 20px;
    height: ${props => props.theme.sizes.largeToExtra};
  }
  @media (max-width: 600px) {
    font-size: 32px;
    width: 260px;

    &::after {
      top: 59px;
      left: 0;
      width: 180px;
    }
  }
`

export const SingleServiceContainer = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-basis: 42%;
  align-items: center;
  flex-shrink: 0;

  & div:first-child {
    text-align: center;
  }
  & .line {
    flex-grow: 0;
    flex-shrink: 0;
    height: 70px;
    width: 4px;
    background-color: ${props => props.theme.colors.primary['500']};
  }
`
export const ServiceTextContainer = styled.div`
  // padding-left: ${props => props.theme.sizes.extraLarge2};
  padding-left: 40px;
  @media (max-width: 1110px) {
    padding-left: 20px;
  }
  ${media.largeLaptop`
  padding-left:40px`}
  ${media.mobile`
  padding-left:16px`}
  & button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-weight: ${props => props.theme.font_weights.semiBold};
    font-size: ${props => props.theme.sizes.mediumSmall};
    line-height: 1.2;
    color: ${props => props.theme.colors.primary['500']};
    transition: 0.15s ease-in;
    &:hover {
      font-size: ${props => props.theme.sizes.medium};
    }
  }
`
export const AllServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

export const ServiceParagraph = styled.p`
  margin-bottom: 5px;
  font-weight: ${props => props.theme.font_weights.medium};
  font-size: ${props => props.theme.sizes.extraLarge};
  line-height: 1.2;
  letter-spacing: ${props => props.theme.negative_values.minusTwo};
  width: 350px;
  & .colored {
    color: ${props => props.theme.colors.primary['500']};
  }
  ${media.desktop`
  width:335px;
  `}
  ${media.smallDesktop`
  width:305px;
  `}
    ${media.largeLaptop`
  width:255px;
  `}
  ${media.mobile`
  width:195px;`}
`

export const AsideNoText = styled(Aside)`
  width: 104px;
  flex-shrink: 0;
  ${media.largeLaptop`
 width:0px;`}
`
export const ServicesMainParagraph = styled.p`
  font-weight: ${props => props.theme.font_weights.extraLight};
  font-size: ${props => props.theme.sizes.mediumLarge};
  line-height: 1.2;
  margin-bottom: 30px;
`

export const TechnologiesParagraph = styled.p`
  margin-top: 24px;
  font-weight: ${props => props.theme.font_weights.semiBold};
  font-size: ${props => props.theme.sizes.mediumLarge};
  line-height: 1.2;
`
