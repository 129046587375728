import {
  AboutUsSideText,
  SectionWrapperVariation,
} from "../about-us-components/aboutus.styled";
import { Aside, ContainerWrapper } from "../navigation/navigation.styled";
import React, { useContext, useEffect, useRef, useState } from "react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { useMediaQuery } from "react-responsive";
import {
  TeamComponentSwiperImage,
  TeamComponentSwiperImageContainer,
  TeamComponentSwiperSlide,
} from "../../style-utils/inline-styles";
import { Context } from "../../context/GalleryContext";

export const Team = () => {
  const { gallery } = useContext(Context);
  const swiperRef = useRef<SwiperRef>(null);
  const isSmallMobile = useMediaQuery({ maxWidth: 405 });
  const isMobile = useMediaQuery({ maxWidth: 500 });
  const isSmallTablet = useMediaQuery({ maxWidth: 800 });
  const isTablet = useMediaQuery({ maxWidth: 1070 });
  const isExtraLarge = useMediaQuery({ minWidth: 1650 });
  const sideRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const elementRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const [sideHeight, setSideHeight] = useState(600);

  useEffect(() => {
    if (sideRef.current) {
      setSideHeight(sideRef.current.clientHeight);
    }
  }, [sideHeight]);
  const slidesPerView = isSmallMobile
    ? 1.2
    : isMobile
    ? 1.5
    : isSmallTablet
    ? 2
    : isTablet
    ? 2.6
    : isExtraLarge
    ? 3
    : 3.2;
  // const imgArr = [image1, image2, image3, image2, image1, image3, image2];

  const handleSlideChange = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.autoplay.stop();
      swiperRef.current.swiper.autoplay.start();
      swiperRef.current.swiper.autoplay.running;
      swiperRef.current.swiper.autoplay.timeLeft = 4000;
    }
  };
  return (
    <ContainerWrapper>
      <Aside ref={sideRef}>
        <AboutUsSideText
          ref={elementRef}
          margin={`calc(${sideHeight - 100}px)0 0 0`}
        >
          The Team
        </AboutUsSideText>
      </Aside>
      <SectionWrapperVariation className="pagination">
        <Swiper
          grabCursor={true}
          modules={[Autoplay, Pagination]}
          ref={swiperRef}
          style={{
            left: isMobile ? "-24px" : "0",
            width: isMobile
              ? "calc(100% + (2 * 24px))"
              : isExtraLarge
              ? "100%"
              : "80vw",
            height: isMobile ? "420px" : "500px",
            paddingBottom: isSmallMobile
              ? "20%"
              : isMobile
              ? "32%"
              : isTablet
              ? "3%"
              : "10%",
          }}
          slidesPerView={slidesPerView}
          spaceBetween={20}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          pagination={{
            dynamicMainBullets: 1,
            dynamicBullets: true,
            clickable: true,
            renderBullet: function () {
              return `<span class="swiper-pagination-bullet" style="background-color:#00A49A;"></span>`;
            },
          }}
          onSlideChange={handleSlideChange}
        >
          {gallery &&
            gallery.map((img, i) => (
              <SwiperSlide key={i} style={TeamComponentSwiperSlide}>
                <figure
                  style={{
                    ...TeamComponentSwiperImageContainer,
                    maxHeight: isMobile
                      ? "450px"
                      : isTablet
                      ? "480px"
                      : "550px",
                  }}
                >
                  <img
                    style={TeamComponentSwiperImage}
                    src={img.gallery_img.url}
                    alt={`team-${i + 1}`}
                  />
                </figure>
              </SwiperSlide>
            ))}
        </Swiper>
      </SectionWrapperVariation>
    </ContainerWrapper>
  );
};
