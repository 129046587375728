import styled from 'styled-components'
import { RelativeSubtitle } from '../services-components/services.styled'
import React from 'react'
import { media } from '../../style-utils/media-queries'
interface SpanLocationProps {
  resizing?: boolean
  top?: boolean
}
export const RelativeSubtitleLarge = styled(
  RelativeSubtitle
)<SpanLocationProps>`
  font-weight: ${props => props.theme.font_weights.bold};
  font-size: ${props => props.theme.sizes.extraLarge5};
  line-height: 1.2;
  letter-spacing: ${props => props.theme.negative_values.minusOne};
  align-self: flex-start;
  flex: 0 0 40%;
  & p {
    margin: 35px 0 30px;
    font-weight: ${props => props.theme.font_weights.extraLight};
    font-size: ${props => props.theme.sizes.extraLarge};
    line-height: 1.2;
    letter-spacing: ${props => props.theme.negative_values.minusOne};
  }
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }
  & span {
    left: 0;
    position: absolute;
    z-index: 9;
    mix-blend-mode: multiply;
    width: ${props => (props.resizing ? '100%' : '425px')};
    border-radius: 30px;
    background-color: ${props => props.theme.colors.parisian_green};
    bottom: ${props => (props.top ? '5%' : '75%')};
    height: ${props => props.theme.sizes.largeToExtra};
    ${media.laptop`
    height: 20px;
    ;`}
  }
`
export const RelativeSubtitleLargeVariation = styled(RelativeSubtitleLarge)`
  font-size: ${props => props.theme.sizes.extraLarge4};
  margin-left: 0;
  margin-right: 0;
  @media (max-width: 400px) {
    font-size: 24px;
  }
`

export const ProjectHeroParagraph = styled.div`
  font-weight: ${props => props.theme.font_weights.regular};
  font-size: ${props => props.theme.sizes.extraLarge};
  line-height: 1.2;
  font-weight: 200;
  color: ${props => props.theme.colors.secondary};
  letter-spacing: ${props => props.theme.negative_values.minusOne};
  ${media.tabletToLaptop`
   text-align:left;
  `}
  & ul li {
    font-size: ${props => props.theme.sizes.largeToExtra} !important;
    margin-bottom: 12px;

    ${media.tabletToLaptop`
    text-align:left;
      // font-size: 15px !important;
    `}
  }

  & p span {
    font-size: ${props => props.theme.sizes.largeToExtra} !important;
    color: ${props => props.theme.colors.light} !important;
    line-height: 1.2;
  }

  ${media.tabletToLaptop`
  width:80%;
  margin:0 auto;
  `}
  ${media.tablet`
  & ul {
    padding-left:12px;
    padding-bottom:12px;
  }
  width:90%;
  `}
`

export const ProjectTitle = styled.h2`
  font-weight: ${props => props.theme.font_weights.bold};
  font-size: ${props => props.theme.sizes.extraLarge5};
  line-height: 1.2;
  letter-spacing: ${props => props.theme.negative_values.minusOne};
  align-self: flex-start;
  margin: 0;
`
export const ProjectSubtitle = styled.h2`
  font-weight: ${props => props.theme.font_weights.bold};
  font-size: ${props => props.theme.sizes.extraLarge5};
  line-height: 1.2;
  letter-spacing: ${props => props.theme.negative_values.minusOne};
  align-self: flex-start;
  width: max-content;
  margin: 0 0 20px;
  position: relative;
  & span {
    left: 0;
    position: absolute;
    z-index: 9;
    mix-blend-mode: multiply;
    width: 100%;
    border-radius: 30px;
    background-color: ${props => props.theme.colors.parisian_green};
    bottom: 1px;
    height: ${props => props.theme.sizes.largeToExtra};
  }
  ${media.laptop`
  // text-align:center;
 // margin : 0 auto;
  `}
`

export const ProjectSubtitleDynamicText = styled(ProjectSubtitle)`
  padding: 48px 0 0 0;
  ${media.laptop`
  padding: 0;
   max-inline-size: 20ch;
  text-wrap:balance;
  `}
  ${media.mobile`
  font-size:26px;
  margin-bottom:0;
  `}
  ${media.smallMobile`
  font-size:25px;
  `}
`
export const ProjectParagraph = styled.div`
  margin: 35px 0 30px;
  font-weight: ${props => props.theme.font_weights.extraLight};
  & p,
  & ul li {
    margin-bottom: 12px;
    font-weight: ${props => props.theme.font_weights.extraLight};
    font-size: ${props => props.theme.sizes.mediumLarge};
    line-height: 1.2;
  }
`

export const ProjectParagraphLarge = styled.p`
  margin: 36px 0 24px;
  font-weight: ${props => props.theme.font_weights.medium};
  font-size: ${props => props.theme.sizes.extraLarge2};
  line-height: 1.2;
  letter-spacing: ${props => props.theme.negative_values.minusOne};
  ${media.mobile`
  font-size: 22px;
  margin: 18px 0 24px;
  `}
`
export const StatsCount = styled.div`
  margin-top: ${props => props.theme.sizes.extraLarge6};
  & h3 {
    font-weight: ${props => props.theme.font_weights.extraBold};
    font-size: ${props => props.theme.sizes.extraLarge7};
    line-height: 1.2;
    letter-spacing: ${props => props.theme.negative_values.minusOne};
    align-self: flex-start;
    width: max-content;
    margin: 0 20px 0;
    position: relative;
    & span {
      position: absolute;
      z-index: 9;
      mix-blend-mode: multiply;
      border-radius: 30px;
      background-color: ${props => props.theme.colors.parisian_green};
      top: 35px;
      width: 120%;
      left: -10%;
      height: ${props => props.theme.sizes.extraLarge4};

      ${media.laptop`
      top: 25px;
      `}
    }
  }

  & p {
    margin-top: 30px;
    text-align: center;
    font-weight: ${props => props.theme.font_weights.regular};
    font-size: ${props => props.theme.sizes.medium};
    line-height: 1.2;
    letter-spacing: ${props => props.theme.negative_values.minusOne};
  }
  ${media.smallTablet`
  flex:1 1 50%;
  & h3 {
    text-align:center;
    margin:0 auto;
  }
  `}
`

export const StatsParagraphContainer = styled.div`
  display: block;
  max-width: 994px;
  padding-right: 20%;
  padding-left: 20%;
  & div h1 {
    font-size: ${props => props.theme.sizes.extraLarge4};
    margin-bottom: ${props => props.theme.sizes.mediumLarge};
    & span {
      color: ${props => props.theme.colors.primary['500']} !important;
    }
  }
  & div h2 {
    font-size: ${props => props.theme.sizes.extraLarge2};
  }
  & div ul {
    margin-bottom: 24px;
  }
  & div ul li {
    font-size: ${props => props.theme.sizes.mediumLarge};
    margin-bottom: 8px;
    font-weight: ${props => props.theme.font_weights.extraLight};
  }
  margin-top: ${props => props.theme.sizes.extraLarge5};
  & p {
    font-weight: ${props => props.theme.font_weights.extraLight};
    font-size: ${props => props.theme.sizes.mediumLarge};
    line-height: 1.2;
  }
`
export const StatsParagraphContainerVariation = styled(StatsParagraphContainer)`
  margin-top: 0;
`

export const RelativeSubtitleContainer = styled.div`
  align-self: flex-start;
  flex: 0 0 40%;
  width: 400px;
  & h3 {
    width: 300px;
    position: relative;
    font-weight: ${props => props.theme.font_weights.bold};
    font-size: ${props => props.theme.sizes.extraLarge4};
    line-height: 1.2;
    letter-spacing: ${props => props.theme.negative_values.minusOne};

    & span {
      left: 0;
      position: absolute;
      z-index: 9;
      mix-blend-mode: multiply;
      width: 280px;
      border-radius: 30px;
      background-color: ${props => props.theme.colors.parisian_green};
      bottom: 7px;
      height: ${props => props.theme.sizes.large};
      ${media.desktop`
      bottom: 2px;
      height: 20px;
      width: 265px;
      `}
      ${media.smallDesktop`
      height: 18px;
      width: 267px;
      bottom: 3px;
      `}
      ${media.largeLaptop`
      height: 17px;
      width: 260px;
      `}
      ${media.laptop`
      height: 16px;
      width: 240px;
      bottom:4px;
      `}
     ${media.mobile`
      width: 198px;
      `}
    }
  }
  & p {
    margin: 35px 0 30px;
    font-weight: ${props => props.theme.font_weights.extraLight};
    line-height: 1.2;
    font-size: ${props => props.theme.sizes.mediumLarge};
  }
  ${media.tabletToLaptop`
   width:100%; 
  // text-align:center;
  & h3 {
   margin:30px 0 0;
   width: 260px;
}

`}
`

export const MobileImageContainer = styled.div`
  padding: 0 0 0 40px;
  ${media.tabletToLaptop`
// padding:35px 50px 0px;
padding:35px 0px 0px;
`}
  ${media.phablet`
// padding:30px 2.5% 0px;
width:calc(100% + (2 * 24px));
padding:30px 0px 0px;
`}
`

export const IndividualProjectFlexContainer = styled.div`
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  ${media.laptop`
  flex-direction:column-reverse;
    padding: 0;
  `}
  ${media.phablet`
    padding:0;
  `}
`

export const IndividualProjectTextContainer = styled.div`
  flex: 0 0 37%;
  ${media.laptop`
  text-align:left;
 margin:35px 0 0 0 ;
  `}
`

export const IndividualProjectTextContainerVariation = styled.div`
  flex: 1 0 57%;
  padding-right: 48px;
  ${media.laptop`
  padding-right:0;
  text-align:left;
 margin:35px 0 0 0 ;
  `}
`
export const ProjectImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  transform-origin: right;
`

export const ProjectsImageContainer = styled.figure`
  flex: 0 1 50%;
  background-color: white;
  max-height: 200px;
  min-height: 150px;
  cursor: pointer;
  transition: 0.3s ease-in;
  &:hover {
    transform: scale(1.02);
  }
  ${media.laptop`
max-height: 200px;
`}
`

export const ModalImgContainer = styled.div`
  position: fixed;
  transition: all 2s eae-in-out;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalImgContent = styled.div`
  background-color: white;
  position: relative;
  z-index: 31;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 60%;
  ${media.laptop`
  width:75%`}
  ${media.phablet`
  width:100%`}
`

export const ModalImage = styled.img`
  max-width: 100%;
  border-radius: 5px;
  max-height: 80vh;
`
