import React, { useState } from "react";
import { Button, Form, Input, Title, Wrapper } from "./form.styled";
import { Aside, ContainerWrapper } from "../navigation/navigation.styled";
import {
  AboutUsSideText,
  SectionWrapper,
} from "../about-us-components/aboutus.styled";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { apiRoute } from "../../constants/constants";
interface FormProps {
  email: string;
  password: string;
}
export const Login = () => {
  const navigate = useNavigate();

  const [formVal, setFormVal] = useState<FormProps>({
    email: "",
    password: "",
  });
  const fillForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentFormValues = { ...formVal, [e.target.name]: e.target.value };
    setFormVal(currentFormValues);
  };
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formVal.email !== "" && formVal.password !== "") {
      try {
        const response = await axios.post(`${apiRoute}api/login`, formVal);

        if (response.data.token) {
          const token = response.data.token;
          localStorage.setItem("token", response.data.token);
          // Send the token back to the backend immediately for verification
          await axios
            .post(`${apiRoute}api/protected`, formVal, {
              headers: {
                Authorization: token,
              },
            })
            .then(() => {
              toast.success("Successful login!");
              navigate("/admin");
            })
            .catch((err) => console.log(err));
        }
      } catch (error) {
        console.error(error);
        toast.error("Error")
      }
    }
  };

  return (
    <>
      <ContainerWrapper>
        <Aside>
          <AboutUsSideText margin={`500px 0 0 0`}>Login</AboutUsSideText>
        </Aside>
        <SectionWrapper style={{ minHeight: "80vh" }}>
          <Wrapper>
            <Form onSubmit={onSubmit}>
              <Title>Login</Title>
              <Input
                type="email"
                name="email"
                value={formVal.email}
                onChange={fillForm}
              />
              <Input
                type="password"
                name="password"
                value={formVal.password}
                onChange={fillForm}
              />
              <Button>Login</Button>
            </Form>
          </Wrapper>
        </SectionWrapper>
        <Toaster />
      </ContainerWrapper>
    </>
  );
};
