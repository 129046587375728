import React from "react";
import { createContext, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";

import { apiRoute } from "../constants/constants";

export interface ServiceDatainterface {
  _id: string;
  title: string;
  question: string;
  answer: string;
  expertise: string;
  index: string;
}
export interface ContextData {
  servicesPage: ServiceDatainterface[];
  setServicesPage: (servicesPage: ServiceDatainterface[]) => void;
  isLoading: boolean;
  error: string;
}

export interface Props {
  children: React.ReactNode;
}

export const Context = createContext({} as ContextData);

export const Provider = ({ children }: Props) => {
  
  const [servicesPage, setServicesPage] = useState<ServiceDatainterface[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | AxiosError | any>("");

  useEffect(() => {
    if (servicesPage.length === 0) {
      setLoading(true);
      axios
        .get(`${apiRoute}api/servicespage`)
        .then((res) => setServicesPage(res.data))
        .catch((err) => setError(err))
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <Context.Provider
      value={{
        servicesPage,
        setServicesPage,
        isLoading,
        error,
      }}
    >
      {children}
    </Context.Provider>
  );
};
