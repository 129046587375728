import React, { useEffect } from "react";
import {
  Aside,
  ContainerWrapper,
  PrimaryButton,
} from "../navigation/navigation.styled";
import {
  AboutImageContainerVariation,
  AboutSectionFlexContainer,
  AboutSectionTitle,
  AboutUsSideText,
  ListWithBulletsContainer,
  SectionWrapper,
  Subtitle,
} from "./aboutus.styled";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useMediaQuery } from "react-responsive";

interface AbotUsComponentProps {
  title: string;
  subtitle?: string;
  sideText: string;
  paragraphOne?: string;
  paragraphTwo?: string;
  paragraphThree?: string;
  paragraphFour?: string;
  img?: string;
}

export const AboutUsComponent = ({
  title,
  sideText,
  subtitle,
  paragraphOne,
  paragraphTwo,
  paragraphThree,
  paragraphFour,
  img,
}: AbotUsComponentProps) => {
  const splitedWord = title.split(" ");
  const breatpoint = useMediaQuery({ maxWidth: 480 });
  useEffect(() => {
    AOS.init({
      duration: 350,
      once: true,
      easing: "ease-out",
    });

    AOS.refresh();
    return () => {
      AOS.refresh();
    };
  }, []);

  return (
    <ContainerWrapper>
      <Aside>
        <AboutUsSideText>{sideText}</AboutUsSideText>
      </Aside>
      <SectionWrapper>
        <AboutSectionTitle>
          {Array.isArray(splitedWord) ? splitedWord[0] : "Why"}
          <span>{" "}
            {Array.isArray(splitedWord)
              ? splitedWord.slice(1).join(" ")
              : "Moxelle"}
          </span>
        </AboutSectionTitle>
        <AboutSectionFlexContainer>
          <div style={{ flex: "0 0 45%" }}>
            <Subtitle data-aos="slide-right">{subtitle}</Subtitle>
            <ListWithBulletsContainer>
              {paragraphOne && (
                <p
                  data-aos="zoom-in"
                  dangerouslySetInnerHTML={{
                    __html: paragraphOne,
                  }}
                ></p>
              )}
              {paragraphTwo && (
                <p
                  data-aos="zoom-in"
                  dangerouslySetInnerHTML={{
                    __html: paragraphTwo,
                  }}
                ></p>
              )}
              {paragraphThree && (
                <p
                  data-aos="zoom-in"
                  dangerouslySetInnerHTML={{
                    __html: paragraphThree,
                  }}
                ></p>
              )}
              {paragraphFour && (
                <p
                  data-aos="zoom-in"
                  dangerouslySetInnerHTML={{
                    __html: paragraphFour,
                  }}
                ></p>
              )}
            </ListWithBulletsContainer>
            <Link to="/about">
              <PrimaryButton
                style={{ marginTop: breatpoint ? "15px" : "35px" }}
              >
                Learn More
              </PrimaryButton>
            </Link>
          </div>
          <div style={{ flex: "0 0 45%" }}>
            <AboutImageContainerVariation data-aos="flip-left">
              <img
                style={{
                  overflow: "hidden",
                  borderRadius: breatpoint ? "0px" : "24px",
                }}
                src={img}
                alt="colleagues in working space"
              />
            </AboutImageContainerVariation>
          </div>
        </AboutSectionFlexContainer>
      </SectionWrapper>
    </ContainerWrapper>
  );
};
