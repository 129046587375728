import React from "react";
import { createContext, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";

import { apiRoute } from "../constants/constants";

export interface ProjectspageDatainterface {
  _id: string;
  banner_title: string;
  banner_paragraphOne: string;
  mobile_app_project_title: string;
  mobile_app_project_description: string;
  mobile_app_project_subtitle: string;
  ux_project_title: string;
  ux_project_description: string;
  ux_project_subtitle: string;
  team_project_title: string;
  team_project_description: string;
  team_project_subtitle: string;
}
export interface ContextData {
  projectspage: ProjectspageDatainterface | undefined;
  setProductspage: (projectspage: ProjectspageDatainterface) => void;
  isLoading: boolean;
  error: string;
}

export interface Props {
  children: React.ReactNode;
}

export const Context = createContext({} as ContextData);

export const Provider = ({ children }: Props) => {
  
  const [projectspage, setProductspage] = useState<ProjectspageDatainterface | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | AxiosError | any>("");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${apiRoute}api/projectspage`)
      .then((res) => setProductspage(res.data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Context.Provider
      value={{
        projectspage,
        setProductspage,
        isLoading,
        error,
      }}
    >
      {children}
    </Context.Provider>
  );
};
