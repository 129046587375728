import React, { useContext } from "react";
import { ToggleContext } from "../theme/ThemeContext";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Aside,
  ContainerWrapper,
  SocialList,
} from "../components/navigation/navigation.styled";
import {
  AboutSectionFlexContainer,
  SectionWrapper,
  Subtitle,
} from "../components/about-us-components/aboutus.styled";
import {
  COLORS,
  COLORS_SECONDARY,
  apiRoute,
  socialListObject,
} from "../constants/constants";
import {
  AddressInfoContainer,
  StyledForm,
} from "../components/contact-us-components/contact-us.styled";
import { LocationIcon } from "../components/contact-us-components/LocationIcon";
import { useMediaQuery } from "react-responsive";
import { Context } from "../context/HomePageDataContext";
import axios from "axios";
import { FormValues } from "../components/contact-us-components/ContactUsComponent";
import { Toaster, toast } from "react-hot-toast";
import { Helmet } from "react-helmet";

export const ContactPage = () => {
  const { homepage } = useContext(Context);
  const breakPoint = useMediaQuery({ maxWidth: 1090 });
  const isTablet = useMediaQuery({ minWidth: 800 });
  const extraLargeScreen = useMediaQuery({ minWidth: 1600 });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await axios
      .post(`${apiRoute}api/form-submission`, data)
      .then((res) => {
        if (res.data === "success") {
          toast.success("Your message was sent successfully");
          reset();
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Helmet>
        <title>Moxelle | Contact us</title>
        <meta
          name="description"
          content="Moxelle is an IT recruitment and digital solutions company with over 7 years of experience. We have a proven track record of success in helping businesses of all sizes find the right talent and achieve their goals."
        />
      </Helmet>
      <ContainerWrapper>
        <Aside>
          {!breakPoint && (
            <SocialList
              style={{
                position: "relative",
                bottom: "-80px",
                left: "0",
              }}
            >
              {socialListObject.map((item, i) => (
                <li key={i}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {item.name}
                  </a>
                </li>
              ))}
            </SocialList>
          )}
        </Aside>
        <div
          style={{
            height: extraLargeScreen ? "90vh" : "auto",
            width: "100%",
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(https://res.cloudinary.com/dfolafoax/image/upload/v1695120949/contact-bg-img_mb6vgb.webp) no-repeat center / cover fixed`,
          }}
        >
          <SectionWrapper
            style={{
              backgroundColor: "transparent",
              margin: 0,
              paddingRight: extraLargeScreen
                ? "250px"
                : isTablet
                ? "70px"
                : "12px",
              paddingLeft: extraLargeScreen
                ? "25%"
                : isTablet
                ? "70px"
                : "12px",
              paddingTop: extraLargeScreen ? "250px" : "150px",
              paddingBottom: "150px",
            }}
          >
            <Subtitle style={{ color: COLORS.light }}>
              Lets's go to work!
            </Subtitle>
            <AboutSectionFlexContainer padding>
              <StyledForm
                style={{ background: COLORS.formBackgroundDark }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <input
                  id="name"
                  {...register("name", { required: true })}
                  placeholder="Your Name *"
                />
                {errors.name && <p>The name is required.</p>}
                <input
                  id="phone"
                  {...register("phone", { required: true })}
                  placeholder="Phone Number *"
                />
                {errors.phone && <p>The phone number is required.</p>}
                <input
                  id="sender"
                  type="sender"
                  {...register("sender", { required: true })}
                  placeholder="E-mail *"
                />
                {errors.sender && <p>The email is required.</p>}
                <div>
                  <textarea
                    rows={4}
                    {...register("message")}
                    placeholder="Message"
                  ></textarea>
                  <input
                    style={{ border: `4px solid ${COLORS.space_cadet800}` }}
                    type="submit"
                  />
                </div>
              </StyledForm>
              <AddressInfoContainer
                style={{ color: COLORS.light }}
                data-aos="zoom-in"
              >
                <div>
                  <LocationIcon color={COLORS_SECONDARY.dark} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homepage ? homepage.address_mk : "",
                    }}
                  />
                </div>
                <div>
                  <LocationIcon color={COLORS_SECONDARY.dark} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homepage ? homepage.address_us : "",
                    }}
                  />
                </div>
              </AddressInfoContainer>
            </AboutSectionFlexContainer>
          </SectionWrapper>
        </div>
        <Toaster />
      </ContainerWrapper>
    </>
  );
};
