import React from 'react'

export const LocationIcon = () => {
  return (
    <svg
      width='32'
      height='40'
      viewBox='0 0 32 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.0045 18.6346C16.8656 18.6346 17.6026 18.3267 18.2154 17.7109C18.8282 17.0951 19.1346 16.3567 19.1346 15.4955C19.1346 14.6344 18.8267 13.8974 18.211 13.2846C17.5952 12.6718 16.8567 12.3654 15.9956 12.3654C15.1344 12.3654 14.3975 12.6732 13.7847 13.289C13.1718 13.9048 12.8654 14.6432 12.8654 15.5044C12.8654 16.3655 13.1733 17.1025 13.7891 17.7153C14.4048 18.3281 15.1433 18.6346 16.0045 18.6346ZM16 36.0885C20.382 32.1833 23.6827 28.4769 25.902 24.9692C28.1212 21.4615 29.2308 18.4384 29.2308 15.9C29.2308 11.9095 27.9567 8.64007 25.4085 6.0917C22.8603 3.54333 19.7242 2.26915 16 2.26915C12.2758 2.26915 9.13968 3.54333 6.59151 6.0917C4.04331 8.64007 2.76921 11.9095 2.76921 15.9C2.76921 18.4384 3.89743 21.4615 6.15386 24.9692C8.41026 28.4769 11.6923 32.1833 16 36.0885ZM16 39.1153C10.7872 34.5871 6.90068 30.3839 4.34041 26.5057C1.78018 22.6275 0.500061 19.0923 0.500061 15.9C0.500061 11.0538 2.06031 7.19228 5.18081 4.31535C8.30131 1.43845 11.9077 0 16 0C20.0923 0 23.6987 1.43845 26.8192 4.31535C29.9397 7.19228 31.5 11.0538 31.5 15.9C31.5 19.0923 30.2198 22.6275 27.6596 26.5057C25.0993 30.3839 21.2128 34.5871 16 39.1153Z'
        fill='#1A2326'
      />
    </svg>
  )
}
