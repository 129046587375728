import React, { CSSProperties, useContext, useState } from "react";
import { COLORS, COLORS_SECONDARY } from "../../../constants/constants";
import { ToggleContext } from "../../../theme/ThemeContext";

export const InstagramIcon = () => {
  const {toggleValue} = useContext(ToggleContext)
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const iconFilltransition: CSSProperties = {
    marginRight: '10px',
    transition: "fill 0.3s ease-in",
    cursor: "pointer",
    fill: isHovered ? COLORS.light : toggleValue ? COLORS.primary["500"]: COLORS_SECONDARY.primary['500'],
  };
  return (
    <a
    href="https://www.instagram.com/moxelle_/"
    target="_blank"
    rel="noopener noreferrer"
    aria-label="Moxelle instagram page"
  >
    <svg
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={iconFilltransition}
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.18901 0C3.95462 0 0.5 3.41825 0.5 7.60805V18.6102C0.5 22.7924 3.95462 26.2103 8.18901 26.2103H19.3083C23.5349 26.2103 26.9892 22.7924 26.9892 18.6026V7.60805C26.9892 3.41825 23.5349 0 19.3005 0H8.18901ZM8.18901 2.01635H19.3005C22.4365 2.01635 24.9517 4.50484 24.9517 7.60805V18.6026C24.9517 21.7055 22.4365 24.1943 19.3083 24.1943H8.18901C5.05305 24.1943 2.5378 21.7055 2.5378 18.6102V7.60805C2.5378 4.50484 5.05305 2.01635 8.18901 2.01635ZM21.8953 4.03242C21.33 4.03242 20.8764 4.48126 20.8764 5.0406C20.8764 5.59966 21.33 6.0485 21.8953 6.0485C22.4603 6.0485 22.9139 5.59966 22.9139 5.0406C22.9139 4.48126 22.4603 4.03242 21.8953 4.03242ZM13.7446 6.0485C9.82058 6.0485 6.61286 9.22245 6.61286 13.1052C6.61286 16.9879 9.82058 20.1618 13.7446 20.1618C17.6686 20.1618 20.8764 16.9879 20.8764 13.1052C20.8764 9.22245 17.6686 6.0485 13.7446 6.0485ZM13.7446 8.06485C16.5702 8.06485 18.8388 10.3093 18.8388 13.1052C18.8388 15.901 16.5702 18.1458 13.7446 18.1458C10.919 18.1458 8.65066 15.901 8.65066 13.1052C8.65066 10.3093 10.919 8.06485 13.7446 8.06485Z"
        style={iconFilltransition}
      />
    </svg>
    </a>
  );
};
