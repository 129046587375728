import React from "react";
import { createContext, useEffect, useState } from "react";
import { ProjectsInterface } from "../components/project-components/IndividualProjectVariation";
import axios, { AxiosError } from "axios";
import { apiRoute } from "../constants/constants";

 export interface ContextData {
    projects: ProjectsInterface[];
   setProjects: (projects: ProjectsInterface[]) => void;
    isLoading:boolean;
    error:string;
  }
  
  export interface Props {
    children: React.ReactNode;
  }
  
  
  export const Context = createContext({} as ContextData);
  
  export const Provider = ({ children }: Props) => {
    
    const [projects,setProjects] = useState<ProjectsInterface[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | AxiosError | any >("");
  
    useEffect(() => {
      setLoading(true);
      axios
        .get(`${apiRoute}api/projects`)
        .then((res) =>setProjects(res.data))
        .catch((err) => setError(err))
        .finally(() => setLoading(false));
    }, []);
  
    return (
      <Context.Provider
        value={{
         projects,
         setProjects,
          isLoading,
          error,
        }}
      >
        {children}
      </Context.Provider>
    );
  };
  