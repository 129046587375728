import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { Context } from '../context/JobsContext'
import { SingleJob } from '../components/contact-us-components/SingleJob'
import { Helmet } from 'react-helmet'
import { JobProps } from '../components/contact-us-components/JobOfferingComponent'

import {
  Aside,
  ContainerWrapper,
  HeroPretitle,
  HeroSectionContainer,
  PrimaryButton,
  SocialList
} from '../components/navigation/navigation.styled'
import { socialListObject } from '../constants/constants'
import { AsideNoText } from '../components/services-components/services.styled'
import { LoadingComponent } from '../components/loading-component/LoadingComponent'
import { ContactModal } from '../components/contact-modal/ContactModal'

export const IndividualJobPost = () => {
  const { _id } = useParams()
  const { jobs, isLoading } = useContext(Context)
  const [localJobPost, setLocalJobPost] = useState<JobProps | undefined>()
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    const checkCurrentJob = (_id: string | undefined) => {
      if (!_id) {
        ;<Navigate to={'/error'} />
        return
      }
      const findJob = jobs.find(job => job._id === _id)
      if (!findJob) {
        ;<Navigate to={'/error'} />
        return
      }
      return findJob
    }
    setLocalJobPost(checkCurrentJob(_id))
  }, [jobs])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <>
      {isLoading ? (
        <ContainerWrapper style={{ height: '80vh' }}>
          <AsideNoText
            style={{ height: '100%', position: 'absolute', zIndex: 10 }}
          />
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              top: 0,
              zIndex: 1,
              width: '100%',
              maxHeight: '80vh',
              inset: 0,
              backgroundImage:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://res.cloudinary.com/dfolafoax/image/upload/v1695121128/services-bg-img_leib7n.webp'
            }}
          >
            <HeroSectionContainer>
              <LoadingComponent />
            </HeroSectionContainer>
          </div>
        </ContainerWrapper>
      ) : localJobPost ? (
        <>
          <Helmet>
            <title>Moxelle | {localJobPost.job_title}</title>
            <meta name='description' content={localJobPost.job_desc} />
          </Helmet>
          <SingleJob {...localJobPost} />
        </>
      ) : !localJobPost && !jobs && isMounted ? (
        <ContainerWrapper style={{ height: '80vh' }}>
          <Aside style={{ zIndex: 1000 }}>
            <SocialList style={{ marginTop: '75vh' }}>
              {socialListObject.map((item, i) => (
                <li key={i}>
                  <a href={item.url} target='_blank' rel='noopener noreferrer'>
                    {item.name}
                  </a>
                </li>
              ))}
            </SocialList>
          </Aside>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              top: 0,
              zIndex: 1,
              width: '100%',
              maxHeight: '80vh',
              inset: 0,
              backgroundImage:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://res.cloudinary.com/dfolafoax/image/upload/v1695121128/services-bg-img_leib7n.webp'
            }}
          >
            <HeroSectionContainer>
              <HeroPretitle>Error 404 page not found</HeroPretitle>
              <Link to='/'>
                <PrimaryButton>Go back to homepage</PrimaryButton>
              </Link>
            </HeroSectionContainer>
          </div>
        </ContainerWrapper>
      ) : (
        <ContainerWrapper style={{ height: '80vh' }}>
          <AsideNoText
            style={{ height: '100%', position: 'absolute', zIndex: 10 }}
          />
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              top: 0,
              zIndex: 1,
              width: '100%',
              maxHeight: '80vh',
              inset: 0,
              backgroundImage:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://res.cloudinary.com/dfolafoax/image/upload/v1695121128/services-bg-img_leib7n.webp'
            }}
          >
            <HeroSectionContainer>
              <HeroPretitle>There is no job post found</HeroPretitle>
              <Link to='/'>
                <PrimaryButton>Go back to homepage</PrimaryButton>
              </Link>
            </HeroSectionContainer>
          </div>
        </ContainerWrapper>
      )}
    </>
  )
}
