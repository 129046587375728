import React, { useEffect, useRef, useState } from "react";
import { CreateTeam } from "../components/contact-us-components/create-team/CreateTeam";
import { HeroPretitleVariation } from "../components/navigation/navigation.styled";
import { Navigation } from "../components/navigation/Navigation";
import { MainOptionsCategory } from "../components/contact-us-components/create-team/MainOptionsCategory";
import { ManagedTeam } from "../components/contact-us-components/create-team/ManagedTeam";
import { RecruitmentForm } from "../components/contact-us-components/create-team/RecruitmentForm";
import bg_image1 from "../img/create-team-bg-images/wave.svg";
import { HeroSectionVariation } from "../components/navigation/HeroSectionVariation";
import { Toaster } from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
export const GetQuotePage = () => {
  const sectionWrapperRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null);
  const breakpoint = useMediaQuery({ maxWidth: 900 });
  const [mainCategory, setMainCategory] = useState("DedicatedTeam");
  const [welcomeText, setWelcomeText] = useState("");
  const welcomeMessageTobeDisplayed = "Let’s go to work!";
  const displayForm = (category: string) => {
    switch (category) {
      case "DedicatedTeam":
        return <CreateTeam />;
      case "ManagedTeam":
        return <ManagedTeam />;
      case "Recruitment":
        return <RecruitmentForm />;
      default:
        null;
    }
  };
  const displayNextLetter = (index: number) => {
    if (index < welcomeMessageTobeDisplayed.length) {
      setWelcomeText((prev) => prev + welcomeMessageTobeDisplayed[index]);
      setTimeout(() => {
        displayNextLetter(index + 1);
      }, 100); // Adjust the timeout duration as needed
    }
  };
  useEffect(() => {
    if (welcomeText !== "" && sectionWrapperRef.current) {
      sectionWrapperRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [mainCategory]);
  useEffect(() => {
    displayNextLetter(0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Moxelle | Get A Quote</title>
        <meta
          name="description"
          content="Moxelle is an IT recruitment and digital solutions company with over 7 years of experience. We have a proven track record of success in helping businesses of all sizes find the right talent and achieve their goals."
        />
      </Helmet>
      <Navigation>
        <HeroSectionVariation url={bg_image1}>
          <div style={{ margin: `${breakpoint ? "100px" : "220px"} auto`,width:"80%" }}>
            <div>
              <HeroPretitleVariation>
                Let’s hear what is your dream so we can make it come true.
                <br /> {welcomeText}
              </HeroPretitleVariation>
            </div>
            <MainOptionsCategory
              setMainCategory={setMainCategory}
              mainCategory={mainCategory}
            />
            <div ref={sectionWrapperRef} style={{ padding: "48px 0" }}>
              {displayForm(mainCategory)}
            </div>
          </div>
        </HeroSectionVariation>
      </Navigation>
      <Toaster />
    </>
  );
};
