export const sizes = {
  smallMobile: "320px",
  mobile: "480px",
  phablet: "560px",
  smallTablet: "650px",
  tablet: "768px",
  tabletToLaptop: "850px",
  laptop: "980px",
  largeLaptop: "1090px",
  smallDesktop: "1280px",
  desktop: "1380px",
  largeDesktop: "1440px",
};
