import React, { useEffect } from 'react'
import {
  Aside,
  ContainerWrapper,
  HeroBlogPostVariation,
  HeroPretitle,
  ServicePageHeroContainer
} from '../navigation/navigation.styled'
import {
  AboutUsSideText,
  SectionWrapper
} from '../about-us-components/aboutus.styled'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Navigation } from '../navigation/Navigation'
import { HeroSection } from '../navigation/HeroSection'
import { PostsInt } from './BlogPosts'
import { clipPathVariable } from '../../constants/constants'
import { BlogTextContainer } from '../clients-and-partners/client-partners.styled'
import { Helmet } from 'react-helmet'

export const SingleBlog = ({
  _id,
  blog_title,
  blog_desc,
  blog_text,
  blog_img
}: PostsInt) => {
  useEffect(() => {
    AOS.init({
      duration: 350,
      once: true,
      easing: 'ease-out'
    })

    AOS.refresh()
    return () => {
      AOS.refresh()
    }
  }, [])
  return (
    <>
      <Helmet>
        <meta property='og:title' content={blog_title} />
        <meta property='og:description' content={blog_desc} />
        <meta property='og:image' content={blog_img.url} />
        {/* Other Open Graph tags or regular meta tags can go here */}
      </Helmet>
      <Navigation>
        <div style={{ height: '40vh' }}>
          <HeroSection
            clipPath={clipPathVariable}
            url={
              'https://res.cloudinary.com/dfolafoax/image/upload/v1695120510/blog-image_sh86xz.webp'
            }
          >
            <ServicePageHeroContainer justifyContent>
              <div>
                <HeroBlogPostVariation
                  style={{ letterSpacing: '1px' }}
                  whitespace
                >
                  {blog_title}
                </HeroBlogPostVariation>
                <HeroPretitle>{blog_desc}</HeroPretitle>
              </div>
            </ServicePageHeroContainer>
          </HeroSection>
        </div>
      </Navigation>
      <ContainerWrapper>
        <Aside>
          <AboutUsSideText
            margin='0px'
            style={{ width: '104px' }}
          ></AboutUsSideText>
        </Aside>
        <SectionWrapper>
          <BlogTextContainer>
            <img src={blog_img.url} alt='blog-post' />
            <div
              data-aos='zoom-in'
              style={{ flex: '0 0 45%', marginTop: '40px' }}
              dangerouslySetInnerHTML={{ __html: blog_text ? blog_text : '' }}
            />
          </BlogTextContainer>
        </SectionWrapper>
      </ContainerWrapper>
    </>
  )
}
