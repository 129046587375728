import styled from 'styled-components'
import { media } from '../../style-utils/media-queries'
interface MarginProps {
  margin?: string
  paddingLeft?: boolean
}
interface BackgroundColor {
  backgroundColor?: boolean
}

interface PaddingProps {
  padding?: boolean
}
export const AboutUsSideText = styled.p<MarginProps>`
  padding-left: ${props => (props.paddingLeft ? '50px' : '15px')};
  font-weight: ${props => props.theme.font_weights.bold};
  margin: ${props => (props.margin ? props.margin : '600px 0 0 0')};
  white-space: nowrap;
  font-size: ${props => props.theme.sizes.extraLarge5};
  line-height: 1.2;
  color: ${props => props.theme.colors.primary['500']};
  transform: rotate(-90deg);
  ${media.largeLaptop`
 display:none;
  ;`}
`

export const Subtitle = styled.h2`
  font-weight: ${props => props.theme.font_weights.bold};
  font-size: ${props => props.theme.sizes.extraLarge2};
  line-height: 1.2;
  letter-spacing: ${props => props.theme.negative_values.minusOne};
  max-width: 1357px;
  margin-left: auto;
  margin-right: auto;
  ${media.laptop`
  margin-top:15px;
  // text-align:center;
  `}
`
export const AboutSectionFlexContainer = styled.div<PaddingProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.tabletToLaptop`
  flex-direction:column-reverse;
  `}
  padding-bottom: ${props =>
    props.padding ? props.theme.sizes.extraLarge3 : '0px'};
  & p {
    font-weight: ${props => props.theme.font_weights.extraLight};
    font-size: ${props => props.theme.sizes.medium};
    line-height: 19px;
    margin-bottom: 30px;
  }
`
export const SectionWrapper = styled.div<BackgroundColor>`
  padding: ${props => props.theme.sizes.extraLarge4}
    ${props => props.theme.sizes.extraLarge6} 0;
  background-color: ${props =>
    props.backgroundColor
      ? props.theme.colors.space_cadet800
      : props.theme.colors.light};
  color: ${props =>
    props.backgroundColor
      ? props.theme.colors.secondary
      : props.theme.colors.dark};
  flex-grow: 1;
  overflow: hidden;
  // transition: all 2s ease-in;
  @media (min-width: 1550px) {
    max-width: ${props => (props.backgroundColor ? '100%' : '1357px')};
    margin: 0 auto;
  }
  ${media.laptop`
  padding: 0px 64px 30px 64px;
  `}
  ${media.mobile`
  padding: 0px 24px 30px 24px;
    `}
`

export const SectionWrapperVariation = styled(SectionWrapper)`
  padding: 36px ${props => props.theme.sizes.extraLarge6} 52px;
`

export const AboutSectionTitle = styled.h2`
  font-weight: ${props => props.theme.font_weights.bold};
  letter-spacing: -1px;
  font-size: ${props => props.theme.sizes.extraLarge6};
  line-height: 1.2;
  margin-bottom: 20px;
  color: ${props => props.theme.colors.background};
  & > span {
    color: ${props => props.theme.colors.primary['500']};
  }
`
export const AboutImageContainer = styled.div`
  display: block;
  border-radius: 24px;
  ${media.laptop`
  padding:0px 20% 30px;
 //padding:0px 0px 30px;
  `}
  ${media.tabletToLaptop`
  padding:0px 15% 30px;
 //padding:0px 0px 30px;
  `}
    ${media.tablet`
  padding:0px 10% 30px;
 //padding:0px 0px 30px;
  `}
      ${media.phablet`
 // padding:0px 5% 24px;
 position:relative; 
 left:-24px;
 width:calc(100% + (2 * 24px));
 padding:0px 0px 24px;
 border-radius:0px;
  `}
`

export const ServiceImageContainer = styled(AboutImageContainer)`
  height: 445px;
  padding-bottom: 0;
  ${media.largeDesktop`
height:467px;`}
  ${media.desktop`
height:425px;`}
${media.smallDesktop`
height:405px;`}
${media.largeLaptop`
 padding:0 0 30px;
height:365px;`}
${media.laptop`
height:335px;
`}
${media.tabletToLaptop`
height:385px;`}
${media.tablet`
height:365px;`}
${media.smallTablet`
height:325px;`}
  ${media.phablet`
height:305px;`}
  ${media.smallMobile`
height:200px;`}
`
export const AboutImageContainerVariation = styled.figure`
  display: block;
  border-radius: 24px;
  ${media.tablet`
  // padding:0 5%;
  margin-bottom:10px;
  `}
  ${media.phablet`
  position:relative;
  left:-24px;
  width:calc(100% + 48px);
  border-radius:0px;`}
`
export const AboutImage = styled.img`
  overflow: hidden;
  border-radius: 24px;
  ${media.mobile`
border-radius:0px`}
`
export const TechnologyIconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${media.smallTablet`
flex-wrap:wrap;`}
`
export const SingleTechnologyIcon = styled.figure`
  display: flex;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 16px;

  @keyframes spin {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.07);
    }
    100% {
      transform: scale(1);
    }
  }
  & img {
    animation: spin 2s ease-in infinite;
    padding: 4px;
    border-radius: 10px;
    background-color: white;
    width: 40px;
    object-fit: contain;
    height: 40px;
  }
  & p {
    margin: 0 0 0 12px;
  }
  ${media.smallTablet`
  margin-bottom:16px;`}
`
export const AboutSectionFlexContainerVariation = styled(
  AboutSectionFlexContainer
)`
  ${media.tabletToLaptop`
flex-direction:column;
`}
`
export const IndividualProjectSingleImageContainer = styled.div`
  padding: 0 0 0 50px;
  ${media.laptop`
  padding: 0;
  `}
  ${media.phablet`
  //padding: 0 20px;
  padding:0;
  width:calc(100% + (2 * 24px));
  left:-24px;
  position:relative;
  `}
`

export const ListWithBulletsContainer = styled.div`
  & p {
    display: list-item;
    line-height: 1.2;
    font-size: ${props => props.theme.sizes.mediumLarge};
  }
`
