import React from "react";
import {
  ModalImage,
  ModalImgContainer,
  ModalImgContent,
} from "./projects.styled";
import { CloseButton } from "../contact-us-components/create-team/create-team.styled";

interface ModalProps {
  imageUrl: string;
  onClose: () => void;
}
const ImageModal = ({ imageUrl, onClose }: ModalProps) => {
  return (
    <ModalImgContainer onClick={onClose}>
      <ModalImgContent>
        <CloseButton onClick={onClose}>x</CloseButton>
        <ModalImage src={imageUrl} alt="Modal" />
      </ModalImgContent>
    </ModalImgContainer>
  );
};

export default ImageModal;
