import React, { ReactNode, createContext, useState } from 'react';

// Create a context for the toggle value
interface ThemeChange{
    toggleValue:boolean;
    toggle:()=> void;
}
interface ThemeProviderInt {
    children:ReactNode;
}
export const ToggleContext = createContext({} as ThemeChange);

// Create a component to provide the toggle value
 export const ThemeChangeProvider = ({ children }:ThemeProviderInt) => {
  const [toggleValue, setToggleValue] = useState(true);

  // Function to toggle the value
  const toggle = () => {
    setToggleValue(prevValue => !prevValue);
  };

  return (
    <ToggleContext.Provider value={{toggleValue,toggle}}>
      {children}
    </ToggleContext.Provider>
  );
};

