import React, { useContext, useState } from "react";
import axios from "axios";
import { AddEditFormWrapper, Form, Input } from "../form.styled";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PrimaryButton } from "../../navigation/navigation.styled";
import { Context } from "../../../context/JobsContext";
import { LoadingComponent } from "../../loading-component/LoadingComponent";
import { JobProps } from "../../contact-us-components/JobOfferingComponent";

import { apiRoute } from "../../../constants/constants";
interface AddEditJobProps {
  mode: string;
  setMode: React.Dispatch<React.SetStateAction<string>>;
  editJob: JobProps | undefined;
  setEditJob: React.Dispatch<React.SetStateAction<JobProps | undefined>>;
}
export const AddEditJobs = ({
  setEditJob,
  editJob,
  mode,
  setMode,
}: AddEditJobProps) => {
  
  const { setJobs } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState(
    editJob && editJob.job_title ? editJob.job_title : ""
  );
  const [jobDesc, setJobDesc] = useState(
    editJob && editJob.job_desc ? editJob.job_desc : ""
  );
  const [jobLocation, setJobLocation] = useState(
    editJob && editJob.job_location ? editJob.job_location : ""
  );
  const [jobType, setJobType] = useState(
    editJob && editJob.job_type ? editJob.job_type : ""
  );
  const resetForm = () => {
    setJobTitle("");
    setJobDesc("");
    setJobLocation("");
    setJobType("");
    setMode("");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const postData = {
      jobDesc,
      jobLocation,
      jobTitle,
      jobType,
    };
    if (mode === "create") {
      setIsLoading(true);
      await axios
        .post(`${apiRoute}api/jobs`, postData)
        .then((res) => {
          resetForm();
          setJobs(res.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else if (mode === "edit") {
      if (editJob) {
        setIsLoading(true);
        await axios
          .put(`${apiRoute}api/jobs/${editJob._id}`, postData)
          .then((res) => {
            resetForm();
            setJobs(res.data);
          })
          .catch((err) => console.log(err))
          .finally(() => setIsLoading(false));
      }
    }
  };
  return (
    <>
      {isLoading && <LoadingComponent />}
      <AddEditFormWrapper>
        <button
          style={{
            zIndex:999999999,
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          onClick={() => {
            setMode("");
            setEditJob(undefined);
          }}
        >
          <b>X</b>
        </button>
        <Form onSubmit={handleSubmit}>
          <h1>{editJob ? "Edit" : "Add"} Job</h1>
          <div>
            <label htmlFor="jobTitleId">Job Title:</label>
            <Input
              type="text"
              id="jobTitleId"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="jobTypeId">Job Type:</label>
            <Input
              id="jobTypeId"
              value={jobType}
              onChange={(e) => setJobType(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="jobLocationId">Job Location:</label>
            <Input
              id="jobLocationId"
              value={jobLocation}
              onChange={(e) => setJobLocation(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="jobDescriptionId">Job Description:</label>
            <ReactQuill
              style={{ minHeight: "250px" }}
              id="jobDescriptionId"
              value={jobDesc}
              onChange={(e) => setJobDesc(e)}
            />
          </div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </Form>
      </AddEditFormWrapper>
    </>
  );
};
