import React from "react";
import { createContext, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { JobProps } from "../components/contact-us-components/JobOfferingComponent";

import { apiRoute } from "../constants/constants";


export interface ContextData {
  jobs: JobProps[];
  setJobs: (jobs: JobProps[]) => void;
  isLoading:boolean;
  error:string;
}

export interface Props {
  children: React.ReactNode;
}


export const Context = createContext({} as ContextData);

export const Provider = ({ children }: Props) => {
  
  const [jobs, setJobs] = useState<JobProps[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | AxiosError | any >("");

  useEffect(() => {
    axios
      .get(`${apiRoute}api/jobs`)
      .then((res) => setJobs(res.data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Context.Provider
      value={{
       jobs,
        setJobs,
        isLoading,
        error,
      }}
    >
      {children}
    </Context.Provider>
  );
};
