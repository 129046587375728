import React, { useEffect, useRef, useState } from "react";
import {
  Aside,
  ContainerWrapper,
  PrimaryButton,
} from "../navigation/navigation.styled";
import {
  AboutSectionFlexContainerVariation,
  AboutUsSideText,
  SectionWrapper,
} from "../about-us-components/aboutus.styled";
import {
  MobileImageContainer,
  RelativeSubtitleContainer,
} from "./projects.styled";
import { Link } from "react-router-dom";
interface Props {
  title: string;
  projectsDescription: string;
}
import projectsImg from "./../../img/mobilescreensimg.svg";
import { useMediaQuery } from "react-responsive";
export const ProjectsComponent = ({ title, projectsDescription }: Props) => {
  const sideRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const elementRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const [sideHeight, setSideHeight] = useState(600);
  const isMobile = useMediaQuery({ maxWidth: 800 });
  const isMobileToTablet = useMediaQuery({ maxWidth: 980 });
  const isTablet = useMediaQuery({ maxWidth: 1200 });
  const isDesktop = useMediaQuery({ maxWidth: 1400 });
  useEffect(() => {
    if (sideRef.current) {
      setSideHeight(sideRef.current.clientHeight);
    }
  }, [sideHeight, isMobile, isMobileToTablet, isTablet, isDesktop]);
  return (
    <ContainerWrapper>
      <Aside ref={sideRef}>
        <AboutUsSideText
          ref={elementRef}
          margin={`calc(${sideHeight - 100}px)0 0 0`}
        >
          Projects
        </AboutUsSideText>
      </Aside>
      <SectionWrapper>
        <AboutSectionFlexContainerVariation>
          <RelativeSubtitleContainer>
            <h3>
              {title} <span></span>
            </h3>
            <p>{projectsDescription}</p>
            <Link to="/projects">
              <PrimaryButton>View Projects</PrimaryButton>
            </Link>
          </RelativeSubtitleContainer>
          <MobileImageContainer>
            <img loading="lazy" src={projectsImg} alt="mobile-screens" />
          </MobileImageContainer>
        </AboutSectionFlexContainerVariation>
      </SectionWrapper>
    </ContainerWrapper>
  );
};
