import React, { useEffect } from 'react'
import {
  Aside,
  ContainerWrapper,
  HeroBlogPostVariation,
  HeroPretitle,
  ServicePageHeroContainer
} from '../navigation/navigation.styled'
import {
  AboutUsSideText,
  SectionWrapper
} from '../about-us-components/aboutus.styled'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Navigation } from '../navigation/Navigation'
import { HeroSection } from '../navigation/HeroSection'
import { JobProps } from './JobOfferingComponent'
import { CareerDescription } from './contact-us.styled'
import { ContactModal } from '../contact-modal/ContactModal'

export const SingleJob = ({
  _id,
  job_desc,
  job_location,
  job_title,
  job_type
}: JobProps) => {
  useEffect(() => {
    AOS.init({
      duration: 350,
      once: true,
      easing: 'ease-out'
    })

    AOS.refresh()
    return () => {
      AOS.refresh()
    }
  }, [])
  return (
    <>
      <Navigation>
        <div style={{ height: '40vh' }}>
          <HeroSection
            url={
              'https://res.cloudinary.com/dfolafoax/image/upload/v1695120752/career-bg-img_osisp0.webp'
            }
          >
            <ServicePageHeroContainer justifyContent>
              <div>
                <HeroBlogPostVariation
                  style={{ padding: '100px 0 0' }}
                  whitespace
                >
                  {job_title}
                </HeroBlogPostVariation>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <HeroPretitle style={{ maxWidth: '200px', margin: '20px 0' }}>
                    {job_type}
                  </HeroPretitle>
                  <HeroPretitle
                    style={{ maxWidth: '200px', margin: '20px 0 0 20px' }}
                  >
                    {job_location}
                  </HeroPretitle>
                </div>
              </div>
            </ServicePageHeroContainer>
          </HeroSection>
        </div>
      </Navigation>
      <ContainerWrapper>
        <Aside>
          <AboutUsSideText margin='0px' style={{ width: '104px' }} />
        </Aside>
        <SectionWrapper>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column-reverse'
            }}
          >
            <CareerDescription>
              <div
                data-aos='zoom-in'
                style={{
                  flex: '0 0 45%',
                  margin: '40px 0'
                }}
                dangerouslySetInnerHTML={{ __html: job_desc ? job_desc : '' }}
              ></div>
            </CareerDescription>
          </div>
          <ContactModal />
        </SectionWrapper>
      </ContainerWrapper>
    </>
  )
}
