import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Form, Input } from "../form.styled";
import aboutusSvg from "./screens/aboutuspage.svg";
import axios from "axios";

import { LoadingComponent } from "../../loading-component/LoadingComponent";
import {
  AboutpageDatainterface,
  Context,
} from "../../../context/AboutPageDataContext";
import { apiRoute } from "../../../constants/constants";
import ReactQuill from "react-quill";

interface EditHomePageProps {
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}

export const EditAboutPage = ({ setCurrentTab }: EditHomePageProps) => {
  const { aboutpage, setAboutpage } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [statsParagraph, setStatsParagraph] = useState(
    aboutpage ? aboutpage.stats_paragraph_containerOne : ""
  );
  const [statsParagraphTwo, setStatsParagraphTwo] = useState(
    aboutpage ? aboutpage.stats_paragraph_containerTwo : ""
  );
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<AboutpageDatainterface>({
    defaultValues: {
      banner_title: aboutpage ? aboutpage.banner_title : "",
      stats_paragraph_containerOne: aboutpage
        ? aboutpage.stats_paragraph_containerOne
        : "",
      stats_paragraph_containerTwo: aboutpage
        ? aboutpage.stats_paragraph_containerTwo
        : "",
      offices_title: aboutpage ? aboutpage.offices_title : "",
      offices_desc: aboutpage ? aboutpage.offices_desc : "",
    },
  });
  const handleStatsParagraphChange = (content: string) => {
    setStatsParagraph(content);
  };
  const handleStatsParagraphTwoChange = (content: string) => {
    setStatsParagraphTwo(content);
  };
  // Register the stats paragraph content with react-hook-form
  useEffect(() => {
    register("stats_paragraph_containerOne"); // Register the input field with the name "stats_paragraph_containerOne"
    setValue("stats_paragraph_containerOne", statsParagraph); // Set the initial value if needed
    register("stats_paragraph_containerTwo"); // Register the input field with the name "stats_paragraph_containerOne"
    setValue("stats_paragraph_containerTwo", statsParagraphTwo); // Set the initial value if needed
  }, [register, setValue, statsParagraph, statsParagraphTwo]);

  const onSubmit: SubmitHandler<AboutpageDatainterface> = async (data) => {
    if (aboutpage) {
      setIsLoading(true);
      await axios
        .put(`${apiRoute}api/aboutpage/${aboutpage._id}`, data)
        .then((res) => {
          setAboutpage(res.data);
          reset();
          setCurrentTab("");
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <div style={{ display: "flex" }}>
          {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
          <Form style={{ maxWidth: "90%" }} onSubmit={handleSubmit(onSubmit)}>
            {/* register your input into the hook by invoking the "register" function */}
            <div>
              <h3>Banner Section</h3>
              <label htmlFor="banner_title">Banner title:</label>
              <Input id="banner_title" {...register("banner_title")} />
            </div>
            <div>
              <h3>Stats Section</h3>
              <label htmlFor="stats_paragraph_containerOne">
                Stats Paragraph 1:
              </label>
              <ReactQuill
                style={{ minHeight: "250px" }}
                value={statsParagraph}
                onChange={handleStatsParagraphChange}
              />

              <label htmlFor="stats_paragraph_containerTwo">
                Stats Paragraph 2:
              </label>
              <ReactQuill
                style={{ minHeight: "250px" }}
                value={statsParagraphTwo}
                onChange={handleStatsParagraphTwoChange}
              />
            </div>
            <div>
              <h3>Offices Section</h3>
              <label htmlFor="offices_title">Offices Section Title:</label>
              <Input id="offices_title" {...register("offices_title")} />
              <label htmlFor="offices_desc">Offices Section Description:</label>
              <Input id="offices_desc" {...register("offices_desc")} />
            </div>
            <Button type="submit">Submit Changes</Button>
          </Form>
          <div style={{ width: "350px", marginRight: "20px" }}>
            <img src={aboutusSvg} alt="homepage-screenshot" />
          </div>
        </div>
      )}
    </>
  );
};
