import React, { useContext, useState } from "react";
import {
  Button,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "../form.styled";
import { Context } from "../../../context/JobsContext";
import axios from "axios";
import { LoadingComponent } from "../../loading-component/LoadingComponent";
import { JobProps } from "../../contact-us-components/JobOfferingComponent";
import { AddEditJobs } from "./addJobs";

import { apiRoute } from "../../../constants/constants";

export const Jobs = () => {
  const [mode, setMode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editJob, setEditJob] = useState<JobProps | undefined>();
  const { jobs, setJobs } = useContext(Context);
  const handleEditMode = (id: string) => {
    setMode("edit");
    scrollToTop();
    setEditJob(jobs.find((job) => job._id === id));
  };
  const handleDelete = async (id: string) => {
    const confirmation = confirm(
      "The job will be deleted from the database.Please confirm that you want to delete the job"
    );

    if (confirmation) {
      setIsLoading(true);
      await axios
        .delete(`${apiRoute}api/jobs/${id}`)
        .then((res) => {
         // console.log(res);
          setJobs(res.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      {isLoading && <LoadingComponent />}
      <div
        style={{
          display: "flex",
          width: "100%",
          marginBottom: "10px",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{ marginRight: "35px" }}
          onClick={() => {
            setMode("create");
            scrollToTop();
          }}
        >
          + Add new job
        </Button>
      </div>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Title</TableHeader>
            <TableHeader>Type</TableHeader>
            <TableHeader>Location</TableHeader>
            <TableHeader>Description</TableHeader>
            <TableHeader>Actions</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {jobs &&
            jobs.length > 0 &&
            jobs.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.job_title}</TableCell>
                <TableCell>{item.job_type}</TableCell>
                <TableCell>{item.job_location}</TableCell>
                <TableCell
                  dangerouslySetInnerHTML={{
                    __html: item
                      ? item.job_desc.slice(0, item.job_desc.indexOf("."))
                      : "",
                  }}
                />
                <TableCell>
                  <Button onClick={() => handleEditMode(item._id)}>Edit</Button>
                  <Button bg onClick={() => handleDelete(item._id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </tbody>
      </Table>
      {mode !== "" && (
        <AddEditJobs
          setEditJob={setEditJob}
          editJob={editJob}
          setMode={setMode}
          mode={mode}
        />
      )}
    </>
  );
};
