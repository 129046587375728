import React from "react";
export interface IconProps {
  color?: string;
}
export const Mobile = ({ color }: IconProps) => {
  return (
    <svg
      style={{ display: "block" }}
      width="100%"
      height="45"
      viewBox="0 0 35 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.97925 64.5833C2.99591 64.5833 2.1355 64.1458 1.398 63.2708C0.660498 62.3958 0.291748 61.375 0.291748 60.2083V4.79166C0.291748 3.62499 0.660498 2.60416 1.398 1.72916C2.1355 0.854157 2.99591 0.416656 3.97925 0.416656H31.0209C32.0042 0.416656 32.8647 0.854157 33.6022 1.72916C34.3397 2.60416 34.7084 3.62499 34.7084 4.79166V60.2083C34.7084 61.375 34.3397 62.3958 33.6022 63.2708C32.8647 64.1458 32.0042 64.5833 31.0209 64.5833H3.97925ZM3.97925 53.6458V60.2083H31.0209V53.6458H3.97925ZM17.5108 59.1146C18.0363 59.1146 18.4732 58.9037 18.8214 58.482C19.1697 58.0603 19.3438 57.5377 19.3438 56.9143C19.3438 56.2909 19.1661 55.7726 18.8107 55.3594C18.4552 54.9462 18.0148 54.7396 17.4893 54.7396C16.9639 54.7396 16.527 54.9504 16.1787 55.3721C15.8305 55.7939 15.6563 56.3164 15.6563 56.9398C15.6563 57.5633 15.834 58.0816 16.1895 58.4948C16.545 58.908 16.9854 59.1146 17.5108 59.1146ZM3.97925 49.2708H31.0209V11.3542H3.97925V49.2708ZM3.97925 6.97916H31.0209V4.79166H3.97925V6.97916Z"
        fill={color}
      />
    </svg>
  );
};
