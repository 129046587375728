import React from "react";
export interface IconProps {
  color?: string;
}
export const Web = ({ color }: IconProps) => {
  return (
    <svg
      style={{ display: "block" }}
      width="100%"
      height="45"
      viewBox="0 0 51 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M13.2073 26.9104C13.7817 26.9104 14.2634 26.7195 14.6527 26.3375C15.0419 25.9556 15.2365 25.4823 15.2365 24.9178C15.2365 24.3531 15.0423 23.8795 14.6538 23.4969C14.2653 23.1142 13.7838 22.9229 13.2095 22.9229C12.6352 22.9229 12.1534 23.1139 11.7641 23.4958C11.3749 23.8777 11.1803 24.351 11.1803 24.9156C11.1803 25.4802 11.3745 25.9538 11.763 26.3365C12.1516 26.7191 12.633 26.9104 13.2073 26.9104ZM13.2073 17.2437C13.7817 17.2437 14.2634 17.0528 14.6527 16.6709C15.0419 16.2889 15.2365 15.8157 15.2365 15.2511C15.2365 14.6865 15.0423 14.2128 14.6538 13.8302C14.2653 13.4476 13.7838 13.2562 13.2095 13.2562C12.6352 13.2562 12.1534 13.4472 11.7641 13.8291C11.3749 14.2111 11.1803 14.6843 11.1803 15.2489C11.1803 15.8135 11.3745 16.2872 11.763 16.6698C12.1516 17.0524 12.633 17.2437 13.2073 17.2437ZM18.1251 26.7292H40.2501V23.1042H18.1251V26.7292ZM18.1251 17.0625H40.2501V13.4375H18.1251V17.0625ZM16.2813 44.25V39.4167H4.60425C3.62091 39.4167 2.7605 39.0542 2.023 38.3292C1.2855 37.6042 0.916748 36.7583 0.916748 35.7917V4.375C0.916748 3.40833 1.2855 2.5625 2.023 1.8375C2.7605 1.1125 3.62091 0.75 4.60425 0.75H46.3959C47.3792 0.75 48.2397 1.1125 48.9772 1.8375C49.7147 2.5625 50.0834 3.40833 50.0834 4.375V35.7917C50.0834 36.7583 49.7147 37.6042 48.9772 38.3292C48.2397 39.0542 47.3792 39.4167 46.3959 39.4167H34.7188V44.25H16.2813ZM4.60425 35.7917H46.3959V4.375H4.60425V35.7917Z"
      />
    </svg>
  );
};
