import React, { useContext, useEffect, useState } from "react";
import {
  AboutUsSideText,
  SectionWrapper,
} from "../about-us-components/aboutus.styled";
import { MainContentPicker, MenuPanel, PanelWrapper } from "./form.styled";
import arrowUp from "./../../img/up-arrow.svg";
import { Aside, ContainerWrapper } from "../navigation/navigation.styled";
import { Blogs } from "./blogs/Blogs";
import { Jobs } from "./jobs/Jobs";
import { Testimonials } from "./testimonials/Testimonials";
import { EditPageContent } from "./edit-page-content/EditPageContent";
import { Gallery } from "./gallery/Gallery";
import { Projects } from "./projects/Projects";
import { COLORS, apiRoute } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import axios from "axios";
export const AdminPanel = () => {
  const tabArray: string[] = [
    "Projects",
    "Gallery",
    "Blogs",
    "Jobs",
    "Testimonials",
  ];
  const pages: string[] = [
    "Home",
    "Services",
    "About",
    "ProjectsPage",
    "Career",
    "PrivacyPolicy",
  ];
  const [currentTab, setCurrentTab] = useState("");
  const [toggler, setToggler] = useState(false);
  const renderTabContent = (tab: string) => {
    switch (tab) {
      case "Blogs":
        return <Blogs />;
      case "Jobs":
        return <Jobs />;
      case "Testimonials":
        return <Testimonials />;
      case "Gallery":
        return <Gallery />;
      case "Projects":
        return <Projects />;
      default:
        return (
          <EditPageContent
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
          />
        );
    }
  };

  const token = localStorage.getItem('token') as string;
  const navigate = useNavigate();

  useEffect(()=>{
    let isMounted = true;  // To track if component is still mounted

    if (!token) {
      navigate('/login', {replace:false})
      return;
    }

    axios
      .post(`${apiRoute}api/tokenCheck`, null, {
        headers: {
          Authorization: token,
        },
      })
      .then(() => {
        if (isMounted) navigate('/admin', {replace:false});
      })
      .catch(() => {
        if (isMounted) navigate('/login', {replace:false});
      })
      return () => {
        isMounted = false; // Cleanup on unmount
      };
      
  },[token])
 

  return (
    <ContainerWrapper>
      {/* <Register/>
      <Login/> */}
      <Aside>
        <AboutUsSideText margin={`500px 0 0 0`}>Admin</AboutUsSideText>
      </Aside>
      <SectionWrapper style={{ minHeight: "80vh", position: "relative" }}>
        {currentTab && (
          <MenuPanel onClick={() => setToggler((prev) => !prev)}>
            <h2>{!toggler ? "EXTEND MENU" : "HIDE MENU"}</h2>
            <img
              style={{
                transform: `rotate(${!toggler ? "180" : "0"}deg)`,
              }}
              src={arrowUp}
              alt="arrow"
            />
          </MenuPanel>
        )}
        <PanelWrapper
          style={{
            marginBottom: "50px",
          }}
        >
          <MainContentPicker
            style={{backgroundColor:COLORS.backgroundLight,
              transition:"height 0.3s ease-in",
              height:
                !currentTab && toggler
                  ? "500px"
                  : currentTab && !toggler
                  ? "0px"
                  : "500px",
            }}
          >
            <h2>Edit Content per Page</h2>
            <div className="admin-nav-container">
              <ul className="panel-class">
                {pages.map((page, i) => (
                  <li
                    className={page === currentTab ? "active" : undefined}
                    key={i}
                    onClick={() => {
                      setCurrentTab(page);
                      setToggler(false);
                    }}
                  >
                    {page}
                  </li>
                ))}
              </ul>
            </div>
            <h2>Edit Services Individually</h2>
            <div className="admin-nav-container">
              <ul className="panel-class">
                {tabArray.map((tab, i) => (
                  <li
                    className={tab === currentTab ? "active" : undefined}
                    key={i}
                    onClick={() => {
                      setCurrentTab(tab);
                      setToggler(false);
                    }}
                  >
                    {tab}
                  </li>
                ))}
              </ul>
            </div>
          </MainContentPicker>
          <h1 style={{marginLeft:"36px",fontSize:"42px"}}>{currentTab}</h1>
          {renderTabContent(currentTab)}
        </PanelWrapper>
      </SectionWrapper>
    </ContainerWrapper>
  );
};
