import { Link, useLocation } from "react-router-dom";
import React, { ReactNode } from "react";
import "./navigation.css";
import { Aside, ContainerWrapper, SocialList } from "./navigation.styled";
import { useMediaQuery } from "react-responsive";
import { AsideNoText } from "../services-components/services.styled";
import { socialListObject } from "../../constants/constants";

interface NavigationProps {
  children?: ReactNode;
}
export const Navigation = ({ children }: NavigationProps) => {
  const { pathname } = useLocation();
  const isExtraLarge = useMediaQuery({ minWidth: 1550 });
  const isSmall = useMediaQuery({ maxWidth: 980 });
  return (
    <ContainerWrapper>
      {!pathname.includes("/blog") && !pathname.includes("/career/") ? (
        <Aside>
          <SocialList>
            {socialListObject.map((item, i) => (
              <li key={i}>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.name}
                </a>
              </li>
            ))}
          </SocialList>
        </Aside>
      ) : (
        <AsideNoText style={{ height: isSmall ? "350px" : "300px" }} />
      )}
      <div
        style={{
          width: "100%",
          position: "relative",
          height:
            pathname.includes("/blog") || pathname.includes("/career/")
              ? "300px"
              : isExtraLarge && !pathname.includes("/get-a-quote")
              ? "750px"
              : undefined,
        }}
      >
        {children}
      </div>
    </ContainerWrapper>
  );
};
