import React, { useContext, useState } from "react";
import axios from "axios";
import { AddEditFormWrapper, Button, Form, Input } from "../form.styled";
import "react-quill/dist/quill.snow.css";
import { PrimaryButton } from "../../navigation/navigation.styled";
import { Context } from "../../../context/ProjectsContext";
import { LoadingComponent } from "../../loading-component/LoadingComponent";
import { apiRoute } from "../../../constants/constants";
import { ProjectsInterface } from "../../project-components/IndividualProjectVariation";
import { Toaster, toast } from "react-hot-toast";
import ReactQuill from "react-quill";
interface AddEditProjectsProps {
  mode: string;
  setMode: React.Dispatch<React.SetStateAction<string>>;
  editProject: ProjectsInterface | undefined;
  setEditProject: React.Dispatch<
    React.SetStateAction<ProjectsInterface | undefined>
  >;
}
interface Technologies {
  name: string;
  value: { public_id: string; url: string };
}
const IMAGES_ARRAY_INIT_STATE = [
  { url: "", public_id: "" },
  { url: "", public_id: "" },
  { url: "", public_id: "" },
  { url: "", public_id: "" },
];
const TECH_ARRAY_INIT_STATE = [{ name: "", value: { public_id: "", url: "" } }];
export const AddEditProjects = ({
  setEditProject,
  editProject,
  mode,
  setMode,
}: AddEditProjectsProps) => {
  const { setProjects } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [projectTitle, setProjectTitle] = useState(
    editProject && editProject.title ? editProject.title : ""
  );

  const [projectSideText, setProjectSideText] = useState(
    editProject && editProject.side_text ? editProject.side_text : ""
  );
  const [projectWebLink, setProjectWebLink] = useState(
    editProject && editProject.links && editProject.links.desktop
      ? editProject.links.desktop
      : ""
  );
  const [projectAndroidLink, setProjectAndroidLink] = useState(
    editProject && editProject.links && editProject.links.android
      ? editProject.links.android
      : ""
  );
  const [projectIosLink, setProjectIosLink] = useState(
    editProject && editProject.links && editProject.links.ios
      ? editProject.links.ios
      : ""
  );
  const [projectCompanyProfile, setProjectCompanyProfile] = useState(
    editProject && editProject.company_profile
      ? editProject.company_profile
      : ""
  );
  const [projectGoals, setProjectGoals] = useState(
    editProject && editProject.project_goals ? editProject.project_goals : ""
  );
  const [technologies, setTechnologies] = useState<Technologies[]>(
    editProject && editProject.technologies
      ? editProject.technologies
      : TECH_ARRAY_INIT_STATE
  );
  const [projectImages, setProjectImages] = useState<any[]>(
    editProject && editProject.images
      ? editProject.images
      : IMAGES_ARRAY_INIT_STATE
  );

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    if (e.target.name === "file" && e.target.files) {
      const file = e.target.files[0];
      setFileToBase(file, i);
    } else if (e.target.name === "text") {
      setTechnologies((prev) =>
        prev.map((tech, index) =>
          index === i ? { ...tech, name: e.target.value } : tech
        )
      );
    }
  };

  const setFileToBase = (file: File | Blob, i: number) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setTechnologies((prev) =>
        prev.map((tech, index) =>
          index === i
            ? {
                ...tech,
                value: { ...tech.value, url: reader.result as string },
              }
            : tech
        )
      );
    };
  };

  const handleProjectImageCollection = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFileToBaseOnCollection(file, i);
    }
  };
  const setFileToBaseOnCollection = (file: File | Blob, i: number) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const updatedArray = projectImages.map((link, index) =>
        index === i ? { ...link, url: reader.result } : link
      );
      setProjectImages(updatedArray);
    };
  };

  const resetForm = () => {
    setProjectSideText("");
    setProjectTitle("");
    setProjectCompanyProfile("");
    setProjectGoals("");
    setProjectWebLink("");
    setProjectIosLink("");
    setTechnologies(TECH_ARRAY_INIT_STATE);
    setProjectAndroidLink("");
    setProjectImages(IMAGES_ARRAY_INIT_STATE);
    setMode("");
  };

  const handleDeleteTechnology = (i: number) => {
    const filteredTechnologies = technologies.filter((_, index) => index !== i);
    setTechnologies(filteredTechnologies);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      projectGoals === "" ||
      projectAndroidLink == "" ||
      projectIosLink == "" ||
      projectWebLink === "" ||
      projectTitle === "" ||
      projectCompanyProfile === "" ||
      projectSideText === "" ||
      projectSideText === null
    ) {
      toast.error("Fill all the fields before submitting");
      return;
    } else if (
      technologies.some((tech) => tech.name === "" || tech.value.url === "")
    ) {
      toast.error(
        "Please fill the technology fileds before submitting the form"
      );
      return;
    } else if (projectImages.some((img) => img.url === "")) {
      toast.error("4 images required for the collection");
      return;
    } else if (projectSideText.length > 20) {
      toast.error("Side text has to be less than 20 characters");
      return;
    } else if (projectTitle.length > 30) {
      toast.error("Title has to be less than 30 characters");
      return;
    }
    toast("The form is uploading.Please wait...");
    const postData = {
      projectSideText: projectSideText,
      projectTitle: projectTitle,
      projectCompanyProfile: projectCompanyProfile,
      links: {
        ios: projectIosLink,
        android: projectAndroidLink,
        desktop: projectWebLink,
      },
      projectGoals: projectGoals,
      technologies: technologies,
      images: projectImages,
    };

    if (mode === "create") {
      setIsLoading(true);
      await axios
        .post(`${apiRoute}api/projects`, postData)
        .then((res) => {
          setProjects(res.data);
          resetForm();
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else if (mode === "edit") {
      if (editProject) {
        setIsLoading(true);
        await axios
          .put(`${apiRoute}api/projects/${editProject._id}`, postData)
          .then((res) => {
            resetForm();
            setProjects(res.data);
          })
          .catch((err) => console.log(err))
          .finally(() => setIsLoading(false));
      }
    }
  };

  return (
    <>
      <AddEditFormWrapper>
        {isLoading && <LoadingComponent />}
        <button
          style={{
            zIndex: 999999999,
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          onClick={() => {
            setMode("");
            setEditProject(undefined);
          }}
        >
          <b>X</b>
        </button>
        <Form style={{ maxWidth: "650px" }} onSubmit={handleSubmit}>
          <h1>{editProject ? "Edit" : "Add"} Project</h1>
          {/*Title */}
          <div>
            <label htmlFor="projectTitleId">Project Title:</label>
            <Input
              type="text"
              id="projectTitleId"
              value={projectTitle}
              onChange={(e) => setProjectTitle(e.target.value)}
            />
          </div>
          {/*Side text */}
          <div>
            <label htmlFor="projectSideTextId">Project Side Text:</label>
            <Input
              type="text"
              id="projectSideTextId"
              value={projectSideText}
              onChange={(e) => setProjectSideText(e.target.value)}
            />
          </div>
          {/*Company Profile */}
          <div>
            <label htmlFor="projectCompanyProfileId">
              Project Company Profile:
            </label>
            <ReactQuill
              style={{ minHeight: "250px" }}
              id="projectCompanyProfileId"
              value={projectCompanyProfile}
              onChange={(e) => setProjectCompanyProfile(e)}
            />
          </div>
          {/*Project Goals */}
          <div>
            <label htmlFor="projectGoalsId">Project Goals:</label>
            <ReactQuill
              style={{ minHeight: "250px" }}
              id="projectGoalsId"
              value={projectGoals}
              onChange={(e) => setProjectGoals(e)}
            />
          </div>
          {/*Project Website */}
          <div>
            <label htmlFor="projectWebLinkId">
              Project Website:(Fill the fields with / if the project doesn't
              have any website)
            </label>
            <Input
              id="projectWebLinkId"
              value={projectWebLink}
              onChange={(e) => setProjectWebLink(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="projectAndroidLinkId">
              Project Android download link:
            </label>
            <Input
              id="projectAndroidLinkId"
              value={projectAndroidLink}
              onChange={(e) => setProjectAndroidLink(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="projectIosLinkId">Project IOS download link:</label>
            <Input
              id="projectIosLinkId"
              value={projectIosLink}
              onChange={(e) => setProjectIosLink(e.target.value)}
            />
          </div>
          {/*Technologies Collection */}
          <div>
            <label>Project Technologies:</label>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "76px",
              }}
            >
              <p>Technology Name</p>
              <p>Technology Icon</p>
            </div>
            {technologies.map((tech, i) => (
              <div key={i} style={{ display: "flex", gap: "36px" }}>
                <div>
                  <Input
                    type="text"
                    name="text"
                    value={tech.name}
                    onChange={(e) => handleImage(e, i)}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {tech.value && tech.value.url && (
                    <img
                      style={{ width: "24px", marginRight: "8px" }}
                      src={tech.value.url}
                      alt="technology"
                    />
                  )}
                  <input
                    accept="image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon"
                    type="file"
                    name="file"
                    id="projectImagesId"
                    onChange={(e) => handleImage(e, i)}
                  />
                  <Button
                    style={{ marginTop: "0px" }}
                    type="button"
                    bg
                    onClick={() => handleDeleteTechnology(i)}
                  >
                    Delete Technology
                  </Button>
                </div>
              </div>
            ))}
            <PrimaryButton
              disabled={
                technologies.filter(
                  (tech) =>
                    Object.keys(tech).some((key) => key === "") ||
                    Object.values(tech).some((key) => key.url === "")
                ).length > 0
              }
              type="button"
              onClick={() => {
                setTechnologies((prev) => [
                  ...prev,
                  { name: "", value: { public_id: "", url: "" } },
                ]);
              }}
            >
              Add technology
            </PrimaryButton>
          </div>
          {/*Images Collection */}
          <div style={{ marginTop: "12px" }}>
            <label>Project Images Collection:(All 4 images required)</label>
            {projectImages.map((project_img, i) => (
              <div
                key={i}
                style={{
                  border: project_img.url ? "2px solid gray" : "none",
                  marginBottom: "4px",
                }}
              >
                {project_img.url && (
                  <img
                    style={{ height: "200px" }}
                    src={project_img.url}
                    alt="project"
                  />
                )}
                <input
                  accept="image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon"
                  type="file"
                  onChange={(e) => handleProjectImageCollection(e, i)}
                />
              </div>
            ))}
          </div>
          <PrimaryButton disabled={isLoading} type="submit">
            Submit
          </PrimaryButton>
        </Form>
      </AddEditFormWrapper>
      <Toaster />
    </>
  );
};
