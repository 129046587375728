import React from "react";
import { createContext, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { PostsInt } from "../components/blog-components/BlogPosts";
import { apiRoute } from "../constants/constants";


export interface ContextData {
  blogs: PostsInt[];
  setBlogs: (blogs: PostsInt[]) => void;
  isLoading:boolean;
  error:string;
}

export interface Props {
  children: React.ReactNode;
}


export const Context = createContext({} as ContextData);

export const Provider = ({ children }: Props) => {
  
  const [blogs, setBlogs] = useState<PostsInt[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | AxiosError | any >("");

  useEffect(() => {
    axios
      .get(`${apiRoute}api/posts`)
      .then((res) => setBlogs(res.data.reverse()))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Context.Provider
      value={{
        blogs,
        setBlogs,
        isLoading,
        error,
      }}
    >
      {children}
    </Context.Provider>
  );
};
