import React from "react";
import { SpinnerPath, SpinnerSvg } from "./loading-component.styled";

export const LoadingComponent = () => {
  return (
    <SpinnerSvg
    width="65px"
    height="65px"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <SpinnerPath
      className="path"
      fill="none"
      strokeWidth="6"
      strokeLinecap="round"
      cx="33"
      cy="33"
      r="24"
    ></SpinnerPath>
  </SpinnerSvg>
  );
};
