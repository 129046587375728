import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { apiRoute, headers } from "../../constants/constants";
import axios from "axios";

export const ProtectedRoutes = () => {
  const token = localStorage.getItem("token");
  const [pageToBeDisplayed, setPageToBeDisplayed] =
  useState<React.ReactElement | null>(null);
  
  useEffect(() => {
    axios
      .post(`${apiRoute}api/tokenCheck`, null, {
        headers: {
          Authorization: token,
        },
      })
      .then(() => {
        setPageToBeDisplayed(<Outlet />);
      })
      .catch(() => setPageToBeDisplayed(<Navigate to={"/login"} replace />));
  }, []);

  return pageToBeDisplayed;
};
