import React, { CSSProperties, useContext, useState } from "react";
import { COLORS, COLORS_SECONDARY } from "../../../constants/constants";
import { ToggleContext } from "../../../theme/ThemeContext";

export const TwitterIcon = () => {
  const { toggleValue } = useContext(ToggleContext);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const iconFilltransition: CSSProperties = {
    margin: "0 8px 0  0",
    fontSize: "16px",
    cursor: "pointer",
    transition: "fill 0.3s ease-in",
    fill: isHovered
      ? COLORS.light
      : toggleValue
      ? COLORS.primary["500"]
      : COLORS_SECONDARY.primary["500"],
  };
  return (
    <a
      href="https://twitter.com/MoxelleIT"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Moxelle twitter page"
    >
      <svg
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={iconFilltransition}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="32px"
        height="32px"
      >
        <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z" />
      </svg>
    </a>
  );
};
