import {
  AboutUsSideText,
  SectionWrapper,
  Subtitle,
} from "../about-us-components/aboutus.styled";
import { Aside, ContainerWrapper } from "../navigation/navigation.styled";
import React, { useContext, useEffect, useRef, useState } from "react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { useMediaQuery } from "react-responsive";
import "./../blog-components/blog-swiper.css";
import "./client-partners.css";
import {
  BracketsContainer,
  CardCompanyLogo,
  CardFullName,
  CardImageContainer,
  CardPosition,
  CardTestimonial,
  CardWrapper,
  SectionDescription,
} from "./client-partners.styled";
import { ClientPartnersComponentSwiper } from "../../style-utils/inline-styles";
import { ClientLogos } from "./ClientLogos";
import { Brackets } from "./Brackets";
import { Context } from "../../context/TestimonialsContext";

interface Props {
  description: string;
  title: string;
}
export const ClientsPartnersComponent = ({ description, title }: Props) => {
  const { testimonials } = useContext(Context);
  const swiperRef = useRef<SwiperRef>(null);
  const isPhablet = useMediaQuery({ minWidth: 560, maxWidth: 800 });
  const isMobile = useMediaQuery({ maxWidth: 800 });
  const isMobileToTablet = useMediaQuery({ maxWidth: 980 });
  const isTablet = useMediaQuery({ maxWidth: 1200 });
  const isExtraLarge = useMediaQuery({ minWidth: 1440 });
  const sideRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const elementRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const [sideHeight, setSideHeight] = useState(600);

  useEffect(() => {
    if (sideRef.current) {
      setSideHeight(sideRef.current.clientHeight);
    }
  }, [sideHeight, isMobile, isMobileToTablet, isTablet, isExtraLarge]);
  const slidesPerView = isMobile ? 1 : isMobileToTablet ? 2 : isTablet ? 2 : 3;

  const handleSlideChange = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.autoplay.stop();
      swiperRef.current.swiper.autoplay.start();
      swiperRef.current.swiper.autoplay.running;
      swiperRef.current.swiper.autoplay.timeLeft = 4000;
    }
  };
  return (
    <ContainerWrapper>
      <Aside ref={sideRef}>
        <AboutUsSideText
          ref={elementRef}
          margin={`calc(${sideHeight - 100}px)0 0 0`}
        >
          Partners & Clients
        </AboutUsSideText>
      </Aside>
      <SectionWrapper backgroundColor>
        <Subtitle>{title}</Subtitle>
        <Swiper
          grabCursor={true}
          modules={[Autoplay, Pagination]}
          ref={swiperRef}
          style={{
            ...ClientPartnersComponentSwiper,
            maxWidth: "1357px",
            width: isPhablet
              ? "70%"
              : isMobile
              ? "100%"
              : isExtraLarge
              ? "70vw"
              : "80vw",
          }}
          slidesPerView={slidesPerView}
          spaceBetween={30}
          // loop={true}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          pagination={{
            dynamicMainBullets: 1,
            dynamicBullets: true,
            clickable: true,
            renderBullet: function () {
              return '<span class="swiper-pagination-bullet" style="background-color:white;"></span>';
            },
          }}
          onSlideChange={handleSlideChange}
        >
          {testimonials.reverse()
            .sort((a, b) =>
              a.index > b.index ? 1: -1
            )
            .map((card, i) => (
              <SwiperSlide key={i}>
                <CardWrapper data-aos="zoom-in">
                  <BracketsContainer>
                    <Brackets />
                  </BracketsContainer>
                  <CardTestimonial>{card.testimonial_text}</CardTestimonial>
                  <CardPosition>{card.testimonial_position}</CardPosition>
                  <CardFullName>{card.testimonial_name}</CardFullName>
                  <CardCompanyLogo>
                    <img
                      src={card.testimonial_logo.url}
                      alt={`company-${i + 1}`}
                    />
                  </CardCompanyLogo>
                  <CardImageContainer>
                    <img
                      src={card.testimonial_image.url}
                      alt={`person-${i + 1}`}
                    />
                  </CardImageContainer>
                </CardWrapper>
              </SwiperSlide>
            ))}
        </Swiper>
        <SectionDescription>{description}</SectionDescription>
        <ClientLogos />
      </SectionWrapper>
    </ContainerWrapper>
  );
};
