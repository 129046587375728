import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Aside, ContainerWrapper } from "../navigation/navigation.styled";
import {
  AboutUsSideText,
  SectionWrapper,
} from "../about-us-components/aboutus.styled";
import { BenefitsList, InfoParagraph } from "./contact-us.styled";
import { useMediaQuery } from "react-responsive";

export interface Benefit {
  isOpen: boolean;
  name: string;
  description: string;
}
interface Props {
  sideText?: string;
  children?: ReactNode;
  benefits: Benefit[];
}

export const Benefits = ({ benefits, sideText, children }: Props) => {
  const sideRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const [localBenefits, setLocalBenefits] = useState<Benefit[]>([]);
  const elementRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const [sideHeight, setSideHeight] = useState(600);
  const isDesktop = useMediaQuery({ maxWidth: 1280 });
  const isTablet = useMediaQuery({ maxWidth: 900 });
  const isMobile = useMediaQuery({ maxWidth: 700 });
  const isExtraLargeDesktop = useMediaQuery({ maxWidth: 1400 });
  useEffect(() => {
    if (benefits) {
      setLocalBenefits(benefits);
    }
  }, [benefits]);

  useEffect(() => {
    if (sideRef.current && benefits.length > 4) {
      setSideHeight(sideRef.current.clientHeight);
    }
  }, [
    sideRef,
    sideHeight,
    isDesktop,
    isExtraLargeDesktop,
    isMobile,
    isTablet,
    benefits,
  ]);

  const handleCurrentBenefit = (i: number) => {
    const changeBenefits = localBenefits.map((benefit, index) =>
      index === i
        ? { ...benefit, isOpen: !benefit.isOpen }
        : { ...benefit, isOpen: false }
    );
    setLocalBenefits(changeBenefits);
  };

  return (
    <ContainerWrapper>
      <Aside>
        <AboutUsSideText
          ref={elementRef}
          margin={`calc(${sideHeight - 100}px)0 0 0`}
        >
          {sideText}
        </AboutUsSideText>
      </Aside>
      <SectionWrapper ref={sideRef}>
        <InfoParagraph>{children}</InfoParagraph>
        <BenefitsList>
          {localBenefits.map((benefit, i) => (
            <li
              className={benefit.isOpen ? "active-list-item" : undefined}
              onClick={() => handleCurrentBenefit(i)}
              key={i}
            >
              {benefit.name}
              <p
                style={{
                  transition: `max-height ${
                    benefit.isOpen ? "1" : "0"
                  }s ease-out`,
                  overflow: "hidden",
                  maxHeight: benefit.isOpen ? "550px" : "0px",
                }}
              >
                {benefit.description}
              </p>
            </li>
          ))}
        </BenefitsList>
      </SectionWrapper>
    </ContainerWrapper>
  );
};
