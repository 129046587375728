import React, { CSSProperties, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import { Video } from './navigation.styled'

interface HeroSectionProps {
  children?: React.ReactNode
  url: string
  clipPath?: string
}

export const HeroSection = ({ clipPath, children, url }: HeroSectionProps) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 560 })
  const isMobile = useMediaQuery({ maxWidth: 980 })
  const isExtraLarge = useMediaQuery({ minWidth: 1550 })
  const { pathname } = useLocation()

  const videoRef = useRef(null)

  // Function to prevent full-screen
  const preventFullScreen = () => {
    if (document.fullscreenElement === videoRef.current) {
      document.exitFullscreen()
    }
  }

  const heroContainerWrapper: CSSProperties = {
    position: 'absolute',
    display: 'flex',
    top: 0,
    zIndex: 1,
    width: '100%',
    minHeight:
      pathname.includes('/blog') ||
      pathname.includes('/career/') ||
      pathname.includes('/get-a-quote')
        ? '350px'
        : isMobile
        ? '650px'
        : isSmallScreen
        ? '670px'
        : isExtraLarge
        ? '820px'
        : '711px',
    clipPath: clipPath,
    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: pathname.includes('/blog') ? 'right' : 'center',
    backgroundPositionY: pathname.includes('/blog') ? '100%' : '35%',
    backgroundSize: 'cover',
    backgroundAttachment: isMobile ? 'scroll' : 'fixed'
  }

  return pathname === '/' ? (
    <div
      style={{
        minHeight: isMobile
          ? '650px'
          : isSmallScreen
          ? '670px'
          : isExtraLarge
          ? '820px'
          : '711px'
      }}
    >
      <Video
        style={{
          clipPath: clipPath
        }}
        src={url}
        ref={videoRef}
        controls={false}
        onClick={preventFullScreen}
        autoPlay
        loop
        muted
        playsInline
      ></Video>
      {children}
    </div>
  ) : (
    <div style={heroContainerWrapper}>{children}</div>
  )
}
