const getApiRoute = (apiRoute: string | undefined) => {
  switch (apiRoute) {
    case 'dev':
      return process.env.REACT_APP_DEV;
    case 'staging':
      return process.env.REACT_APP_STAGING;
    case 'prod':
      return process.env.REACT_APP_PROD;
    default:
      return process.env.REACT_APP_DEV;
  }
};

export const apiRoute = getApiRoute(process.env.REACT_APP_ENV);

const token = localStorage.getItem("token");

export   const socialListObject = [
  { name: "Instagram", url: "https://www.instagram.com/moxelle_/" },
  { name: "Twitter", url: "https://twitter.com/MoxelleIT" },
  { name: "Linked IN", url: "https://www.linkedin.com/company/moxelle/" },
  { name: "Facebook", url: "https://www.facebook.com/moxelle" },
];
export const headers = {
  "Authorization": `Bearer ${token}`,
  "Content-Type": "application/json",
};
export const compressionOptions = {
  maxSizeMB: 2,
  maxWidthOrHeight: 1500,
  useWebWorker: true,
};

export const COLORS = {
  error:"#DC3444",
  dark: "black",
  light: "white",
  background: "#141B29",
  backgroundLight: "#26344E",
  backgroundWithOpacity: "rgba(20, 27, 41, 0.64)",
  backgroundWithOutOpacity: "rgba(20, 27, 41)",
  formBackgroundDark: "rgba(38, 52, 78, 0.80)",
  secondary: "white",
  parisian_green: "#D6FFFD",
  space_cadet: "#E9EDF4",
  space_cadet800: "#26344E",
  footer_bg: "#081a27",
  neutral: {
    "50": "#eff1f1",
    "100": "#d8dcdc",
    "200": "#c0c7c7",
    "300": "#a8b3b2",
    "400": "#919e9d",
    "500": "#798988",
    "600": "#697776",
    "700": "#515b5b",
    "800": "#384040",
    "900": "#202524",
  },
  primary: {
    "50": "#d6fffd",
    "100": "#aefffa",
    "200": "#48fff4",
    "300": "#00f5e6",
    "400": "#00cdc0",
    "500": "#00A49A",
    "600": "#008e85",
    "700": "#007871",
    "800": "#005752",
    "900": "#002c29",
  },
};

export const COLORS_SECONDARY = {
  error:"#DC3444",
  dark: "white",
  light: "#889ec5",
  background: "white",
  backgroundLight: "white",
  backgroundWithOpacity: "#889ec5",
  backgroundWithOutOpacity: "white",
  secondary: "white",
  parisian_green: "transparent",
  space_cadet: "#E9EDF4",
  space_cadet800: "#116ec5",
  footer_bg: "#889ec5",
  neutral: {
    "50": "#eff1f1",
    "100": "#d8dcdc",
    "200": "#c0c7c7",
    "300": "#a8b3b2",
    "400": "#919e9d",
    "500": "#798988",
    "600": "#697776",
    "700": "#515b5b",
    "800": "#384040",
    "900": "#202524",
  },
  primary: {
    "50": "#d6fffd",
    "100": "#aefffa",
    "200": "#48fff4",
    "300": "#00f5e6",
    "400": "#00cdc0",
    "500": "#116ec5",
    "600": "#008e85",
    "700": "#007871",
    "800": "#005752",
    "900": "#002c29",
  },
};

export const SIZES = {
  xSmall: "8px",
  small: "10px",
  smallMedium: "12px",
  mediumSmall: "14px",
  medium: "16px",
  mediumLarge: "18px",
  large: "18px",
  largeToExtra: "24px",
  extraLarge: "28px",
  extraLarge2: "32px",
  extraLarge3: "32px",
  extraLarge4: "44px",
  extraLarge5: "56px",
  extraLarge6: "60px",
  extraLarge7: "84px",
};
export const NEGATIVE_VALUES = {
  minusOne: "-1px",
  minusTwo: "-2px",
  minusThree: "-3px",
  minusFour: "-4px",
  minusFive: "-5px",
};
export const FONT_WEIGHTS = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};
export const clipPathVariable = "polygon(0 0, 100% 0, 100% 100%, 0 94%)";
