import React from "react";
export interface IconProps {
  color?: string;
}
export const Cloud = ({ color }: IconProps) => {
  return (
    <svg
      style={{ display: "block" }}
      width="100%"
      height="33"
      viewBox="0 0 44 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.55 32.4998C7.61667 32.4998 5.125 31.4748 3.075 29.4248C1.025 27.3748 0 24.8831 0 21.9498C0 19.3498 0.833333 17.0665 2.5 15.0998C4.16667 13.1331 6.28333 11.9498 8.85 11.5498C9.51667 8.31647 11.0833 5.6748 13.55 3.6248C16.0167 1.5748 18.8667 0.549805 22.1 0.549805C25.8333 0.549805 28.9833 1.90814 31.55 4.6248C34.1167 7.34147 35.4 10.5998 35.4 14.3998V15.5998C37.8 15.5331 39.8333 16.3081 41.5 17.9248C43.1667 19.5415 44 21.5831 44 24.0498C44 26.3498 43.1667 28.3331 41.5 29.9998C39.8333 31.6665 37.85 32.4998 35.55 32.4998H10.55ZM10.55 29.4998H35.55C37.05 29.4998 38.3333 28.9665 39.4 27.8998C40.4667 26.8331 41 25.5498 41 24.0498C41 22.5498 40.4667 21.2665 39.4 20.1998C38.3333 19.1331 37.05 18.5998 35.55 18.5998H32.4V14.3998C32.4 11.3665 31.3833 8.7998 29.35 6.6998C27.3167 4.5998 24.8333 3.5498 21.9 3.5498C18.9667 3.5498 16.475 4.5998 14.425 6.6998C12.375 8.7998 11.35 11.3665 11.35 14.3998H10.4C8.33333 14.3998 6.58333 15.1248 5.15 16.5748C3.71667 18.0248 3 19.8165 3 21.9498C3 24.0498 3.73333 25.8331 5.2 27.2998C6.66667 28.7665 8.45 29.4998 10.55 29.4998Z"
        fill={color}
      />
    </svg>
  );
};
