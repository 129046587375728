import React, { ReactNode, useEffect } from "react";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import axios from "axios";
import toast from "react-hot-toast";
import {
  BodyContentWrapper,
  CheckboxContainer,
  CheckboxInput,
  CheckboxLabel,
  CustomBtn,
  ModalContent,
  ModalWrapper,
  ProgressBarContainer,
  RadioContainer,
  RadioInput,
  RadioLabel,
  SelectRoles,
  StyledInput,
  StyledTextarea,
} from "./create-team.styled";
import { devRolesArr, options } from "./servicesArray";
import { COLORS, apiRoute } from "../../../constants/constants";
import { useMediaQuery } from "react-responsive";
import { InputContainer } from "../../admin-panel/form.styled";
import { LoadingComponent } from "../../loading-component/LoadingComponent";

enum STEPS {
  ROLES = 1,
  COMMITMENT = 2,
  SKILLS = 3,
  START = 4,
  SEND = 5,
}
interface FieldValues {
  count: string;
  roles: string[];
  commitment: string;
  skills: string[];
  start: string;
  info: {
    last_name: string;
    first_name: string;
    email: string;
    company_role: string;
    company_name: string;
    phone: string;
    description: string;
  };
}

export const CreateTeam = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState<number>(STEPS.ROLES);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedType, setSelectedTypes] = useState<string[]>([]);
  const breakpoint = useMediaQuery({ maxWidth: 600 });
  const handleTypeValues = (value: string) =>
    selectedType.includes(value)
      ? setSelectedTypes(selectedType.filter((item) => item !== value))
      : setSelectedTypes([...selectedType, value]);

  const handleChange = (value: any) => {
    setSelectedOptions(value);
    setErrorMessage("");
  };
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<FieldValues>({
    defaultValues: {
      count: "",
      roles: [],
      commitment: "",
      skills: [],
      start: "",
      info: {
        last_name: "",
        first_name: "",
        email: "",
        company_role: "",
        company_name: "",
        phone: "",
        description: "",
      },
    },
  });
  const roles = watch("roles");
  const commitment = watch("commitment");
  const start = watch("start");
  const count = watch("count");

  const setCustomValue = (id: string, value: any) => {
    setValue(id as keyof FieldValues, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setErrorMessage("");
  };

  const onBack = () => {
    setStep((value) => value - 1);
    setErrorMessage("");
  };
  const onNext = () => {
    if (step === STEPS.ROLES && roles.length === 0) {
      setErrorMessage("Select one of the options to continue");
      return;
    } else if (step === STEPS.COMMITMENT && commitment === "") {
      setErrorMessage("Select one of the options to continue");
      return;
    } else if (step === STEPS.SKILLS && selectedOptions.length === 0) {
      setErrorMessage("Select one of the options to continue");
      return;
    } else if (step === STEPS.START && start === "") {
      setErrorMessage("Select one of the options to continue");
      return;
    }
    setStep((value) => value + 1);
  };
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    //console.log(data);
    if (step !== STEPS.SEND) {
      return onNext();
    }
    setIsLoading(true);

    await axios
      .post(`${apiRoute}api/create-team`, data)
      .then(() => {
        toast.success("You have successfully created your team");
        reset();
        setSelectedOptions([]);
        setSelectedTypes([]);
        setStep(STEPS.ROLES);
      })
      .catch(() => {
        toast.error("Something went wront.Please try again");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    setCustomValue("roles", selectedType);
  }, [selectedType]);

  let bodyContent = (
    <>
      <h2>What roles will you be hiring for?</h2>
      <div>
        {devRolesArr.map((dev, i) => (
          <CheckboxContainer key={i}>
            <CheckboxInput
              type="checkbox"
              id={dev}
              name="roles"
              value={dev}
              checked={selectedType.includes(dev)}
              onChange={(e) => handleTypeValues(e.target.value)}
            />
            <CheckboxLabel htmlFor={dev} />
            <p className="labels" onClick={() => handleTypeValues(dev)}>
              {dev.replace("and", "&")}
            </p>
          </CheckboxContainer>
        ))}
      </div>
    </>
  );

  if (step === STEPS.COMMITMENT) {
    bodyContent = (
      <div>
        <div>
          <h2>How many tech talents do you need?</h2>
          <StyledInput
            {...register("count", { required: true, pattern: /^[0-9]+$/ })}
            type="text"
            placeholder=""
          />
          {errors.count && (
            <p style={{ color: COLORS.error }}>
              The input field must contain only numbers
            </p>
          )}
        </div>
        <h2>What level of time commitment will you require from the expert?</h2>
        <div>
          <RadioContainer>
            <RadioInput
              type="radio"
              id="Full_Time"
              name="commitment"
              value="Full_Time"
              checked={commitment === "Full_Time"}
              onChange={(value) =>
                setCustomValue("commitment", value.target.value)
              }
            />
            <RadioLabel htmlFor="Full_Time" />
            <p
              className="labels"
              onClick={() => setCustomValue("commitment", "Full_Time")}
            >
              Full-Time
            </p>
          </RadioContainer>
          <RadioContainer>
            <RadioInput
              type="radio"
              id="Part_Time"
              name="commitment"
              value="Part_Time"
              checked={commitment === "Part_Time"}
              onChange={(value) =>
                setCustomValue("commitment", value.target.value)
              }
            />
            <RadioLabel htmlFor="Part_Time" />
            <p
              className="labels"
              onClick={() => setCustomValue("commitment", "Part_Time")}
            >
              Part-Time
            </p>
          </RadioContainer>
        </div>
      </div>
    );
  }
  if (step === STEPS.SKILLS) {
    bodyContent = (
      <SelectRoles>
        <h2>Which skills or expertise do you need?</h2>
        <Controller
          name="skills"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              className="select-roles"
              isMulti
              defaultInputValue={undefined}
              options={options}
              onChange={(selected) => {
                onChange(
                  selected ? selected.map((option, _i) => option.value) : []
                );
                handleChange(selected);
              }}
              value={options.filter(
                (option) => Array.isArray(value) && value.includes(option.value)
              )}
            />
          )}
        />
      </SelectRoles>
    );
  }
  if (step === STEPS.START) {
    bodyContent = (
      <>
        <h2>When are you looking to start?</h2>
        <div>
          <RadioContainer>
            <RadioInput
              type="radio"
              id="Immediately"
              name="start"
              value="Immediately"
              checked={start === "Immediately"}
              onChange={(value) => setCustomValue("start", value.target.value)}
            />
            <RadioLabel htmlFor="Immediately" />
            <p
              className="labels"
              onClick={() => setCustomValue("start", "Immediately")}
            >
              Immediately
            </p>
          </RadioContainer>
          <RadioContainer>
            <RadioInput
              type="radio"
              id="In_1_to_4_weeks"
              name="start"
              value="In_1_to_4_weeks"
              checked={start === "In_1_to_4_weeks"}
              onChange={(value) => setCustomValue("start", value.target.value)}
            />
            <RadioLabel htmlFor="In_1_to_4_weeks" />
            <p
              className="labels"
              onClick={() => setCustomValue("start", "In_1_to_4_weeks")}
            >
              In 1 To 4 Weeks
            </p>
          </RadioContainer>
          <RadioContainer>
            <RadioInput
              type="radio"
              id="In_1_to_2_months"
              name="start"
              value="In_1_to_2_months"
              checked={start === "In_1_to_2_months"}
              onChange={(value) => setCustomValue("start", value.target.value)}
            />
            <RadioLabel htmlFor="In_1_to_2_months" />
            <p
              className="labels"
              onClick={() => setCustomValue("start", "In_1_to_2_months")}
            >
              In 1 To 2 Months
            </p>
          </RadioContainer>
          <RadioContainer>
            <RadioInput
              type="radio"
              id="More_then_2_months_from_now"
              name="start"
              value="More_then_2_months_from_now"
              checked={start === "More_then_2_months_from_now"}
              onChange={(value) => setCustomValue("start", value.target.value)}
            />
            <RadioLabel htmlFor="More_then_2_months_from_now" />
            <p
              className="labels"
              onClick={() =>
                setCustomValue("start", "More_then_2_months_from_now")
              }
            >
              More Than 2 Months From Now
            </p>
          </RadioContainer>
        </div>
      </>
    );
  }
  if (step === STEPS.SEND) {
    bodyContent = (
      <div>
        <h2>Let's kick off your project!</h2>
        <InputContainer>
          <div>
            <StyledInput
              {...register("info.first_name", { required: true })}
              type="text"
              placeholder="Your Name *"
            />
            {errors.info && errors.info.first_name && (
              <p>Please enter your name</p>
            )}
          </div>
          <div>
            <StyledInput
              {...register("info.last_name", { required: true })}
              type="text"
              placeholder="Your Surname *"
            />
            {errors.info && errors.info.last_name && (
              <p>Please enter your surname</p>
            )}
          </div>
        </InputContainer>
        <InputContainer>
          <div>
            <StyledInput
              {...register("info.email", { required: true })}
              type="email"
              placeholder="Your E-mail *"
            />
            {errors.info && errors.info.email && <p>Please enter your email</p>}
          </div>
          <div>
            <StyledInput
              {...register("info.phone", {
                required: true,
                pattern: /^[0-9+]+$/,
              })}
              type="phone"
              placeholder="Your Phone Number *"
            />
            {errors.info && errors.info.phone && (
              <p>Please enter your phone number</p>
            )}
          </div>
        </InputContainer>

        <InputContainer>
          <div>
            <StyledInput
              {...register("info.company_name")}
              type="text"
              placeholder="Your Company Name"
            />
            {/* {errors.info && errors.info.company_name && (
              <p>Please enter your company name</p>
            )} */}
          </div>
          <div>
            <StyledInput
              {...register("info.company_role")}
              type="text"
              placeholder="Your Company Role"
            />
          </div>
        </InputContainer>
        <StyledTextarea
          rows={4}
          {...register("info.description", { required: true })}
          placeholder="Tell us about your project or the challenge you have *"
        />
        {errors.info && errors.info.description && (
          <p style={{ color: COLORS.error }}>The input field is required</p>
        )}
      </div>
    );
  }
  const disableButtons = () => {
    switch (true) {
      case step === 1 && roles.length === 0:
        return true;
      case step === 2 && (commitment === "" || count === ""):
        return true;
      case step === 3 && selectedOptions.length === 0:
        return true;
      case step === 4 && start === "":
        return true;
    }
  };
  return (
    <ModalWrapper>
      {isLoading && <LoadingComponent />}
      <ModalContent>
        <h2>Dedicated Team</h2>
        <ProgressBarContainer>
          <div
            className="inner-bar"
            style={{
              width: `calc(${step * 20}%)`,
              transition: "width 0.4s ease-in",
            }}
          ></div>
        </ProgressBarContainer>
        <div>
          {!breakpoint &&
            Object.entries(STEPS).map(
              (enum_step, i) =>
                i > 4 && (
                  <CustomBtn
                    disabled={i - 4 > step && disableButtons()}
                    style={{
                      backgroundColor:
                        i - 4 > step ? COLORS.light : COLORS.primary["500"],
                      color:
                        i - 4 > step ? COLORS.primary["500"] : COLORS.secondary,
                    }}
                    onClick={() => {
                      errorMessage && setErrorMessage("");
                      setStep(STEPS[enum_step[0] as keyof typeof STEPS]);
                    }}
                    key={i}
                  >
                    <span className="tooltiptext">
                      Select one of the options to continue
                    </span>
                    {enum_step[0]}
                  </CustomBtn>
                )
            )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BodyContentWrapper>
            {bodyContent}
            {errorMessage && (
              <span
                style={{
                  color: COLORS.error,
                  marginBottom: "16px",
                  display: "block",
                }}
              >
                {errorMessage}
              </span>
            )}
          </BodyContentWrapper>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {step > 1 && (
              <CustomBtn type="button" onClick={onBack}>
                Back
              </CustomBtn>
            )}
            <CustomBtn disabled={isLoading} type="submit">
              {step !== STEPS.SEND ? "Next" : "Submit request"}
            </CustomBtn>
          </div>
        </form>
        {isLoading && <LoadingComponent />}
      </ModalContent>
    </ModalWrapper>
  );
};
