import React, { useContext, useState } from "react";
import {
  Button,
  TableImage,
} from "../form.styled";
import { Context, GalleryInt } from "../../../context/GalleryContext";
import { AddImg } from "./AddImg";
import axios from "axios";
import { LoadingComponent } from "../../loading-component/LoadingComponent";

import { apiRoute } from "../../../constants/constants";

export const Gallery = () => {
  
  const [mode, setMode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editImg, setEditImg] = useState<GalleryInt | undefined>();
  const { gallery, setGallery } = useContext(Context);
  const handleEditMode = (id: string) => {
    setMode("edit");
    scrollToTop();
    setEditImg(gallery.find((img) => img._id === id));
  };
  const handleDelete = async (id: string) => {
    const confirmation = confirm(
      "The blog will be deleted from the database.Please confirm that you want to delete the blog"
    );

    if (confirmation) {
      setIsLoading(true);
      await axios
        .delete(`${apiRoute}api/gallery/${id}`)
        .then((res) => {
          setGallery(res.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      {isLoading && <LoadingComponent />}
      <div
        style={{
          display: "flex",
          width: "100%",
          marginBottom: "10px",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{ marginRight: "35px" }}
          onClick={() => {
            setMode("create");
            scrollToTop();
          }}
        >
          + Add new image
        </Button>
      </div>
      <div  style={{display: "flex", flexWrap: "wrap" }}>
        {gallery &&
          gallery.length > 0 &&
          gallery.map((item, index) => (
            <div key={index} style={{ padding: "20px"}}>
              <TableImage style={{margin:"0 auto",width:'210px',height:"150px",objectFit:"cover"}} src={item.gallery_img.url} alt={`Item${index}`} />
              <div>
                <Button style={{marginRight:"10px"}} onClick={() => handleEditMode(item._id)}>Edit</Button>
                <Button bg onClick={() => handleDelete(item._id)}>
                  Delete
                </Button>
              </div>
            </div>
          ))}
      </div>
      {mode !== "" && (
        <AddImg
          setEditImg={setEditImg}
          editImg={editImg}
          setMode={setMode}
          mode={mode}
        />
      )}
    </>
  );
};
