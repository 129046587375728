import styled, { keyframes } from 'styled-components'
import { COLORS } from '../../constants/constants'

interface ButtonBackground {
  bg?: boolean
}
export const jump = keyframes`
  from{
    transform: translateY(0)
  }
  to{
    transform: translateY(-3px)
  }
`
export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`
export const Form = styled.form`
  margin: 0 auto;
  width: 100%;
  max-width: 414px;
  padding: 1.3rem;
  display: flex;
  flex-direction: column;
  position: relative;
  & .ql-editor ul{
    display:block;
    & li{
        font-size 14px;
    }
  }
  & .ql-editor{
    min-height:200px;
}
  & label {
    display:block;
    margin:10px 0;
  }
  & input[type="file"]::file-selector-button {
    margin-bottom:10px;
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
  }
  
  /* file upload button hover state */
   & input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
  }
  
  /* file upload button active state */
  & input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
  }
`
export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & div {
    min-width: 49.5%;
    & input {
      width: 100%;
      box-sizing: border-box;
    }
    & p {
      color: ${props => props.theme.colors.error};
      margin-bottom: 16px;
    }
  }
  @media (max-width: 600px) {
    flex-direction: column;

    & div {
      margin-bottom: 16px;
    }
  }
`
export const Input = styled.input`
  max-width: 100%;
  min-width: 90%;
  padding: 11px 13px;
  background: #f9f9fa;
  margin-bottom: 0.9rem;
  border-radius: 4px;
  outline: 0;
  border: 1px solid rgba(245, 245, 245, 0.7);
  font-size: 14px;
  transition: all 0.3s ease-out;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.1);
  :focus,
  :hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15), 0 1px 5px rgba(0, 0, 0, 0.1);
  }
`
export const TextArea = styled.textarea`
  max-width: 100%;
  min-width: 90%;
  padding: 11px 13px;
  background: #f9f9fa;
  margin-bottom: 0.9rem;
  border-radius: 4px;
  outline: 0;
  border: 1px solid rgba(245, 245, 245, 0.7);
  font-size: 14px;
  transition: all 0.3s ease-out;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.1);
  :focus,
  :hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15), 0 1px 5px rgba(0, 0, 0, 0.1);
  }
`
export const Button = styled.button<ButtonBackground>`
  max-width: 100%;
  min-width: 100px;
  padding: 11px 13px;
  color: rgb(253, 249, 243);
  font-weight: 600;
  text-transform: uppercase;
  background: ${props =>
    props.bg ? props.theme.colors.error : props.theme.colors.primary['500']};
  border: none;
  border-radius: 3px;
  outline: 0;
  cursor: pointer;
  margin-top: 0.6rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
  :hover {
    animation: ${jump} 0.2s ease-out forwards;
  }
`
export const Title = styled.h2`
  font-weight: normal;
  color: ${COLORS.dark};
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
`

export const MenuPanel = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  position: absolute;
  top: 90px;
  right: 90px;

  & h2 {
    margin: 0 8px 0 0;
  }
  & img {
    height: 20px;
    width: 20px;
  }
`
export const MainContentPicker = styled.div`
  overflow: hidden;
  & h2 {
    color: ${props => props.theme.colors.light};
  }
  & ul li {
    color: ${props => props.theme.colors.light};
  }
`
export const PanelWrapper = styled.div`
  position: relative;
  & h2 {
    padding: 48px 0 24px 48px;
  }

  margin-top: 70px;
  background-color: ${COLORS.space_cadet};
  & ul.panel-class {
    list-style-type: none;
    padding: 16px 24px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    font-size: 24px;
    & li {
      cursor: pointer;
      padding: 8px 16px;
    }
    & li.active {
      border: 1px solid ${props => props.theme.colors.primary['500']};
      border-radius: 4px;
      background-color: white;
      color: ${props => props.theme.colors.primary['500']};
    }
    & li:hover {
      border-radius: 4px;
      background-color: white;
      color: ${props => props.theme.colors.primary['500']};
    }
  }
  & .admin-nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`

export const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background-color: #333;
  color: #fff;
`

export const TableCell = styled.td`
  padding: 10px;
`

export const TableImage = styled.img`
  width: 100px;
  height: 75px;
  object-fit: contain;
`

export const AddEditFormWrapper = styled.div`
  border-radius: 20px;
  background-color: whitesmoke;
  position: fixed;
  width: 60%;
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  top: 60px;
  left: 50%;
  transform: translate(-50%);
  z-index: 999999999;
`

export const Select = styled.select`
  height: 45px;
  background: white;
  border-radius: 4px;
  color: black;
  font-size: 14px;
  margin-left: 10px;
  font-size: 24px;
  option {
    color: black;
    background: white;
    padding: 16px 24px;

    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`

export const ServicesListCont = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  & .list-item:hover {
    transform: scale(1.04);
  }
  & .list-item {
    margin-right: auto;
    font-weight: bold;
    font-size: 18px;
  }
  & .list-item.active {
    border: 1px solid black;
    border-radius: 4px;
    background-color: white;
  }
`
