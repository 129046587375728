import React from "react";

interface Props {
  components: (({ children }: { children: React.ReactNode }) => JSX.Element)[];
  children: React.ReactNode;
}

export const AppContextWrapper = (props: Props) => {
  const { components = [], children } = props;

  return (
    <>
      {components.reduce((acc, Component) => {
        return <Component>{acc}</Component>;
      }, children)}
    </>
  );
};
