import React from "react";
import {
  Aside,
  ContainerWrapper,
  PrimaryButton,
} from "../navigation/navigation.styled";
import {
  AboutUsSideText,
  SectionWrapper,
  Subtitle,
} from "../about-us-components/aboutus.styled";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";


export const GetAQuoteComponent = () => {
  const breakpoint = useMediaQuery({ minWidth: 1090 });
  return (
    <ContainerWrapper>
      <Aside>
        <AboutUsSideText paddingLeft margin={`400px 0 0 0`}>
          Get A Quote
        </AboutUsSideText>
      </Aside>
      <SectionWrapper
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Subtitle
          style={{
            textAlign: "center",
            fontWeight: "normal",
          }}
        >
          Do you have an idea or project on your mind? Do you need help in the
          recruitment process?
        </Subtitle>
        <Link
          style={{
            marginBottom: breakpoint ? "0" : "50px",
          }}
          to="/get-a-quote"
        >
          <PrimaryButton>Let's talk</PrimaryButton>
        </Link>
      </SectionWrapper>
    </ContainerWrapper>
  );
};
