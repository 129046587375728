import React from "react";
import { Navigation } from "../components/navigation/Navigation";
import { HeroSection } from "../components/navigation/HeroSection";
import {
  Aside,
  ContainerWrapper,
  HeroPretitle,
  HeroSectionContainer,
  PrimaryButton,
  SocialList,
} from "../components/navigation/navigation.styled";
import { Link } from "react-router-dom";
import { AsideNoText } from "../components/services-components/services.styled";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { socialListObject } from "../constants/constants";
export const ErrorPage = () => {
  const breakPoint = useMediaQuery({ maxWidth: 980 });
  return (
    <>
      <Helmet>
        <title>Moxelle | Error page</title>
        <meta
          name="description"
          content="Moxelle is an IT recruitment and digital solutions company with over 7 years of experience. We have a proven track record of success in helping businesses of all sizes find the right talent and achieve their goals."
        />
      </Helmet>
      <ContainerWrapper style={{ height: "80vh" }}>
        <Aside style={{ zIndex: 1000 }}>
          <SocialList style={{ marginTop: "75vh" }}>
            {socialListObject.map((item, i) => (
              <li key={i}>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.name}
                </a>
              </li>
            ))}
          </SocialList>
        </Aside>

        <div
          style={{
            position: "absolute",
            display: "flex",
            top: 0,
            zIndex: 1,
            width: "100%",
            maxHeight: "80vh",
            inset: 0,
            backgroundImage:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://res.cloudinary.com/dfolafoax/image/upload/v1695121128/services-bg-img_leib7n.webp",
          }}
        >
          <HeroSectionContainer>
            <HeroPretitle>Error 404 page not found</HeroPretitle>
            <Link to="/">
              <PrimaryButton>Go back to homepage</PrimaryButton>
            </Link>
          </HeroSectionContainer>
        </div>
      </ContainerWrapper>
    </>
  );
};
