import {
  AboutUsSideText,
  SectionWrapper,
  Subtitle,
} from "../about-us-components/aboutus.styled";
import {
  Aside,
  ContainerWrapper,
  PrimaryButton,
} from "../navigation/navigation.styled";
import React, { useContext, useEffect, useRef, useState } from "react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { useMediaQuery } from "react-responsive";
import "./blog-swiper.css";
import {
  BlogPostInnerElement,
  ClientPartnersComponentSwiper,
  JobOfferingButton,
} from "../../style-utils/inline-styles";
import { ClientLogos } from "./../clients-and-partners/ClientLogos";
import { ToggleContext } from "../../theme/ThemeContext";
import { PostsInt } from "./BlogPosts";
import { PositionContainer } from "../contact-us-components/contact-us.styled";
import { Link } from "react-router-dom";
import { RelativeSubtitleLarge } from "../project-components/projects.styled";

interface Props {
  posts: PostsInt[];
}
export const BlogSwiper = ({ posts }: Props) => {
  const swiperRef = useRef<SwiperRef>(null);
  const isPhablet = useMediaQuery({ minWidth: 560, maxWidth: 800 });
  const isMobile = useMediaQuery({ maxWidth: 800 });
  const isMobileToTablet = useMediaQuery({ maxWidth: 980 });
  const isTablet = useMediaQuery({ maxWidth: 1200 });
  const isDesktop = useMediaQuery({ minWidth: 1440 });
  const isExtraLarge = useMediaQuery({ minWidth: 1900 });
  const sideRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const elementRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const [sideHeight, setSideHeight] = useState(600);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { toggleValue } = useContext(ToggleContext);

  useEffect(() => {
    if (sideRef.current) {
      setSideHeight(sideRef.current.clientHeight);
    }
  }, [sideHeight, isMobile, isMobileToTablet, isTablet, isDesktop]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const slidesPerView = isMobile
    ? 1
    : isMobileToTablet
    ? 1.4
    : isExtraLarge
    ? 4
    : parseFloat((windowWidth / 500).toFixed(3));

  const handleSlideChange = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.autoplay.stop();
      swiperRef.current.swiper.autoplay.start();
      swiperRef.current.swiper.autoplay.running;
      swiperRef.current.swiper.autoplay.timeLeft = 4000;
    }
  };
  return (
    <ContainerWrapper>
      <Aside ref={sideRef}>
        <AboutUsSideText
          ref={elementRef}
          margin={`calc(${sideHeight - 100}px)0 0 0`}
        >
          Blogs
        </AboutUsSideText>
      </Aside>
      <SectionWrapper style={{ paddingTop: "0" }}>
        <RelativeSubtitleLarge
          style={{ marginLeft: "0px" }}
          data-aos="zoom-in"
          resizing
          top
        >
          Read Next
          <span></span>
        </RelativeSubtitleLarge>
        <Swiper
          grabCursor={true}
          spaceBetween={20}
          modules={[Autoplay, Pagination]}
          ref={swiperRef}
          style={{
            ...ClientPartnersComponentSwiper,
            width: isPhablet
              ? "78%"
              : isMobile
              ? "100%"
              : isDesktop
              ? "74vw"
              : "80vw",
          }}
          slidesPerView={slidesPerView}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          pagination={{
            dynamicMainBullets: 1,
            dynamicBullets: true,
            clickable: true,
            renderBullet: function () {
              return `<span class="swiper-pagination-bullet" style="background-color:#00A49A;"></span>`;
            },
          }}
          onSlideChange={handleSlideChange}
        >
          {posts.map((post, i) => (
            <SwiperSlide key={post._id}>
              <PositionContainer style={{ height: "410px" }} key={i}>
                <div
                  className="inner"
                  style={{
                    ...BlogPostInnerElement,
                    marginBottom: i === posts.length - 1 ? "0px" : "50px",
                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(${post.blog_img.url})`,
                    padding: "40px 20px 40px 30px",
                  }}
                >
                  <p className="posts-title">{post.blog_title}</p>
                  <p className="posts-desc">{post.blog_desc}</p>
                  <Link to={`/blog/${post._id}`}>
                    <PrimaryButton
                      style={{
                        ...JobOfferingButton,
                        left: "50%",
                        bottom: "-22px",
                        transform: "translate(-50%)",
                      }}
                    >
                      Read More
                    </PrimaryButton>
                  </Link>
                </div>
              </PositionContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </SectionWrapper>
    </ContainerWrapper>
  );
};
