import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Aside,
  ContainerWrapper,
  PrimaryButton,
} from "../navigation/navigation.styled";
import {
  AboutUsSideText,
  SectionWrapper,
} from "../about-us-components/aboutus.styled";
import peopleInOffice from "./../../img/office1.svg";
import {
  AddressInfoVariation,
  OfficesFlexContainer,
  OfficesRightSide,
} from "./contact-us.styled";
import { LocationIcon } from "./LocationIcon";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { COLORS } from "../../constants/constants";
import { OfficesComponentImage } from "../../style-utils/inline-styles";
import { Context } from "../../context/HomePageDataContext";
export interface Props {
  sideText?: string;
  offices_title?: string;
  offices_desc?: string;
}

export const Offices = ({ offices_desc, offices_title, sideText }: Props) => {
  const sideRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const elementRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const [sideHeight, setSideHeight] = useState(600);
  const isDesktop = useMediaQuery({ maxWidth: 1280 });
  const isTablet = useMediaQuery({ maxWidth: 900 });
  const isMobile = useMediaQuery({ maxWidth: 700 });
  const isSmallMobile = useMediaQuery({ maxWidth: 450 });
  const isExtraLargeDesktop = useMediaQuery({ maxWidth: 1400 });
  const { homepage } = useContext(Context);

  useEffect(() => {
    if (sideRef.current) {
      setSideHeight(sideRef.current.clientHeight);
    }
  }, [sideHeight, isDesktop, isTablet, isExtraLargeDesktop]);
  return (
    <ContainerWrapper>
      <Aside ref={sideRef}>
        <AboutUsSideText
          ref={elementRef}
          margin={`calc(${sideHeight - 100}px)0 0 0`}
        >
          {sideText}
        </AboutUsSideText>
      </Aside>
      <SectionWrapper
        backgroundColor
        style={{ paddingBottom: isTablet ? "30px" : "70px" }}
      >
        <div style={{ maxWidth: "1357px", margin: "0 auto" }}>
          <OfficesFlexContainer>
            <figure
              style={
                isSmallMobile
                  ? {
                      ...OfficesComponentImage,
                      width: "285px",
                      marginBottom: "24px",
                    }
                  : isMobile
                  ? {
                      ...OfficesComponentImage,
                      width: "360px",
                      marginBottom: "24px",
                    }
                  : OfficesComponentImage
              }
            >
              <img
                src={peopleInOffice}
                alt="colleagues-in-the-office"
                style={{ flex: "0 1 40%" }}
              />
              <Link
                to="/contact"
                style={{
                  position: "absolute",
                  right: "-5px",
                  bottom: "-24px",
                }}
              >
                <PrimaryButton
                  style={{
                    border: `4px solid ${COLORS.space_cadet800}`,
                    padding: "16px 40px",
                    borderRadius: "40px",
                  }}
                >
                  Contact Us
                </PrimaryButton>
              </Link>
            </figure>
            <OfficesRightSide>
              <h2>{offices_title}</h2>
              <h3>{offices_desc}</h3>
              <AddressInfoVariation>
                <div>
                  <LocationIcon color={COLORS.light} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homepage ? homepage.address_mk : "",
                    }}
                  />
                </div>
                <div>
                  <LocationIcon color={COLORS.light} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homepage ? homepage.address_us : "",
                    }}
                  />
                </div>
              </AddressInfoVariation>
            </OfficesRightSide>
          </OfficesFlexContainer>
        </div>
      </SectionWrapper>
    </ContainerWrapper>
  );
};
