import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider as HomePageProvider } from "./context/HomePageDataContext";
import reportWebVitals from "./reportWebVitals";
import { Provider as AboutPageProvider } from "./context/AboutPageDataContext";
import { Provider as BlogsProvider } from "./context/BlogContext";
import { Provider as JobsProvider } from "./context/JobsContext";
import { Provider as TesimonialsProvider } from "./context/TestimonialsContext";
import { Provider as ServicePageProvider } from "./context/ServicesContext";
import { Provider as CareerPageProvider } from "./context/CareerPageDataContext";
import { Provider as ProjectsPageProvider } from "./context/ProjectPageDataContext";
import { Provider as GalleryProvider } from "./context/GalleryContext";
import {Provider as ProjectsProvider} from "./context/ProjectsContext";
import {Provider as PrivacyPolicyProvider} from "./context/PrivacyPolicyDataContext" 
import { AppContextWrapper } from "./context/AppContextWrapper";
import ThemeProviderComponent from "./theme/ThemeProviderComponent";
import { ThemeChangeProvider } from "./theme/ThemeContext";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const providers = [
  GalleryProvider,
  CareerPageProvider,
  ProjectsPageProvider,
  ServicePageProvider,
  AboutPageProvider,
  HomePageProvider,
  TesimonialsProvider,
  JobsProvider,
  BlogsProvider,
  ThemeProviderComponent,
  ThemeChangeProvider,
  ProjectsProvider,
  PrivacyPolicyProvider
];
root.render(
    <AppContextWrapper components={providers}>
      <App />
    </AppContextWrapper>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
