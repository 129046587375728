import styled from 'styled-components'
import { media } from '../../style-utils/media-queries'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 24px;
  gap: 40px;
  padding-left: 170px;
  padding-right: 170px;
  ${media.laptop`
    display: block;
    padding-left: 0px;
    padding-right: 0px;
    height: 100%;
  `}
`

export const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 7px;
  padding: 24px;
  border-radius: 24px;
  background: var(--Secondary-Space-Cadet-50, #e9edf4);
`

export const Heading = styled.h1`
  ${media.laptop`
    padding-left: 0px;
    font-size: 32px;
  `}
  font-family: Montserrat;
  font-size: 39px;
  font-style: normal;
  font-weight: 700;
  line-height: 47px; /* 120.513% */
  letter-spacing: -1.56px;
  padding-left: 170px;
`

export const Input = styled.input`
  height: 28px;
  padding: 8px;
  border: 0;
  border-radius: 8px;
  background: #fff;
  color: var(--Shade-Black-100, #1a2326);
  font-family: Montserrat;
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px; /* 121.739% */
  letter-spacing: -0.46px;
`

export const TextArea = styled.textarea`
  resize: none;
  padding: 8px;
  border: 0;
  border-radius: 8px;
  background: #fff;
  color: var(--Shade-Black-100, #1a2326);
  font-family: Montserrat;
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px; /* 121.739% */
  letter-spacing: -0.46px;
`

export const ButtonWrapper = styled.div`
  position: relative;
`

export const Button = styled.button`
  position: absolute;
  right: -20px;
  bottom: -20px;
  display: flex;
  width: 137px;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 4px solid var(--Secondary-Space-Cadet-50, #e9edf4);
  background: var(--Primary-Persian-Green-500, #00a49a);
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  cursor: pointer;
  :hover {
    // background: #fff;
    // color: var(--Primary-Persian-Green-500, #00a49a);
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 40px;
  padding-top: 24px;
  ${media.laptop`
    flex: 0;
    width: 100%;
  `}
`

export const AddressBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const Address = styled.p`
  color: var(--Shade-Black-100, #1a2326);
  font-family: Montserrat;
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: -0.46px;
  padding-bottom: 8px;
  margin: 0;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
  ${media.tablet`
  padding-bottom: 0;
  margin-bottom: 40px;
  `}
`
export const AddressWrapper = styled.div`
  padding-left: 24px;
`
export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
`
export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`
