import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { CSSProperties } from "styled-components";
import { ToggleContext } from "../../../theme/ThemeContext";
import { COLORS, COLORS_SECONDARY } from "../../../constants/constants";
interface ArrowUpDownProps {
  showAll: boolean;
  setShowAll: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ArrowUpDown = ({ showAll, setShowAll }: ArrowUpDownProps) => {
  const {toggleValue} = useContext(ToggleContext);
  const breakpoint = useMediaQuery({ maxWidth: 650 });
  const arrowRotation: CSSProperties = {
    transition: "0.2s ease-in",
    cursor: "pointer",
    transform: "rotate(180deg)",
    display: "block",
    margin: "0 auto",
  };

  return (
    <div style={{ marginBottom: breakpoint ? "10px" : "50px" }}>
      <svg
        onClick={() => setShowAll((prevState) => !prevState)}
        style={
          !showAll
            ? arrowRotation
            : { ...arrowRotation, transform: "rotate(0deg)" }
        }
        width="38"
        height="24"
        viewBox="0 0 38 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 0.953127L37.75 19.7031L34.3906 23.0625L19 7.59375L3.60937 22.9844L0.25 19.625L19 0.953127Z"
          fill={toggleValue ? COLORS.dark : COLORS_SECONDARY.dark }
        />
      </svg>
    </div>
  );
};
