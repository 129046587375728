import React, { useContext, useState } from "react";
import {
  Context,
  ServiceDatainterface,
} from "../../../context/ServicesContext";
import { Button, Form, Input, ServicesListCont } from "../form.styled";
import axios from "axios";
import { apiRoute} from "../../../constants/constants";

interface EditServicesPageProps {
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}
export const EditServicesPage = ({ setCurrentTab }: EditServicesPageProps) => {
  const { servicesPage, setServicesPage } = useContext(Context);
  const [currentServiceIndex, setCurrentServiceIndex] = useState<
    number | undefined
  >();
  const [currentService, setCurrentService] = useState<
    ServiceDatainterface | undefined
  >();
  const [formValues, setFormValues] = useState<ServiceDatainterface>({
    _id: currentService ? currentService._id : "",
    title: currentService ? currentService.title : "",
    question: currentService ? currentService.question : "",
    answer: currentService ? currentService.answer : "",
    expertise: currentService ? currentService.expertise : "",
    index: currentService ? currentService.index : "",
  });

  const setService = (i: number) => {
    setCurrentServiceIndex(i);
    const findCurrentService = servicesPage.find(
      (service) => parseInt(service.index) === i
    );
    if (findCurrentService) {
      setCurrentService(findCurrentService);
      setFormValues({
        _id: findCurrentService._id,
        title: findCurrentService.title,
        question: findCurrentService.question,
        answer: findCurrentService.answer,
        expertise: findCurrentService.expertise,
        index: findCurrentService.index,
      });
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    data: ServiceDatainterface
  ) => {
    event.preventDefault();
    if (currentService) {
      setIsLoading(true);
      await axios
        .put(`${apiRoute}api/servicespage/${currentService._id}`, data)
        .then((res) => {
          // console.log(res);
          setServicesPage(res.data);
          setCurrentTab("");
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };
  const servicesArray = [
    "Recruitment",
    "Mobile_Development",
    "Web_Development",
    "Custom_Solutions",
    "Consulting",
    "Design",
    "Management",
    "Quality_Assurance",
  ];
 // console.log(formValues)
  return (
    <div style={{ display: "flex" }}>
      <ServicesListCont>
        {servicesArray.map((service, i) => (
          <li
            className={
              currentServiceIndex === i ? "active list-item" : "list-item"
            }
            onClick={() => setService(i)}
            key={i}
          >
            {service.replaceAll("_", " ")}
          </li>
        ))}
      </ServicesListCont>
      <div style={{ width: "75%" }}>
        {currentService ? (
          <Form
            style={{ maxWidth: "90%" }}
            onSubmit={(e) => onSubmit(e, formValues)}
          >
            {/* register your input into the hook by invoking the "register" function */}
            <div>
              <h3>Services Section</h3>
              <label htmlFor="title">Service Title:</label>
              <Input
                disabled={formValues.title === ""}
                placeholder={
                  formValues.title === ""
                    ? "The input is disabled for this service"
                    : undefined
                }
                id="title"
                value={formValues.title}
                onChange={(e) =>
                  setFormValues({ ...formValues, title: e.target.value })
                }
              />
              {/* <label htmlFor="subtitle">Service Subtitle:</label>
              <Input
                id="subtitle"
                value={formValues.subtitle}
                onChange={(e) =>
                  setFormValues({ ...formValues, subtitle: e.target.value })
                }
              /> */}
              <label htmlFor="question">Service First Paragraph:</label>
              <Input
                id="question"
                value={formValues.question}
                onChange={(e) =>
                  setFormValues({ ...formValues, question: e.target.value })
                }
              />
              <label htmlFor="answer">Service Second Paragraph:</label>
              <Input
                id="answer"
                value={formValues.answer}
                onChange={(e) =>
                  setFormValues({ ...formValues, answer: e.target.value })
                }
              />
              <label htmlFor="expertise">
                Service Expertise: (only for services including expertise)
              </label>
              <Input
                id="expertise"
                value={formValues.expertise}
                onChange={(e) =>
                  setFormValues({ ...formValues, expertise: e.target.value })
                }
                disabled={formValues.expertise === ""}
                placeholder={
                  formValues.expertise === ""
                    ? "The input is disabled for this service"
                    : undefined
                }
              />
            </div>
            <Button disabled={isLoading} style={{ width: "150px", margin: "0 auto" }} type="submit">
              Submit
            </Button>
          </Form>
        ) : (
          <h2 style={{ textAlign: "center" }}>
            Choose a service to update the content
          </h2>
        )}
      </div>
    </div>
  );
};
