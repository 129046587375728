import React, { CSSProperties } from "react";
interface Props {
  handleClick: () => void;
  open: boolean;
  color: string;
}
const MenuButton = ({ handleClick, open, color }: Props) => {
  const styles: Record<string, CSSProperties> = {
    container: {
      WebkitTapHighlightColor: "transparent",
      height: "32px",
      width: "32px",
      display: "flex",
      marginLeft: "auto",
      flexShrink: 1,
      flexGrow: 0,
      flexDirection: "column",
      justifyContent: "center",
      justifySelf: "flex-end",
      alignSelf: "flex-start",
      alignItems: "center",
      cursor: "pointer",
      padding: "4px",
    },
    line: {
      height: "2px",
      width: "20px",
      background: color,
      transition: "0.2s ease",
    },
    lineTop: {
      transform: open ? "rotate(45deg)" : "none",
      transformOrigin: "top left",
      marginBottom: "5px",
    },
    lineMiddle: {
      opacity: open ? 0 : 1,
      transform: open ? "translateX(-16px)" : "none",
    },
    lineBottom: {
      transform: open ? "translateX(-1px) rotate(-45deg)" : "none",
      transformOrigin: "top left",
      marginTop: "5px",
    },
  };

  return (
    <div style={styles.container} onClick={handleClick}>
      <div style={{ ...styles.line, ...styles.lineTop }} />
      <div style={{ ...styles.line, ...styles.lineMiddle }} />
      <div style={{ ...styles.line, ...styles.lineBottom }} />
    </div>
  );
};

export default MenuButton;
