import React, { useState } from 'react'
import styled from 'styled-components'
import { LocationIcon } from './LocationIcon'
import { media } from '../../style-utils/media-queries'
import axios from 'axios'
import { apiRoute } from '../../constants/constants'
import toast from 'react-hot-toast'
import {
  Address,
  AddressBlock,
  AddressWrapper,
  Button,
  ButtonWrapper,
  Container,
  ErrorMessage,
  FormWrapper,
  Heading,
  IconContainer,
  InfoWrapper,
  Input,
  TextArea,
  Wrapper
} from './ContactModal.styled'

type ContactDataKey = 'name' | 'phoneNumber' | 'email' | 'message'

export const ContactModal = () => {
  const initialContactData: Record<ContactDataKey, string> = {
    name: '',
    phoneNumber: '',
    email: '',
    message: ''
  }

  const regexPatterns: Record<ContactDataKey, string> = {
    name: "^[a-zA-Z',.\\- ]+$",
    phoneNumber: '^\\+?\\d[\\d .()-]*$', // Moved hyphen to the end
    email: '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', // Unchanged, but ensure it's a string
    message: ''
  }

  const [contactData, setContactData] = useState(initialContactData)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as { name: ContactDataKey; value: string }

    setContactData({ ...contactData, [name]: value })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    let errors = { ...initialErrorState }
    let hasErrors = false

    // Validate phone number and email
    if (!new RegExp(regexPatterns.phoneNumber).test(contactData.phoneNumber)) {
      errors.phoneNumber = 'Invalid Phone Number'
      hasErrors = true
    }

    if (!new RegExp(regexPatterns.email).test(contactData.email)) {
      errors.email = 'Invalid Email Address'
      hasErrors = true
    }

    setValidationErrors(errors)
    if (!hasErrors) {
      const emailData = {
        sender: contactData.email,
        phone: contactData.phoneNumber,
        name: contactData.name,
        message: contactData.message,
        recipientEmail: 'career@moxelle.com'
      }

      await axios
        .post(`${apiRoute}api/form-submission`, emailData)
        .then(res => {
          // Handle success
          toast.success('Your message was sent successfully')
          setContactData(initialContactData)
        })
        .catch(err => {
          // Handle error
          console.log(err)
        })
    }
  }

  const initialErrorState: Record<ContactDataKey, string> = {
    name: '',
    phoneNumber: '',
    email: '',
    message: ''
  }

  const [validationErrors, setValidationErrors] = useState(initialErrorState)

  return (
    <Wrapper>
      <Heading>Let's get to Work!</Heading>
      <Container>
        <FormWrapper>
          <Input
            type='text'
            name='name'
            placeholder='Your Name *'
            required
            value={contactData.name}
            onChange={handleChange}
            pattern={regexPatterns.name}
          />
          {validationErrors.name && (
            <ErrorMessage>{validationErrors.name}</ErrorMessage>
          )}
          <Input
            type='tel'
            name='phoneNumber'
            placeholder='Phone Number *'
            required
            value={contactData.phoneNumber}
            onChange={handleChange}
            pattern={regexPatterns.phoneNumber}
          />
          {validationErrors.phoneNumber && (
            <ErrorMessage>{validationErrors.phoneNumber}</ErrorMessage>
          )}

          <Input
            type='email'
            name='email'
            placeholder='E-mail *'
            required
            value={contactData.email}
            onChange={handleChange}
            pattern={regexPatterns.email}
          />
          {validationErrors.email && (
            <ErrorMessage>{validationErrors.email}</ErrorMessage>
          )}

          <TextArea
            name='message'
            placeholder='Message'
            rows={4}
            value={contactData.message}
            onChange={handleChange}
          />
          <ButtonWrapper>
            <Button onClick={(e: any) => handleSubmit(e)}>Send</Button>
          </ButtonWrapper>
        </FormWrapper>
        <InfoWrapper>
          <AddressBlock>
            <IconContainer>
              <LocationIcon />
            </IconContainer>
            <AddressWrapper>
              <Address>
                Majakovski 44/1-5 1000 Skopje, Republic of Macedonia
              </Address>
              <Address>+1 302 3106570</Address>
              <Address>Mon - Fri: 09:00 am - 06:00 pm</Address>
            </AddressWrapper>
          </AddressBlock>
          <AddressBlock>
            <IconContainer>
              <LocationIcon />
            </IconContainer>
            <AddressWrapper>
              <Address>
                100 Southeast Third Avenue, Suite 1000 Fort Lauderdale, FL 33394
              </Address>
              <Address>+1 954 310 4844</Address>
              <Address>Mon - Fri: 09:00 am - 06:00 pm</Address>
            </AddressWrapper>
          </AddressBlock>
        </InfoWrapper>
      </Container>
    </Wrapper>
  )
}
