import styled from "styled-components";
import React from "react";
import { media } from "../../style-utils/media-queries";
export const StyledFooter = styled.div`
  max-width:1357px;
  margin:0 auto;
  color: ${(props) => props.theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  @media(min-width:1610px){
      padding: 0 0 0 100px;
  }
  ${media.smallDesktop`
  padding: 50px 0px;
  flex-direction:column;
  `}
`;

export const StyledFooterWrapper = styled.footer`
background-color: ${(props) => props.theme.colors.footer_bg};
`
export const ListContainer = styled.div`
  order: 2;
  & ul:before {
    white-space: nowrap;
    content: attr(data-header);
    color: ${(props) => props.theme.colors.primary["500"]};
    font-weight: ${(props) => props.theme.font_weights.extraBold};
    font-size: ${(props) => props.theme.sizes.large};
  }
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
  ${media.smallDesktop`
  order:1;
  `}
  ${media.tabletToLaptop`
  flex-wrap:wrap;
  `}
  ${media.smallTablet`
  flex-wrap:no-wrap;
  flex-direction:column;
  `}
  & ul:first-child {
    flex-basis: 25%;
    position: relative;
    inset: 0;
    list-style-type: none;
    padding-left: 0;
    ${media.smallDesktop`
    flex-basis: 50%;
    `}
    ${media.tabletToLaptop`
    flex-basis:50%;
    margin-right:0;
   `}

  & li {
      width: max-content;
      & a {
        position: relative;
        top: 8px;
        z-index: 10;
        display: block;
        font-weight: ${(props) => props.theme.font_weights.semiBold};
        text-decoration: none;
      }
      position: relative;
      font-size: ${(props) => props.theme.sizes.mediumSmall};
      z-index: 10;
      margin-right: 35px;
      margin-bottom: 10px;
      &::after {
        z-index: 0;
        content: "";
        width: 0%;
        top: 15px;
        left: 0;
        position: absolute;
        height: 10px;
        background-color: ${(props) => props.theme.colors.primary["500"]};
        border-radius: 10px;
      }
    }

    & li:hover::after {
      position: absolute;
      width: 100%;
      z-index: 0;
      transition: 0.2s ease-in;
      transition-delay: 0.2s;
    }
  }
  & ul:not(:first-child) {
    flex-basis: 25%;
    position: relative;
    inset: 0;
    list-style-type: none;
    padding-left: 0;
    ${media.smallDesktop`
    flex-basis: 50%;
    `}
    ${media.tabletToLaptop`
     flex-basis:50%;
     margin-right:0;
    `}
    & li {
      width: max-content;
      & a {
        position: relative;
        top: 8px;
        z-index: 10;
        display: block;
        font-weight: ${(props) => props.theme.font_weights.semiBold};
        text-decoration: none;
      }
      position: relative;
      font-size: ${(props) => props.theme.sizes.mediumSmall};
      z-index: 10;
      margin-right: 35px;
      margin-bottom: 10px;
      &::after {
        z-index: 0;
        content: "";
        width: 0%;
        top: 15px;
        left: 0;
        position: absolute;
        height: 10px;
        background-color: ${(props) => props.theme.colors.primary["500"]};
        border-radius: 10px;
      }
    }

    & li:hover::after {
      position: absolute;
      width: 100%;
      z-index: 0;
      transition: 0.2s ease-in;
      transition-delay: 0.2s;
    }
  }
  & ul:not(:last-child) {
    ${media.smallDesktop`
     margin-right: 95px;
    `}
    ${media.largeLaptop`
   margin-right: 55px;
  `}
  ${media.tabletToLaptop`
  flex-basis:50%;
  margin-right:0;
 `}
  }
  ${media.smallDesktop`
  margin-left: 50px;
 `}
  ${media.largeLaptop`
 margin-left: 40px;
`}
${media.tabletToLaptop`
flex-direction:column;
margin-left:70px;
& ul{
  flex-basis:50%;
  margin-right:0;
}
`}
${media.smallTablet`
margin-left:62px;
& ul{
  flex-basis:100%;
  margin-right:0;
 
}
`}
 & ul:not(:first-child) li:nth-child(2) {
    margin-top: 16px;
  }
  & ul:first-child li:nth-child(2) {
    margin-top: 24px;
  }
`;

export const FollowUsContainer = styled.div`
  order: 3;
  display: flex;
  margin-top: 24px;
  align-self: flex-start;
  justify-content: flex-end;
  flex-grow: 0;
  flex-shrink: 1;

  @media(max-width:1550px){
    margin-right:40px;
  }

  ${media.smallDesktop`

  margin-right: auto;
  margin-left: auto;
  justify-content:center;
  align-self:center;
 `}
  ${media.laptop`
  margin-right: 0;
    margin-left: 22px;
  `}
  ${media.tabletToLaptop`
    margin-left: 36px;
  `}
    ${media.tablet`
    margin-left: 34px;
  `}

  & .flexible-div {
    display: flex;
    flex-direction: column;
    ${media.desktop`
    justify-content:center;
   `}
  }
  & .flexible-div span {
    font-weight: ${(props) => props.theme.font_weights.extraBold};
    font-size: ${(props) => props.theme.sizes.mediumLarge};
    color: ${(props) => props.theme.colors.secondary};
    line-height: 1.2;
    letter-spacing: ${(props) => props.theme.negative_values.minusOne};
    margin-bottom: 24px;
    ${media.desktop`
    width:100%;
   // text-align:center;
    `}
  }
`;

export const TransitionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const MoxelleCopyRightsText = styled.p`
  margin-top: 24px;
  font-size: ${(props) => props.theme.sizes.small};
  color: ${(props) => props.theme.colors.primary["500"]};
`;

export const FooterLogoContainer = styled.div`
  order: 1;
  width: 62px;
  height: 62px;
  ${media.smallDesktop`
  display:none;
  margin:24px 0;
  order:2;
  `}
`;
