import { CSSProperties } from "react";

export const ClientLogoContainer:CSSProperties = {
  display: "flex",
  padding:"0 15px",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 20px",
}
export const ClientLogoMainWrapper:CSSProperties = {
  margin: "0 auto 24px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexWrap: "wrap",
}

export const footerScratchStyle: CSSProperties = {
  transform: "rotate(-90deg)",
  position: "absolute",
  width: "130px",
  height: "1px",
  top: "30px",
  left: "-120px",
  objectFit: "cover",
  objectPosition: "left",
};

export const TeamComponentSwiperSlide: CSSProperties = {
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  width: "300px",
};
export const TeamComponentSwiperImageContainer : CSSProperties = {
  width: "100%",
  maxHeight:"550px"
}

export const TeamComponentSwiperImage: CSSProperties = {
  objectFit: "cover",
  display:'block',
  maxHeight: "550px",
};

export const ClientPartnersComponentSwiper: CSSProperties = {
  width: "80vw",
  height: "420px",
};

export const JobOfferingWrapper: CSSProperties = {
  display: "flex",
  flexWrap: "wrap",
  marginTop: "50px",
  justifyContent: "center",
};

export const JobOfferingButton: CSSProperties = {
  position: "absolute",
  right: "0",
  bottom: "0",
  display: "inherit",
};

export const OfficesComponentImage: CSSProperties = {
  width: "600px",
  position: "relative",
  height: "max-content",
};
export const BlogPostInnerElement: CSSProperties = {
  height: "265px",
  backgroundColor: "lightgray",
  backgroundPosition: "50%",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};
