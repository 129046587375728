import React from "react";
import { createContext, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";

import { apiRoute } from "../constants/constants";
import { Benefit } from "../components/contact-us-components/Benefits";


export interface CareerpageDatainterface {
  _id: string;
  banner_title: string;
  banner_paragraphOne: string;
  banner_paragraphTwo: string;
  banner_paragraphThree: string;
  benefits_array: Benefit[];
}
export interface ContextData {
  careerpage: CareerpageDatainterface | undefined;
  setCareerpage: (careerpage: CareerpageDatainterface) => void;
  isLoading: boolean;
  error: string;
}

export interface Props {
  children: React.ReactNode;
}

export const Context = createContext({} as ContextData);

export const Provider = ({ children }: Props) => {
  
  const [careerpage, setCareerpage] = useState<
    CareerpageDatainterface | undefined
  >();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | AxiosError | any>("");

  useEffect(() => {
    if(!careerpage){
    setLoading(true);
    axios
      .get(`${apiRoute}api/careerpage`)
      .then((res) => setCareerpage(res.data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }}, []);

  return (
    <Context.Provider
      value={{
        careerpage,
        setCareerpage,
        isLoading,
        error,
      }}
    >
      {children}
    </Context.Provider>
  );
};