import React from "react";
export interface IconProps {
  color?: string;
}
export const Consulting = ({ color }: IconProps) => {
  return (
    <svg
      style={{ display: "block" }}
      width="100%"
      height="58"
      viewBox="0 0 57 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1624 30.6375L0.399902 20.875L10.1624 11.1813L19.8562 20.875L10.1624 30.6375ZM21.6437 58V43.378C18.7103 43.1343 15.7999 42.8177 12.9124 42.4281C10.0249 42.0385 7.1374 41.5 4.2499 40.8125L5.28115 36.6875C9.18927 37.6042 13.109 38.2573 17.0404 38.6469C20.9718 39.0365 24.9614 39.2313 29.0091 39.2313C33.0568 39.2313 37.0464 39.0365 40.9778 38.6469C44.9092 38.2573 48.8228 37.6042 52.7187 36.6875L53.7499 40.8125C50.8624 41.5 47.9753 42.0433 45.0886 42.4424C42.2018 42.8416 39.291 43.1462 36.3562 43.3563V58H21.6437ZM10.1624 24.9312L14.1499 20.875L10.1624 16.8875L6.10615 20.875L10.1624 24.9312ZM28.9918 15.375C26.8889 15.375 25.1041 14.639 23.6374 13.1669C22.1707 11.6949 21.4374 9.90736 21.4374 7.80439C21.4374 5.70146 22.1734 3.91667 23.6455 2.45C25.1175 0.983333 26.905 0.25 29.008 0.25C31.1109 0.25 32.8957 0.986037 34.3624 2.45811C35.8291 3.93014 36.5624 5.71764 36.5624 7.82061C36.5624 9.92354 35.8264 11.7083 34.3543 13.175C32.8823 14.6417 31.0948 15.375 28.9918 15.375ZM29.0054 35.0375C27.6267 35.0375 26.4447 34.5466 25.4593 33.5649C24.4739 32.5831 23.9812 31.4029 23.9812 30.0243C23.9812 28.6456 24.472 27.4635 25.4538 26.4781C26.4356 25.4927 27.6158 25 28.9944 25C30.3731 25 31.5551 25.4909 32.5405 26.4726C33.5259 27.4544 34.0187 28.6346 34.0187 30.0133C34.0187 31.3919 33.5278 32.574 32.546 33.5594C31.5642 34.5448 30.384 35.0375 29.0054 35.0375ZM28.9879 11.25C29.9584 11.25 30.7759 10.9217 31.4405 10.2652C32.1051 9.60864 32.4374 8.7951 32.4374 7.82453C32.4374 6.85401 32.1091 6.03646 31.4526 5.37188C30.796 4.70729 29.9825 4.375 29.0119 4.375C28.0414 4.375 27.2239 4.70328 26.5593 5.35984C25.8947 6.01636 25.5624 6.8299 25.5624 7.80047C25.5624 8.77099 25.8907 9.58854 26.5472 10.2531C27.2038 10.9177 28.0173 11.25 28.9879 11.25ZM43.9874 29.8125L39.7249 22.25L44.0103 14.6875H52.5812L56.8437 22.25L52.5583 29.8125H43.9874ZM46.3708 25.6875H50.1749L52.0999 22.25L50.1978 18.8125H46.3937L44.4687 22.25L46.3708 25.6875Z"
        fill={color}
      />
    </svg>
  );
};
