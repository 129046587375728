import React, { useContext, useState } from "react";
import {
  Context,
  HomepageDatainterface,
} from "../../../context/HomePageDataContext";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Form, Input } from "../form.styled";
import homepageSvg from "./screens/homepageSvg.svg";
import axios from "axios";
import { LoadingComponent } from "../../loading-component/LoadingComponent";

import { apiRoute } from "../../../constants/constants";

interface EditHomePageProps {
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}

export const EditHomePage = ({ setCurrentTab }: EditHomePageProps) => {
  
  const { homepage, setHomepage } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<HomepageDatainterface>({
    defaultValues: {
      banner_pretitle: homepage ? homepage.banner_pretitle : "",
      banner_subtitle: homepage ? homepage.banner_subtitle : "",
      about_us_title: homepage ? homepage.about_us_title : "",
      about_us_subtitle: homepage ? homepage.about_us_subtitle : "",
      about_us_text_one: homepage ? homepage.about_us_text_one : "",
      about_us_text_two: homepage ? homepage.about_us_text_two : "",
      about_us_text_three: homepage ? homepage.about_us_text_three : "",
      about_us_text_four: homepage ? homepage.about_us_text_four : "",
      services_title: homepage ? homepage.services_title : "",
      testimonials_title: homepage ? homepage.testimonials_title : "",
      testimonials_description: homepage
        ? homepage.testimonials_description
        : "",
      projects_title: homepage ? homepage.projects_title : "",
      projects_description: homepage ? homepage.projects_description : "",
      address_mk: homepage ? homepage.address_mk : "",
      address_us: homepage ? homepage.address_us : "",
    },
  });

  const onSubmit: SubmitHandler<HomepageDatainterface> = async (data) => {
    if (homepage) {
      setIsLoading(true);
      await axios
        .put(`${apiRoute}api/homepage/${homepage._id}`, data)
        .then((res) => {
          setHomepage(res.data);
          reset();
          setCurrentTab("");
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <div style={{ display: "flex" }}>
          {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
          <Form style={{ maxWidth: "90%" }} onSubmit={handleSubmit(onSubmit)}>
            {/* register your input into the hook by invoking the "register" function */}
            <div>
              <h3>Banner Section</h3>
              <label htmlFor="banner_pretitle">Banner Pretitle:</label>
              <Input id="banner_pretitle" {...register("banner_pretitle")} />

              <label htmlFor="banner_subtitle">Banner Subtitle:</label>
              <Input id="banner_subtitle" {...register("banner_subtitle")} />
            </div>
            <div>
              <h3>About Us Section</h3>
              <label htmlFor="about_us_title">About Us Title:</label>
              <Input id="about_us_title" {...register("about_us_title")} />

              <label htmlFor="about_us_subtitle">About Us Subtitle:</label>
              <Input
                id="about_us_subtitle"
                {...register("about_us_subtitle")}
              />

              <label htmlFor="about_us_text_one">About Us Paragraph 1:</label>
              <Input
                id="about_us_text_one"
                {...register("about_us_text_one")}
              />

              <label htmlFor="about_us_text_two">About Us Paragraph 2:</label>
              <Input
                id="about_us_text_two"
                {...register("about_us_text_two")}
              />

              <label htmlFor="about_us_text_three">About Us Paragraph 3:</label>
              <Input
                id="about_us_text_three"
                {...register("about_us_text_three")}
              />
                <label htmlFor="about_us_text_four">About Us Paragraph 4:</label>
              <Input
                id="about_us_text_four"
                {...register("about_us_text_four")}
              />
            </div>
            <div>
              <h3>Services Section</h3>
              <label htmlFor="services_title">Services Title:</label>
              <Input id="services_title" {...register("services_title")} />
            </div>
            <div>
              <h3>Testimonials Section</h3>
              <label htmlFor="testimonials_title">Testimonials Title:</label>
              <Input
                id="testimonials_title"
                {...register("testimonials_title")}
              />
              <label htmlFor="testimonials_description">
                Testimonials Description:
              </label>
              <Input
                id="testimonials_description"
                {...register("testimonials_description")}
              />
            </div>
            <div>
              <h3>Projects Section</h3>
              <label htmlFor="projects_title">Projects Title:</label>
              <Input id="projects_title" {...register("projects_title")} />
              <label htmlFor="projects_description">
                Projects Description:
              </label>
              <Input
                id="projects_description"
                {...register("projects_description")}
              />
            </div>
            <div>
              <h3>Contact Us Section</h3>
              <label htmlFor="address_mk">
                {
                  "Address MK: ( Avoid deleting the <p></p> tags.Only change the content inside the tags to mantain the design!)"
                }
              </label>
              <Input id="address_mk" {...register("address_mk")} />
              <label htmlFor="address_us">
                {
                  "Address US: ( Avoid deleting the <p></p> tags.Only change the content inside the tags to mantain the design!)"
                }
              </label>
              <Input id="address_us" {...register("address_us")} />
            </div>
            <Button type="submit" >Submit Changes</Button>
          </Form>
          <div style={{ width: "350px", marginRight: "20px" }}>
            <img src={homepageSvg} alt="homepage-screenshot" />
          </div>
        </div>
      )}
    </>
  );
};
