import { Link, useLocation } from 'react-router-dom'
import { LogoWrapper } from './LogoWrapper'
import React, { useContext, useEffect, useRef, useState } from 'react'
import './navigation.css'
import {
  ContactInfoContainer,
  MenuList,
  Nav,
  Toggler
} from './navigation.styled'
import HamburgerIcon from './HamburgerIcon'
import { useMediaQuery } from 'react-responsive'
import { COLORS, COLORS_SECONDARY } from '../../constants/constants'
import bgImage from './../../img/particles.webp'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { ToggleContext } from '../../theme/ThemeContext'

export const Navbar = () => {
  const { toggleValue, toggle } = useContext(ToggleContext)
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)
  const largeLaptop = useMediaQuery({ maxWidth: 980 })
  useEffect(() => {
    AOS.init({
      duration: 350,
      once: true,
      easing: 'ease-out'
    })

    AOS.refresh()
    return () => {
      AOS.refresh()
    }
  }, [])
  useEffect(() => {
    setOpen(false)
  }, [largeLaptop, pathname])
  const handleClick = () => {
    setOpen(!open)
  }

  const [navbarPosition, setNavbarPosition] = useState('absolute')

  const lastScrollY = useRef(0)
  const [isNavbarVisible, setIsNavbarVisible] = useState(true)

  const handleScroll = () => {
    const currentScrollY = window.scrollY

    const nearTop = currentScrollY < 50

    const isScrollingUp = currentScrollY < lastScrollY.current

    setIsNavbarVisible(isScrollingUp || nearTop)

    lastScrollY.current = currentScrollY
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <header>
      <Nav
        isVisible={isNavbarVisible}
        style={{
          background: open
            ? `${
                toggleValue
                  ? COLORS.backgroundWithOutOpacity
                  : COLORS_SECONDARY.backgroundWithOutOpacity
              } url(${bgImage}) no-repeat center / cover scroll`
            : toggleValue
            ? COLORS.backgroundWithOpacity
            : COLORS_SECONDARY.backgroundWithOpacity,
          height: open && largeLaptop ? '100vh' : '40px',
          transition: 'opacity 0.3s ease-in-out',
          overflowY: 'hidden',
          position: 'fixed',
          opacity: isNavbarVisible ? 1 : 0
        }}
      >
        <div className='align-self-start'>
          <Link to='/'>
            <LogoWrapper />
          </Link>
        </div>
        {open || !largeLaptop ? (
          <MenuList>
            <li
              data-aos-delay='300'
              data-aos={open ? 'zoom-out' : undefined}
              className={
                pathname === '/home' || pathname === '/' ? 'active' : undefined
              }
            >
              <Link to='/'>Home</Link>
              <p
                className='empty'
                style={{
                  backgroundColor: toggleValue
                    ? COLORS.primary['500']
                    : COLORS_SECONDARY.primary['500']
                }}
              ></p>
            </li>
            <li
              data-aos-delay='300'
              data-aos={open ? 'zoom-out' : undefined}
              className={pathname === '/services' ? 'active' : undefined}
            >
              <Link to='/services'>Services</Link>
              <p
                className='empty'
                style={{
                  backgroundColor: toggleValue
                    ? COLORS.primary['500']
                    : COLORS_SECONDARY.primary['500']
                }}
              ></p>
            </li>
            <li
              data-aos-delay='500'
              data-aos={open ? 'zoom-out' : undefined}
              className={pathname === '/projects' ? 'active' : undefined}
            >
              <Link to='/projects'>Projects</Link>
              <p
                className='empty'
                style={{
                  backgroundColor: toggleValue
                    ? COLORS.primary['500']
                    : COLORS_SECONDARY.primary['500']
                }}
              ></p>
            </li>
            <li
              data-aos-delay='700'
              data-aos={open ? 'zoom-out' : undefined}
              className={pathname.includes('/career') ? 'active' : undefined}
            >
              <Link to='/career'>Careers</Link>
              <p
                className='empty'
                style={{
                  backgroundColor: toggleValue
                    ? COLORS.primary['500']
                    : COLORS_SECONDARY.primary['500']
                }}
              ></p>
            </li>
            <li
              data-aos={open ? 'zoom-out' : undefined}
              data-aos-delay='900'
              className={pathname.includes('/blog') ? 'active' : undefined}
            >
              <Link to='/blog'>Blog</Link>
              <p
                className='empty'
                style={{
                  backgroundColor: toggleValue
                    ? COLORS.primary['500']
                    : COLORS_SECONDARY.primary['500']
                }}
              ></p>
            </li>
            <li
              data-aos-delay='1100'
              data-aos={open ? 'zoom-out' : undefined}
              className={pathname === '/about' ? 'active' : undefined}
            >
              <Link to='/about'>About Us</Link>
              <p
                className='empty'
                style={{
                  backgroundColor: toggleValue
                    ? COLORS.primary['500']
                    : COLORS_SECONDARY.primary['500']
                }}
              ></p>
            </li>
            <li
              data-aos-delay='1300'
              data-aos={open ? 'zoom-out' : undefined}
              className={
                pathname === '/get-a-quote' ? 'active2' : 'bigger-link'
              }
            >
              <Link to='/get-a-quote'>Get A Quote</Link>
              <p
                className='empty2'
                style={{
                  backgroundColor: toggleValue
                    ? COLORS.primary['500']
                    : COLORS_SECONDARY.primary['500']
                }}
              ></p>
            </li>
          </MenuList>
        ) : null}
        {largeLaptop && (
          <HamburgerIcon
            handleClick={handleClick}
            color={COLORS.light}
            open={open}
          />
        )}
        <ContactInfoContainer>
          <a href='mailto:info@moxelle.com'>info@moxelle.com</a>
          <a href='tel:+19543104844'>{'+ 1 (954) 310-4844'}</a>
        </ContactInfoContainer>
      </Nav>
    </header>
  )
}
