import React, { useContext } from "react";
import { ToggleContext } from "../../theme/ThemeContext";

export const Brackets = () => {
  const { toggleValue } = useContext(ToggleContext);
  return (
    <svg
      width="32"
      height="25"
      viewBox="0 0 32 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.196 0.0759969L9.444 17.356L7.86 10.876C9.924 10.876 11.604 11.476 12.9 12.676C14.196 13.876 14.844 15.532 14.844 17.644C14.844 19.708 14.172 21.388 12.828 22.684C11.532 23.932 9.9 24.556 7.932 24.556C5.916 24.556 4.236 23.932 2.892 22.684C1.596 21.388 0.948 19.708 0.948 17.644C0.948 17.02 0.996 16.42 1.092 15.844C1.188 15.22 1.38 14.5 1.668 13.684C1.956 12.868 2.364 11.788 2.892 10.444L7.068 0.0759969H14.196ZM31.188 0.0759969L26.436 17.356L24.852 10.876C26.916 10.876 28.596 11.476 29.892 12.676C31.188 13.876 31.836 15.532 31.836 17.644C31.836 19.708 31.164 21.388 29.82 22.684C28.524 23.932 26.892 24.556 24.924 24.556C22.908 24.556 21.228 23.932 19.884 22.684C18.588 21.388 17.94 19.708 17.94 17.644C17.94 17.02 17.988 16.42 18.084 15.844C18.18 15.22 18.372 14.5 18.66 13.684C18.948 12.868 19.356 11.788 19.884 10.444L24.06 0.0759969H31.188Z"
        fill={toggleValue ? "#00A49A" : "white"}
      />
    </svg>
  );
};
