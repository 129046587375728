import React, { useEffect, useRef, useState } from "react";
import {
  Aside,
  ContainerWrapper,
  PrimaryButton,
} from "../navigation/navigation.styled";
import {
  AboutUsSideText,
  SectionWrapper,
} from "../about-us-components/aboutus.styled";
import { PositionContainer } from "./contact-us.styled";
import { useMediaQuery } from "react-responsive";
import {
  JobOfferingButton,
  JobOfferingWrapper,
} from "../../style-utils/inline-styles";
import { Link } from "react-router-dom";

export interface JobProps {
  _id: string;
  job_title: string;
  job_type: string;
  job_location: string;
  job_desc:string;
}
interface Props {
  sideText?: string;
  jobs: JobProps[];
}

export const JobOfferingComponent = ({ jobs, sideText }: Props) => {
  const sideRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const elementRef: React.RefObject<HTMLParagraphElement> = useRef(null);
  const [sideHeight, setSideHeight] = useState(600);
  const breakpoint = useMediaQuery({ maxWidth: 823 });
  const isDesktop = useMediaQuery({ maxWidth: 1439 });
  const isTablet = useMediaQuery({ maxWidth: 900 });
  useEffect(() => {
    if (sideRef.current) {
      setSideHeight(sideRef.current.clientHeight);
    }
  }, [sideHeight, isDesktop, isTablet]);
  return (
    <ContainerWrapper>
      <Aside ref={sideRef}>
        <AboutUsSideText
          ref={elementRef}
          margin={jobs.length < 4 ? "450px 0 0 0": `calc(${sideHeight - 100}px)0 0 0`}
        >
          {sideText}
        </AboutUsSideText>
      </Aside>
      <SectionWrapper>
        <div
          style={{
            ...JobOfferingWrapper,
            justifyContent: isTablet ? "center" : "flex-start",
            marginRight: breakpoint ? "0px" : "-50px",
          }}
        >
          {jobs.map((job, i) => (
            <PositionContainer key={i}>
              <div
                className="inner"
                style={{ marginBottom: i === jobs.length - 1 ? "0px" : "50px" }}
              >
                <div className="flexible">
                  <h2>{job.job_title}</h2>
                  <div>
                    <p>{job.job_type}</p>
                    <p>{job.job_location}</p>
                  </div>
                </div>
                <Link to={`/career/${job._id}`}>
                  <PrimaryButton style={JobOfferingButton}>
                    Read More
                  </PrimaryButton>
                </Link>
              </div>
            </PositionContainer>
          ))}
        </div>
      </SectionWrapper>
    </ContainerWrapper>
  );
};
