import React, { useContext } from "react";
import { Navigation } from "../components/navigation/Navigation";
import { HeroSection } from "../components/navigation/HeroSection";
import {
  HeroTitleAboutUs,
  ServicePageHeroContainer,
} from "../components/navigation/navigation.styled";
import { Stats } from "./../components/about-us-components/Stats";
import { clipPathVariable } from "../constants/constants";
import { Context } from "../context/AboutPageDataContext";
import { LoadingComponent } from "../components/loading-component/LoadingComponent";
import { GetAQuoteComponent } from "../components/contact-us-components/GetAQuoteComponent";
import { StatsVariant } from "../components/about-us-components/StatsVariant";
import { Helmet } from "react-helmet";

export const AboutPage = () => {
  const { aboutpage } = useContext(Context);

  return (
    <>
    <Helmet>
        <title>Moxelle | About</title>
        <meta
          name="description"
          content="Moxelle is an IT recruitment and digital solutions company with over 7 years of experience. We have a proven track record of success in helping businesses of all sizes find the right talent and achieve their goals."
        />
      </Helmet>
      <Navigation>
        <HeroSection
          clipPath={clipPathVariable}
          url={"https://res.cloudinary.com/dfolafoax/image/upload/v1695120619/about-bg-img_dgmi29.webp"}
        >
          {aboutpage ? (
            <ServicePageHeroContainer justifyContent>
              <div>
                <HeroTitleAboutUs
                  whitespace
                  dangerouslySetInnerHTML={{
                    __html: !aboutpage ? "" : aboutpage.banner_title,
                  }}
                />
              </div>
            </ServicePageHeroContainer>
          ) : (
            <LoadingComponent />
          )}
        </HeroSection>
      </Navigation>
      <Stats
        stats_paragraph_containerOne={
          aboutpage
            ? !aboutpage
              ? ""
              : aboutpage.stats_paragraph_containerOne
            : ""
        }
      />
      <StatsVariant
        stats_paragraph_containerTwo={
          aboutpage
            ? !aboutpage
              ? ""
              : aboutpage.stats_paragraph_containerTwo
            : ""
        }
      />
      <GetAQuoteComponent />
    </>
  );
};
