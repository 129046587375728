import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AboutUsSideText,
  SectionWrapper,
} from "../about-us-components/aboutus.styled";
import {
  Aside,
  ContainerWrapper,
  PrimaryButton,
} from "../navigation/navigation.styled";
import { Web } from "./../../img/service-icons/Web";
import { Ux } from "./../../img/service-icons/Ux";
import { Software } from "./../../img/service-icons/Software";
import { Quality } from "./../../img/service-icons/Quality";
import { Mobile } from "./../../img/service-icons/Mobile";
import { Digital } from "./../../img/service-icons/Digital";
import { Consulting } from "./../../img/service-icons/Consulting";
import { Cloud } from "../../img/service-icons/Cloud";

import {
  AllServicesContainer,
  RelativeSubtitleVariation,
  ServiceParagraph,
  ServiceTextContainer,
  SingleServiceContainer,
} from "./services.styled";
import { ArrowUpDown } from "./services-svg/ArrowUpDown";
import { useMediaQuery } from "react-responsive";
import { ToggleContext } from "../../theme/ThemeContext";
import { COLORS, COLORS_SECONDARY } from "../../constants/constants";

interface ServiceProps {
  title?: string;
  setCurrentService: (service: string) => void;
}
export const ServicesComponent = ({
  title,
  setCurrentService,
}: ServiceProps) => {
  const navigate = useNavigate();
  const { toggleValue } = useContext(ToggleContext);
  const ServiceObject: Record<string, string | React.JSX.Element> = {
   Recruitment: Digital({
      color: toggleValue ? COLORS.dark : COLORS_SECONDARY.dark,
    }),
    Web_Development: Web({
      color: toggleValue ? COLORS.dark : COLORS_SECONDARY.dark,
    }),
    Mobile_APP_Development: Mobile({
      color: toggleValue ? COLORS.dark : COLORS_SECONDARY.dark,
    }),
    Custom_Software_Solutions: Software({
      color: toggleValue ? COLORS.dark : COLORS_SECONDARY.dark,
    }),
    Consulting_and_Resourcing: Consulting({
      color: toggleValue ? COLORS.dark : COLORS_SECONDARY.dark,
    }),
    "UX/UI_Design_Services": Ux({
      color: toggleValue ? COLORS.dark : COLORS_SECONDARY.dark,
    }),
    Cloud_Management: Cloud({
      color: toggleValue ? COLORS.dark : COLORS_SECONDARY.dark,
    }),
    Quality_Assurance: Quality({
      color: toggleValue ? COLORS.dark : COLORS_SECONDARY.dark,
    }),
  };
  const servicesArray: string[] = [
    "Recruitment",
    "Development",
    "Development",
    "Custom Solutions",
    "Consulting",
    "Design",
    "Management",
    "QA",
   
  ];

  const [showAll, setShowAll] = useState(false);
  const [sideHeight, setSideHeight] = useState(0);
  const isDesktop = useMediaQuery({ maxWidth: 1280 });
  const isTablet = useMediaQuery({ maxWidth: 906 });
  const breakpoint = useMediaQuery({ maxWidth: 480 });
  const sideRef: React.RefObject<HTMLParagraphElement> = useRef(null);

  const twoItemsInRow = isTablet ? "1000px" : isDesktop ? "750px" : "780px";
  const singleItemInRow = isTablet ? "700px" : isDesktop ? "500px" : "500px";
  const extendedContainerSize = isTablet
    ? "1240px"
    : isDesktop
    ? "700px"
    : "700px";
  const smallContainerSize = isTablet ? "720px" : isDesktop ? "400px" : "450px";
  useEffect(() => {
    if (sideRef.current) {
      setSideHeight(sideRef.current.clientHeight);
    }
  }, [sideHeight, showAll, isDesktop, isTablet]);

  const navigateToServices = (service: string) => {
    setCurrentService(service);
    navigate("/services");
  };

  return (
    <ContainerWrapper>
      <Aside
        ref={sideRef}
        style={{
          transition: "height 0.3s ease-out",
          height: showAll ? twoItemsInRow : singleItemInRow,
        }}
      >
        <AboutUsSideText
          style={{
            transition: "margin 0.3s ease-out",
          }}
          margin={`calc(${showAll ? 700 - 100 : 500 - 100}px)0 0 0`}
        >
          Services
        </AboutUsSideText>
      </Aside>
      <SectionWrapper
        style={{
          transition: "height 0.3s ease-out",
          height: showAll ? extendedContainerSize : smallContainerSize,
        }}
      >
        <div>
          <RelativeSubtitleVariation data-aos="fade-up">
            {title}
          </RelativeSubtitleVariation>
          <AllServicesContainer>
            {Object.entries(ServiceObject).map((service, i) =>
              !showAll && i < 4 ? (
                <SingleServiceContainer
                  data-aos={i % 2 === 0 ? "fade-right" : "fade-left"}
                  key={service[0]}
                >
                  <div
                    style={{
                      width: breakpoint ? "35px" : "50px",
                      marginRight: breakpoint ? "16px" : "40px",
                    }}
                  >
                    <div>{service[1]}</div>
                  </div>
                  <span className="line"></span>
                  <ServiceTextContainer>
                    <ServiceParagraph>
                      {service[0].replaceAll("_", " ").split(" ").length === 3
                        ? service[0]
                            .replaceAll("_", " ")
                            .split(" ")
                            .slice(0, 2)
                            .join(" ")
                        : service[0]
                            .replaceAll("_", " ")
                            .split(" ")
                            .slice(0, 1)
                            .join(" ")}{" "}
                      <span className="colored">
                        {service[0].split("_")[2]
                          ? service[0].split("_")[2]
                          : service[0].split("_")[1]}
                      </span>
                    </ServiceParagraph>
                    <button onClick={() => navigateToServices(servicesArray[i])}>
                      read more
                    </button>
                  </ServiceTextContainer>
                </SingleServiceContainer>
              ) : showAll ? (
                <SingleServiceContainer
                  data-aos={i % 2 === 0 ? "fade-right" : "fade-left"}
                  key={service[0]}
                >
                  <div
                    style={{
                      width: breakpoint ? "35px" : "50px",
                      marginRight: breakpoint ? "16px" : "40px",
                    }}
                  >
                    <div>{service[1]}</div>
                    {/* <img alt={`service-logo-${i}`} src={service[1]} /> */}
                  </div>
                  <span className="line"></span>
                  <ServiceTextContainer>
                    <ServiceParagraph>
                      {service[0].replaceAll("_", " ").split(" ").length === 3
                        ? service[0]
                            .replaceAll("_", " ")
                            .split(" ")
                            .slice(0, 2)
                            .join(" ")
                        : service[0]
                            .replaceAll("_", " ")
                            .split(" ")
                            .slice(0, 1)
                            .join(" ")}{" "}
                      <span className="colored">
                        {service[0].split("_")[2]
                          ? service[0].split("_")[2]
                          : service[0].split("_")[1]}
                      </span>
                    </ServiceParagraph>
                    <button onClick={() => navigateToServices(servicesArray[i])}>
                      read more
                    </button>
                  </ServiceTextContainer>
                </SingleServiceContainer>
              ) : null
            )}
          </AllServicesContainer>
        </div>
        <ArrowUpDown showAll={showAll} setShowAll={setShowAll} />
      </SectionWrapper>
    </ContainerWrapper>
  );
};
