import React, { useContext, useState } from "react";
import axios from "axios";
import imageCompression from "browser-image-compression";
import { AddEditFormWrapper, Button, Form } from "../form.styled";
import { apiRoute, compressionOptions } from "../../../constants/constants";
import { LoadingComponent } from "../../loading-component/LoadingComponent";
import { Context, GalleryInt } from "../../../context/GalleryContext";

interface AddEditGallerysProps {
  mode: string;
  setMode: React.Dispatch<React.SetStateAction<string>>;
  editImg: GalleryInt | undefined;
  setEditImg: React.Dispatch<React.SetStateAction<GalleryInt | undefined>>;
}

export const AddImg = ({
  setEditImg,
  editImg,
  mode,
  setMode,
}: AddEditGallerysProps) => {
  const { setGallery } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [newGalleryImg, setGalleryImg] = useState<any>(
    editImg && editImg.gallery_img ? editImg.gallery_img : ""
  );
  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setGalleryImg(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      // Compress the image

      // Send the base64 string to the backend
      if (mode === "create") {
        const compressedImage = await imageCompression(
          newGalleryImg,
          compressionOptions
        );

        // Convert the compressed image to a base64 string
        const reader = new FileReader();
        reader.readAsDataURL(compressedImage);
        reader.onloadend = async () => {
          const base64String = reader.result as string;
          setIsLoading(true);
          const postData = {
            galleryImg: base64String,
          };

          await axios
            .post(`${apiRoute}api/gallery`, postData)
            .then((res) => {
              console.log(res);
              setGalleryImg(undefined);
              setMode("");
              setGallery(res.data);
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
        };
      } else if (mode === "edit") {
        if (editImg && newGalleryImg && !newGalleryImg.url) {
          const compressedImage = await imageCompression(
            newGalleryImg,
            compressionOptions
          );

          // Convert the compressed image to a base64 string
          const reader = new FileReader();
          reader.readAsDataURL(compressedImage);
          reader.onloadend = async () => {
            const base64String = reader.result as string;

            setIsLoading(true);
            const postData = {
              galleryImg: base64String,
            };

            await axios
              .put(`${apiRoute}api/gallery/${editImg._id}`, postData)
              .then((res) => {
                //  console.log(res);
                setGalleryImg(undefined);
                setMode("");
                setGallery(res.data);
              })
              .catch((err) => console.log(err))
              .finally(() => setIsLoading(false));
          };
        } else if (editImg && newGalleryImg && newGalleryImg.url) {
          // Convert the compressed image to a base64 string
          setIsLoading(true);
          const postData = {
            galleryImg: { ...newGalleryImg },
          };

          await axios
            .put(`${apiRoute}api/gallery/${editImg._id}`, postData)
            .then((res) => {
              //  console.log(res);
              setGalleryImg(undefined);
              setMode("");
              setGallery(res.data);
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading && <LoadingComponent />}
      <AddEditFormWrapper>
        <button
          style={{
            zIndex:999999999,
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          onClick={() => {
            setMode("");
            setEditImg(undefined);
          }}
        >
          <b>X</b>
        </button>
        <Form onSubmit={handleSubmit}>
          <h1>{editImg ? "Edit" : "Add"} Gallery</h1>
          <div>
            <label htmlFor="galleryImgId">Gallery Image:</label>
            {newGalleryImg && newGalleryImg.url && newGalleryImg.type ? (
              <img src={newGalleryImg.url} alt="gallery-img" />
            ) : newGalleryImg !== "" ? (
              <img
                src={URL.createObjectURL(
                  new Blob([newGalleryImg], { type: newGalleryImg.type })
                )}
                alt="gallery-img"
              />
            ) : null}
            <input
              accept="image/*"
              type="file"
              id="galleryImgId"
              onChange={handleImage}
            />
          </div>
          <Button disabled={isLoading} type="submit">
            Submit
          </Button>
        </Form>
      </AddEditFormWrapper>
    </>
  );
};
