import React, { CSSProperties } from "react";

interface HeroSectionProps {
  children?: React.ReactNode;
  url: string;
  clipPath?: string;
}
export const HeroSectionVariation = ({
  clipPath,
  children,
  url,
}: HeroSectionProps) => {
  const heroContainerWrapper: CSSProperties = {
    top: 0,
    zIndex: 1,
    display:"flex",
    width: "100%",
    minHeight: "100vh",
    clipPath: clipPath,
    backgroundImage: `url(${url})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "160%",
    backgroundAttachment: "scroll",
  };
  return <div style={heroContainerWrapper}>{children}</div>;
};
