import React from "react";
import { EditHomePage } from "./EditHomePage";
import { EditServicesPage } from "./EditServicesPage";
import { EditAboutPage } from "./EditAboutPage";
import { EditProjectsPage } from "./EditProjectsPage";
import { EditCareerPage } from "./EditCareerPage";
import { EditPrivacyPolicyPage } from "./EditPrivacyPolicyPage";
export interface EditPageContentProps {
  currentTab: string;
  setCurrentTab:React.Dispatch<React.SetStateAction<string>>;
}

export const EditPageContent = ({ currentTab,setCurrentTab }: EditPageContentProps) => {
  const editPage = (currentTab: string) => {
    switch (currentTab) {
      case "Home":
        return <EditHomePage setCurrentTab={setCurrentTab}/>;
      case "Services":
        return <EditServicesPage setCurrentTab={setCurrentTab} />;
      case "About":
        return <EditAboutPage setCurrentTab={setCurrentTab}/>;
      case "ProjectsPage":
        return <EditProjectsPage setCurrentTab={setCurrentTab}/>;
      case "Career":
        return <EditCareerPage setCurrentTab={setCurrentTab} />;
      case "PrivacyPolicy":
        return <EditPrivacyPolicyPage setCurrentTab={setCurrentTab}/>
        default:
            return null;
    }
  };

  return (
    <>
      {editPage(currentTab)}
    </>
  );
};
