import React from "react";
import bridg from "./../../img/client-logos/bridg.svg";
import ct_logo from "./../../img/client-logos/ct_logo.svg";
import neotel from "./../../img/client-logos/logo_neotel.png";
import triglav from "./../../img/client-logos/logo-triglav.png";
import qtwin from "./../../img/client-logos/qt-web.webp";
import djuma from "./../../img/client-logos/Logo_Djuma.svg";
import around25 from "./../../img/client-logos/a25-logo.svg";
import quinshift from "./../../img/client-logos/qinshift_logo.svg";
import new_view from "./../../img/client-logos/new_view.svg";
import cardlytics from "./../../img/client-logos/cardlytics-logo.svg";
import infinum from "./../../img/client-logos/infinum.svg";
import { useMediaQuery } from "react-responsive";
import {
  ClientLogoContainer,
  ClientLogoMainWrapper,
} from "../../style-utils/inline-styles";
export const ClientLogos = () => {
  const isTablet = useMediaQuery({ maxWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 560 });
  const isExtraLarge = useMediaQuery({ minWidth: 1650 });
  const clientLogoArr = [
    cardlytics,
    ct_logo,
    bridg,
    new_view,
    quinshift,
    infinum,
    around25,
    djuma,
    triglav,
    qtwin,
    neotel,
  ];

  return (
    <div
      style={{ ...ClientLogoMainWrapper, width: isExtraLarge ? "80%" : "100%" ,maxWidth:"1357px"}}
    >
      {clientLogoArr.map((logo, i) => (
        <figure
          data-aos="zoom-in"
          data-aos-delay={`${i * 200}`}
          style={{
            ...ClientLogoContainer,
            width: isMobile ? "110px" : isTablet ? "140px" : "180px",
            height: isMobile ? "90px" : isTablet ? "100px" : "140px",
          }}
          key={i}
        >
          <img src={logo} alt={`clients_logo_${i}`} />
        </figure>
      ))}
    </div>
  );
};
