import React, { useContext } from 'react'
import { Navigation } from '../components/navigation/Navigation'
import { HeroSection } from '../components/navigation/HeroSection'
import {
  CareerPageHeroContainer,
  HeroTitleProjectsVariation,
  ServicePageHeroContainer
} from '../components/navigation/navigation.styled'
import { CareerHeroParagraph } from '../components/contact-us-components/contact-us.styled'
import { JobOfferingComponent } from '../components/contact-us-components/JobOfferingComponent'
import { Benefits } from '../components/contact-us-components/Benefits'
import { Offices } from '../components/contact-us-components/Offices'
import { clipPathVariable } from '../constants/constants'
import { Context } from '../context/JobsContext'
import { Context as OfficeTittleContext } from '../context/AboutPageDataContext'
import { Context as CareerPageContext } from '../context/CareerPageDataContext'
import { Team } from '../components/about-us-components/Team'
import { useMediaQuery } from 'react-responsive'
import { Helmet } from 'react-helmet'

export const CareerPage = () => {
  const breakpoint = useMediaQuery({ maxWidth: 850 })
  const { aboutpage } = useContext(OfficeTittleContext)
  const { jobs } = useContext(Context)
  const { careerpage } = useContext(CareerPageContext)
  const handleClick = () => {
    window.location.href = 'mailto:career@moxelle.com'
  }
  return (
    <>
      <Helmet>
        <title>Moxelle | Career</title>
        <meta
          name='description'
          content='Moxelle is an IT recruitment and digital solutions company with over 7 years of experience. We have a proven track record of success in helping businesses of all sizes find the right talent and achieve their goals.'
        />
      </Helmet>
      <Navigation>
        <HeroSection
          clipPath={clipPathVariable}
          url={
            'https://res.cloudinary.com/dfolafoax/image/upload/v1695120752/career-bg-img_osisp0.webp'
          }
        >
          <CareerPageHeroContainer
            style={{
              justifyContent: breakpoint ? 'center' : 'space-between',
              marginTop: breakpoint ? '36px' : undefined,
              alignItems: 'flex-start'
            }}
          >
            <div>
              <HeroTitleProjectsVariation
                whitespace
                dangerouslySetInnerHTML={{
                  __html: careerpage ? careerpage.banner_title : ''
                }}
              />
            </div>
            <div style={{ flex: '0 0 55%' }}>
              {careerpage && (
                <>
                  <CareerHeroParagraph>
                    {careerpage.banner_paragraphOne}
                  </CareerHeroParagraph>
                  <CareerHeroParagraph>
                    {careerpage.banner_paragraphTwo}
                  </CareerHeroParagraph>
                  <CareerHeroParagraph>
                    {careerpage.banner_paragraphThree}
                  </CareerHeroParagraph>
                </>
              )}
            </div>
          </CareerPageHeroContainer>
        </HeroSection>
      </Navigation>
      <JobOfferingComponent jobs={jobs} sideText='Job Offerings' />
      <Benefits
        benefits={careerpage ? careerpage.benefits_array : []}
        sideText='Benefits'
      >
        <p>
          If you haven't found the position you've been looking for, send us
          your cv at <span onClick={handleClick}>career@moxelle.com</span> and
          we will contact you once this position is open.
        </p>
      </Benefits>
      <Team />
      <Offices
        sideText='Offices'
        offices_title={aboutpage ? aboutpage.offices_title : ''}
        offices_desc={aboutpage ? aboutpage.offices_desc : ''}
      />
    </>
  )
}
