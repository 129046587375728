import React, { useContext } from 'react'
import { Navigation } from '../components/navigation/Navigation'
import { HeroSection } from '../components/navigation/HeroSection'
// import about_us_image from "./../img/about_us_section_img.svg";
import {
  HeroPretitle,
  HeroSectionContainer,
  HeroSubtitle,
  HeroTitle,
  PrimaryButton
} from '../components/navigation/navigation.styled'
import { Link } from 'react-router-dom'
import { AboutUsComponent } from '../components/about-us-components/AboutUsComponent'
import { ServicesComponent } from '../components/services-components/ServicesComponent'
import { ClientsPartnersComponent } from '../components/clients-and-partners/ClientsPartnersComponent'
import { ProjectsComponent } from '../components/project-components/ProjectsComponent'
import { clipPathVariable } from '../constants/constants'
import { LoadingComponent } from '../components/loading-component/LoadingComponent'
import { Context } from '../context/HomePageDataContext'
import { GetAQuoteComponent } from '../components/contact-us-components/GetAQuoteComponent'
import { Helmet } from 'react-helmet'
export interface setCurrentServiceInt {
  setCurrentService: (service: string) => void
}

export const HomePage = ({ setCurrentService }: setCurrentServiceInt) => {
  const { homepage } = useContext(Context)

  return (
    <>
      <Helmet>
        <title>Welcome to Moxelle</title>
        <meta
          name='description'
          content='Moxelle is an IT recruitment and digital solutions company with over 7 years of experience. We have a proven track record of success in helping businesses of all sizes find the right talent and achieve their goals.'
        />

        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=G-Z6G9WYFQT8'
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || []
          function gtag () {
            dataLayer.push(arguments)
          }
          gtag('js', new Date())

          gtag('config', 'G-Z6G9WYFQT8')
        `}
        </script>
      </Helmet>
      <Navigation>
        <HeroSection
          clipPath={clipPathVariable}
          url={
            'https://res.cloudinary.com/dhyfnlbyw/video/upload/v1701345463/moxelle-video_1_xkjyi4_zmwlkb.mp4'
          }
        >
          {!homepage ? (
            <LoadingComponent />
          ) : (
            <HeroSectionContainer>
              <HeroPretitle>{homepage.banner_pretitle}</HeroPretitle>
              <HeroTitle whitespace>
                Consider <span>IT</span> Done
              </HeroTitle>
              <HeroSubtitle>{homepage.banner_subtitle}</HeroSubtitle>
              <div
                style={{
                  margin: '0 auto',
                  width: '60%',
                  display: 'flex',
                  justifyContent: 'space-around'
                }}
              >
                <Link to='/get-a-quote'>
                  <PrimaryButton>Get A Quote</PrimaryButton>
                </Link>
              </div>
            </HeroSectionContainer>
          )}
        </HeroSection>
      </Navigation>
      <AboutUsComponent
        sideText='About Us'
        title={homepage ? homepage.about_us_title : ''}
        subtitle={homepage ? homepage.about_us_subtitle : ''}
        paragraphOne={homepage ? homepage.about_us_text_one : ''}
        paragraphTwo={homepage ? homepage.about_us_text_two : ''}
        paragraphThree={homepage ? homepage.about_us_text_three : ''}
        paragraphFour={homepage ? homepage.about_us_text_four : ''}
        img={
          'https://res.cloudinary.com/dfolafoax/image/upload/v1695121128/services-bg-img_leib7n.webp'
        }
      />
      {homepage && (
        <>
          <ServicesComponent
            setCurrentService={setCurrentService}
            title={homepage.services_title}
          />
          <ClientsPartnersComponent
            title={homepage.testimonials_title}
            description={homepage.testimonials_description}
          />
          <ProjectsComponent
            projectsDescription={homepage.projects_description}
            title={homepage.projects_title}
          />
          <GetAQuoteComponent />
        </>
      )}
    </>
  )
}
