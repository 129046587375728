import React, { useContext, useState } from "react";
import axios from "axios";
import { AddEditFormWrapper, Form, Input } from "../form.styled";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PrimaryButton } from "../../navigation/navigation.styled";
import { PostsInt } from "../../blog-components/BlogPosts";
import { Context } from "../../../context/BlogContext";
import { LoadingComponent } from "../../loading-component/LoadingComponent";
import { apiRoute } from "../../../constants/constants";
interface AddEditBlogsProps {
  mode: string;
  setMode: React.Dispatch<React.SetStateAction<string>>;
  editBlog: PostsInt | undefined;
  setEditBlog: React.Dispatch<React.SetStateAction<PostsInt | undefined>>;
}
export const AddEditBlogs = ({
  setEditBlog,
  editBlog,
  mode,
  setMode,
}: AddEditBlogsProps) => {
  const { setBlogs } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [blogTitle, setblogTitle] = useState(
    editBlog && editBlog.blog_title ? editBlog.blog_title : ""
  );
  const [blogDesc, setblogDesc] = useState(
    editBlog && editBlog.blog_desc ? editBlog.blog_desc : ""
  );
  const [blogText, setblogText] = useState(
    editBlog && editBlog.blog_text ? editBlog.blog_text : ""
  );
  const [blogImg, setblogImg] = useState<any>(
    editBlog && editBlog.blog_img ? editBlog.blog_img : ""
  );
  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFileToBase(file);
    }
  };
  const setFileToBase = (file: File | Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setblogImg(reader.result);
    };
  };
  const resetForm = () => {
    setblogTitle("");
    setblogDesc("");
    setblogText("");
    setblogImg(undefined);
    setMode("");
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const postData = {
      blogTitle,
      blogDesc,
      blogImg,
      blogText,
    };
    if (mode === "create") {
      setIsLoading(true);
      await axios
        .post(`${apiRoute}api/posts`, postData)
        .then((res) => {
          //  console.log(res);
          resetForm();
          setBlogs(res.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else if (mode === "edit") {
      if (editBlog) {
        setIsLoading(true);
        await axios
          .put(`${apiRoute}api/posts/${editBlog._id}`, postData)
          .then((res) => {
            // console.log(res);
            resetForm();
            setBlogs(res.data);
          })
          .catch((err) => console.log(err))
          .finally(() => setIsLoading(false));
      }
    }
  };
  return (
    <>
      {isLoading && <LoadingComponent />}
      <AddEditFormWrapper>
        <button
          style={{
            zIndex:999999999,
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          onClick={() => {
            setMode("");
            setEditBlog(undefined);
          }}
        >
          <b>X</b>
        </button>
        <Form onSubmit={handleSubmit}>
          <h1>{editBlog ? "Edit" : "Add"} Blog</h1>
          <div>
            <label htmlFor="blogTitleId">Blog Title:</label>
            <Input
              type="text"
              id="blogTitleId"
              value={blogTitle}
              onChange={(e) => setblogTitle(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="blogDescId">Blog Description:</label>
            <Input
              id="blogDescId"
              value={blogDesc}
              onChange={(e) => setblogDesc(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="blogTextId">Blog Text:</label>
            <ReactQuill
              style={{ minHeight: "250px" }}
              id="blogTextId"
              value={blogText}
              onChange={(e) => setblogText(e)}
            />
          </div>
          <div>
            <label htmlFor="blogImgId">Blog Image:</label>
            {blogImg && blogImg.url ? (
              <img src={blogImg.url} alt="blog-img" />
            ) : blogImg !== "" ? (
              <img src={blogImg} alt="blog-img" />
            ) : null}
            <input
              accept="image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon"
              type="file"
              id="blogImgId"
              onChange={handleImage}
            />
          </div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </Form>
      </AddEditFormWrapper>
    </>
  );
};
