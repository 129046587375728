import React, { useContext, useState } from "react";
import {
  Button,
  Table,
  TableCell,
  TableHeader,
  TableImage,
  TableRow,
} from "../form.styled";
import { Context, TestimonialsInt } from "../../../context/TestimonialsContext";
import axios from "axios";
import { LoadingComponent } from "../../loading-component/LoadingComponent";
import { AddEditTestimonials } from "./AddEditTestimonials";

import { apiRoute } from "../../../constants/constants";

export const Testimonials = () => {
  
  const [mode, setMode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editTestimonial, setEditTestimonial] = useState<TestimonialsInt | undefined>();
  const { testimonials, setTestimonials } = useContext(Context);
  const handleEditMode = (id: string) => {
    setMode("edit");
    scrollToTop();
    setEditTestimonial(testimonials.find((testimonial) => testimonial._id === id));
  };
  const handleDelete = async (id: string) => {
    const confirmation = confirm(
      "The blog will be deleted from the database.Please confirm that you want to delete the blog"
    );

    if (confirmation) {
      setIsLoading(true);
      await axios
        .delete(`${apiRoute}api/testimonials/${id}`)
        .then((res) => {
          
          setTestimonials(res.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      {isLoading && <LoadingComponent />}
      <div
        style={{
          display: "flex",
          width: "100%",
          marginBottom: "10px",
          justifyContent: "flex-end",
        }}
      >
        <Button style={{marginRight:'35px'}}
          onClick={() => {
            setMode("create");
            scrollToTop();
          }}
        >
         + Add new testimonial
        </Button>
      </div>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Full Name</TableHeader>
            <TableHeader>Position</TableHeader>
            <TableHeader>Testimonial</TableHeader>
            <TableHeader>Company Logo</TableHeader>
            <TableHeader>Image</TableHeader>
            <TableHeader>Actions</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {testimonials &&
            testimonials.length > 0 &&
            testimonials.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.testimonial_name}</TableCell>
                <TableCell>{item.testimonial_position}</TableCell>
                <TableCell>{item.testimonial_text}</TableCell>
                <TableCell>
                  <TableImage src={item.testimonial_logo.url} alt={`Item${index}`} />
                </TableCell>
                <TableCell>
                  <TableImage src={item.testimonial_image.url} alt={`Item${index}`} />
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleEditMode(item._id)}>Edit</Button>
                  <Button bg onClick={() => handleDelete(item._id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </tbody>
      </Table>
      {mode !== "" && (
        <AddEditTestimonials
          setEditTestimonial={setEditTestimonial}
          editTestimonial={editTestimonial}
          setMode={setMode}
          mode={mode}
        />
      )}
    </>
  );
};