import React from "react";
import { createContext, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";

import { apiRoute } from "../constants/constants";

export interface GalleryInt {
  gallery_img: { public_id: string; url: string };
  _id: string;
  }

export interface ContextData {
  gallery: GalleryInt[];
  setGallery: (gallery: GalleryInt[]) => void;
  isLoading:boolean;
  error:string;
}

export interface Props {
  children: React.ReactNode;
}


export const Context = createContext({} as ContextData);

export const Provider = ({ children }: Props) => {
  
  const [gallery, setGallery] = useState<GalleryInt[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | AxiosError | any >("");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${apiRoute}api/gallery`)
      .then((res) => setGallery(res.data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Context.Provider
      value={{
        gallery,
        setGallery,
        isLoading,
        error,
      }}
    >
      {children}
    </Context.Provider>
  );
};
