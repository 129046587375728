import React from "react";
export interface IconProps {
  color?: string;
}
export const Digital = ({ color }: IconProps) => {
  return (
    <svg
      style={{ display: "block" }}
      width="100%"
      height="37"
      viewBox="0 0 34 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 36.5C2.2 36.5 1.5 36.2 0.9 35.6C0.3 35 0 34.3 0 33.5V3.5C0 2.7 0.3 2 0.9 1.4C1.5 0.8 2.2 0.5 3 0.5H26.1L36 10.4V33.5C36 34.3 35.7 35 35.1 35.6C34.5 36.2 33.8 36.5 33 36.5H3ZM3 33.5H33V12.0714H24.45V3.5H3V33.5ZM7.95 27.95H28.05V24.95H7.95V27.95ZM7.95 12.05H18V9.05H7.95V12.05ZM7.95 20H28.05V17H7.95V20Z"
        fill={color}
      />
    </svg>
  );
};
