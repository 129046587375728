import React, { ReactNode, useContext } from "react";
import { ThemeProvider } from "styled-components";
import {
  COLORS,
  COLORS_SECONDARY,
  FONT_WEIGHTS,
  NEGATIVE_VALUES,
  SIZES,
} from "../constants/constants";
import { useMediaQuery } from "react-responsive";
import { ThemeChangeProvider, ToggleContext } from "./ThemeContext";
interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProviderComponent = ({ children }: ThemeProviderProps) => {
  const isSmallMobile = useMediaQuery({ maxWidth: 320 });
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isPhablet = useMediaQuery({ maxWidth: 560 });
  const isTablet = useMediaQuery({ maxWidth: 768 });
  const isLaptop = useMediaQuery({ maxWidth: 970 });
  const isLargeLaptop = useMediaQuery({ maxWidth: 1090 });
  const isSmallDesktop = useMediaQuery({ maxWidth: 1280 });
  const isDesktop = useMediaQuery({ maxWidth: 1380 });
  const isLargeDesktop = useMediaQuery({ maxWidth: 1440 });
  const { toggleValue } = useContext(ToggleContext);
  const modifiedSizes = {
    ...SIZES,
    xSmall: "8px",
    small: "10px",
    smallMedium: "12px",
    mediumSmall: "14px",
    medium: isMobile ? "14px" : isLaptop ? "15px" : SIZES.medium,
    mediumLarge: isMobile
      ? "16px"
      : isTablet
      ? "17px"
      : isLaptop
      ? "18px"
      : isSmallDesktop
      ? "18px"
      : SIZES.mediumLarge,
    large: isMobile
      ? "18px"
      : isPhablet
      ? "18px"
      : isTablet
      ? "18px"
      : isLaptop
      ? "18px"
      : isSmallDesktop
      ? "18px"
      : SIZES.large,
    largeToExtra: isMobile
      ? "20px"
      : isPhablet
      ? "22px"
      : isTablet
      ? "22px"
      : isLaptop
      ? "22px"
      : isSmallDesktop
      ? "24px"
      : SIZES.largeToExtra,
    extraLarge: isSmallMobile
      ? "20px"
      : isMobile
      ? "21px"
      : isPhablet
      ? "22px"
      : isTablet
      ? "23px"
      : isLaptop
      ? "24px"
      : isLargeLaptop
      ? "26px"
      : isSmallDesktop
      ? "24px"
      : isDesktop
      ? "28px"
      : isLargeDesktop
      ? "28px"
      : SIZES.extraLarge,
    extraLarge2: isSmallMobile
      ? "30px"
      : isMobile
      ? "31px"
      : isPhablet
      ? "32px"
      : isTablet
      ? "32px"
      : isLaptop
      ? "32px"
      : isLargeLaptop
      ? "32px"
      : isSmallDesktop
      ? "32px"
      : isDesktop
      ? "32px"
      : isLargeDesktop
      ? "32px"
      : SIZES.extraLarge2,
    extraLarge3: isSmallMobile
      ? "26px"
      : isMobile
      ? "28px"
      : isPhablet
      ? "31px"
      : isTablet
      ? "32px"
      : isLaptop
      ? "32px"
      : isLargeLaptop
      ? "32px"
      : isSmallDesktop
      ? "32px"
      : isDesktop
      ? "32px"
      : isLargeDesktop
      ? "32px"
      : SIZES.extraLarge3,
    extraLarge4: isSmallMobile
      ? "30px"
      : isMobile
      ? "32px"
      : isPhablet
      ? "35px"
      : isTablet
      ? "37px"
      : isLaptop
      ? "40px"
      : isLargeLaptop
      ? "42px"
      : isSmallDesktop
      ? "42px"
      : isDesktop
      ? "42px"
      : isLargeDesktop
      ? "44px"
      : SIZES.extraLarge4,
    extraLarge5: isSmallMobile
      ? "34px"
      : isMobile
      ? "36px"
      : isPhablet
      ? "38px"
      : isTablet
      ? "40px"
      : isLaptop
      ? "42px"
      : isLargeLaptop
      ? "36px"
      : isSmallDesktop
      ? "40px"
      : isDesktop
      ? "44px"
      : isLargeDesktop
      ? "52px"
      : SIZES.extraLarge5,
    extraLarge6: isSmallMobile
      ? "40px"
      : isMobile
      ? "40px"
      : isPhablet
      ? "42px"
      : isTablet
      ? "48px"
      : isLaptop
      ? "48px"
      : isLargeLaptop
      ? "50px"
      : isSmallDesktop
      ? "50px"
      : isDesktop
      ? "56px"
      : isLargeDesktop
      ? "60px"
      : SIZES.extraLarge6,
    extraLarge7: isSmallMobile
      ? "32px"
      : isMobile
      ? "40px"
      : isPhablet
      ? "50px"
      : isTablet
      ? "52px"
      : isLaptop
      ? "56px"
      : isLargeLaptop
      ? "60px"
      : isSmallDesktop
      ? "64px"
      : isDesktop
      ? "68px"
      : isLargeDesktop
      ? "70px"
      : SIZES.extraLarge7,
  };

  const modifiedNegativeSizes = {
    ...NEGATIVE_VALUES,
    minusOne: isLaptop ? "-1px" : "-1px",
    minusTwo: isLaptop ? "-1px" : "-2px",
    minusThree: isLaptop ? "-2px" : "-3px",
    minusFour: isLaptop ? "1px" : "-4px",
    minusFive: isLaptop ? "-2px" : "-5px",
  };

  return (
    <ThemeProvider
      theme={{
        colors: toggleValue ? COLORS : COLORS_SECONDARY,
        sizes: modifiedSizes,
        negative_values: modifiedNegativeSizes,
        font_weights: FONT_WEIGHTS,
      }}
    >
      {children}
    </ThemeProvider>
  );
};

export default ThemeProviderComponent;
