import React, { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Form, Input } from "../form.styled";
import careerSvg from "./screens/career.svg";
import axios from "axios";
import { LoadingComponent } from "../../loading-component/LoadingComponent";
import {
  CareerpageDatainterface,
  Context,
} from "../../../context/CareerPageDataContext";

import { apiRoute } from "../../../constants/constants";

interface EditCareerPageProps {
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}

export const EditCareerPage = ({ setCurrentTab }: EditCareerPageProps) => {
  const { careerpage, setCareerpage } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = {
    banner_title: careerpage ? careerpage.banner_title : "",
    banner_paragraphOne: careerpage ? careerpage.banner_paragraphOne : "",
    banner_paragraphTwo: careerpage ? careerpage.banner_paragraphTwo : "",
    banner_paragraphThree: careerpage ? careerpage.banner_paragraphThree : "",
    benefits_array: careerpage ? careerpage.benefits_array : [],
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CareerpageDatainterface>({
    defaultValues: defaultValues,
  });

  const onSubmit: SubmitHandler<CareerpageDatainterface> = async (data) => { 
    if (careerpage) {

      setIsLoading(true);
      await axios
        .put(`${apiRoute}api/careerpage/${careerpage._id}`, data)
        .then((res) => {
          setCareerpage(res.data);
          reset();
          setCurrentTab("");
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <div style={{ display: "flex" }}>
          {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
          <Form style={{ maxWidth: "90%" }} onSubmit={handleSubmit(onSubmit)}>
            {/* register your input into the hook by invoking the "register" function */}
            <div>
              <h3>Banner Section</h3>
              <label htmlFor="banner_title">Banner title:</label>
              <Input id="banner_title" {...register("banner_title")} />

              <label htmlFor="banner_paragraphOne">Banner Paragraph One:</label>
              <Input
                id="banner_paragraphOne"
                {...register("banner_paragraphOne")}
              />
              <label htmlFor="banner_paragraphTwo">Banner Paragraph Two:</label>
              <Input
                id="banner_paragraphTwo"
                {...register("banner_paragraphTwo")}
              />
              <label htmlFor="banner_paragraphThree">
                Banner Paragraph Three:
              </label>
              <Input
                id="banner_paragraphThree"
                {...register("banner_paragraphThree")}
              />
              <p>Benefits List</p>
              {careerpage && careerpage.benefits_array &&
                careerpage.benefits_array.map((_, i) => (
                  <div key={i}>
                    <p>Benefit {i+1} name</p>
                    <Input
                      type="text"
                      placeholder="Name"
                      {...register(`benefits_array[${i}].name` as any)}
                    />
                      <p>Benefit {i+1} description</p>
                    <Input
                      type="text"
        
                      placeholder="Description"
                      {...register(`benefits_array[${i}].description` as any)}
                    />
                    <p>------------------------------------------------------</p>
                  </div>
                ))}
            </div>

            <Button type="submit">Submit Changes</Button>
          </Form>
          <div style={{ width: "350px", marginRight: "20px" }}>
            <img src={careerSvg} alt="homepage-screenshot" />
          </div>
        </div>
      )}
    </>
  );
};
