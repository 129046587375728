import React from "react";
import { createContext, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { apiRoute } from "../constants/constants";

export interface StatsInterface {
  Satisfied_Clients: string;
  Awards: string;
  Team_Members: string;
  Finished_Projects: string;
}
export interface AboutpageDatainterface {
  _id: string;
  banner_title: string;
  stats_paragraph_containerOne: string;
  stats_paragraph_containerTwo: string;
  offices_desc: string;
  offices_title: string;
}
export interface ContextData {
  aboutpage: AboutpageDatainterface | undefined;
  setAboutpage: (aboutpage: AboutpageDatainterface) => void;
  isLoading: boolean;
  error: string;
}

export interface Props {
  children: React.ReactNode;
}

export const Context = createContext({} as ContextData);

export const Provider = ({ children }: Props) => {
  const [aboutpage, setAboutpage] = useState<
    AboutpageDatainterface | undefined
  >();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | AxiosError | any>("");

  useEffect(() => {
    if (!aboutpage) {
      setLoading(true);
      axios
        .get(`${apiRoute}api/aboutpage`)
        .then((res) => setAboutpage(res.data))
        .catch((err) => setError(err))
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <Context.Provider
      value={{
        aboutpage,
        setAboutpage,
        isLoading,
        error,
      }}
    >
      {children}
    </Context.Provider>
  );
};
