import React, { useEffect, useRef, useState } from 'react'
import {
  Aside,
  ContainerWrapper,
  PrimaryButton,
  PrimaryButtonContainer,
  SocialList
} from '../navigation/navigation.styled'
import { SectionWrapper } from '../about-us-components/aboutus.styled'
import { PositionContainer } from '../contact-us-components/contact-us.styled'
import { useMediaQuery } from 'react-responsive'
import {
  BlogPostInnerElement,
  JobOfferingButton,
  JobOfferingWrapper
} from '../../style-utils/inline-styles'
import { Link } from 'react-router-dom'
import { socialListObject } from '../../constants/constants'

export interface PostsInt {
  _id: string
  blog_title: string
  blog_desc: string
  blog_img: { public_id: string; url: string }
  blog_text: string
}

interface Props {
  sideText?: string
  posts: PostsInt[]
}

export const BlogPosts = ({ posts }: Props) => {
  const breakpoint = useMediaQuery({ maxWidth: 823 })
  const [viewMorePosts, setViewMorePosts] = useState(
    posts.length < 9 ? posts.length : 9
  )
  const isTablet = useMediaQuery({ maxWidth: 900 })
  const [height, setHeight] = useState(500)
  const disableSidebar = useMediaQuery({ maxWidth: 1090 })
  const sectionRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null)

  useEffect(() => {
    if (sectionRef.current) {
      setHeight(sectionRef.current.clientHeight)
    }
  }, [])

  return (
    <ContainerWrapper>
      {!disableSidebar && (
        <Aside>
          <SocialList
            style={{
              position: 'relative',
              top: height > 1000 ? '500px' : '0px',
              left: '0'
            }}
          >
            {socialListObject.map((item, i) => (
              <li key={i}>
                <a href={item.url} target='_blank' rel='noopener noreferrer'>
                  {item.name}
                </a>
              </li>
            ))}
          </SocialList>
        </Aside>
      )}
      <SectionWrapper>
        <div
          ref={sectionRef}
          style={{
            ...JobOfferingWrapper,
            justifyContent: isTablet ? 'center' : 'flex-start',
            marginRight: breakpoint ? '0px' : '-50px'
          }}
        >
          {posts.slice(0, viewMorePosts).map((post, i) => (
            <PositionContainer style={{ height: '410px' }} key={i}>
              <div
                className='inner'
                style={{
                  ...BlogPostInnerElement,
                  marginBottom: i === posts.length - 1 ? '0px' : '50px',
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(${post.blog_img.url})`
                }}
              >
                <p className='posts-title'>{post.blog_title}</p>
                <p className='posts-desc'>{post.blog_desc}</p>
                <Link to={`/blog/${post._id}`}>
                  <PrimaryButton
                    style={{
                      ...JobOfferingButton,
                      left: '50%',
                      bottom: '-22px',
                      transform: 'translate(-50%)'
                    }}
                  >
                    Read More
                  </PrimaryButton>
                </Link>
              </div>
            </PositionContainer>
          ))}
        </div>
        {viewMorePosts !== posts.length && (
          <PrimaryButtonContainer>
            <PrimaryButton
              onClick={() =>
                setViewMorePosts(prev =>
                  prev + 3 > posts.length ? posts.length : (prev += 3)
                )
              }
            >
              View more posts
            </PrimaryButton>
          </PrimaryButtonContainer>
        )}
      </SectionWrapper>
    </ContainerWrapper>
  )
}
