export const options = [
  { value: "AWS", label: "AWS" },
  { value: "Google Cloud Platform", label: "Google Cloud Platform" },
  { value: "Azure", label: "Azure" },
  { value: "Firebase", label: "Firebase" },
  { value: "Django", label: "Django" },
  { value: "Ruby on Rails", label: "Ruby on Rails" },
  { value: "Flask", label: "Flask" },
  { value: "Express.js", label: "Express.js" },
  { value: "Angular", label: "Angular" },
  { value: "React", label: "React" },
  { value: "Vue.js", label: "Vue.js" },
  { value: "Node.js", label: "Node.js" },
  { value: "Java", label: "Java" },
  { value: "Python", label: "Python" },
  { value: "C++", label: "C++" },
  { value: "C#", label: "C#" },
  { value: "JavaScript", label: "JavaScript" },
  { value: "TypeScript", label: "TypeScript" },
  { value: "Ruby", label: "Ruby" },
  { value: "PHP", label: "PHP" },
  { value: "Swift", label: "Swift" },
  { value: "Kotlin", label: "Kotlin" },
  { value: "Go", label: "Go" },
  { value: "Rust", label: "Rust" },
  { value: "Scala", label: "Scala" },
  { value: "Haskell", label: "Haskell" },
  { value: "R", label: "R" },
  { value: "SQL", label: "SQL" },
  { value: "HTML", label: "HTML" },
  { value: "CSS", label: "CSS" },
  { value: "Eclipse", label: "Eclipse" },
  { value: "Visual Studio", label: "Visual Studio" },
  { value: "Xcode", label: "Xcode" },
  { value: "Android Studio", label: "Android Studio" },
  { value: "IntelliJ IDEA", label: "IntelliJ IDEA" },
  { value: "PyCharm", label: "PyCharm" },
  { value: "NetBeans", label: "NetBeans" },
  { value: "Sublime Text", label: "Sublime Text" },
  { value: "Atom", label: "Atom" },
  { value: "Visual Studio Code", label: "Visual Studio Code" },
  { value: "Jupyter Notebook", label: "Jupyter Notebook" },
  { value: "Git", label: "Git" },
  { value: "GitHub", label: "GitHub" },
  { value: "Bitbucket", label: "Bitbucket" },
  { value: "Docker", label: "Docker" },
  { value: "VirtualBox", label: "VirtualBox" },
  { value: "Vagrant", label: "Vagrant" },
  { value: "Postman", label: "Postman" },
  { value: "npm", label: "npm" },
  { value: "Yarn", label: "Yarn" },
  { value: "Gradle", label: "Gradle" },
  { value: "Maven", label: "Maven" },
  { value: "Webpack", label: "Webpack" },
  { value: "Babel", label: "Babel" },
  { value: "Jenkins", label: "Jenkins" },
  { value: "Travis CI", label: "Travis CI" },
  { value: "CircleCI", label: "CircleCI" },
  { value: "Ansible", label: "Ansible" },
  { value: "Chef", label: "Chef" },
  { value: "Puppet", label: "Puppet" },
  { value: "Salt", label: "Salt" },
  { value: "Heroku", label: "Heroku" },
  { value: "Software Development", label: "Software Development" },
  { value: "Web Development", label: "Web Development" },
  { value: "Mobile App Development", label: "Mobile App Development" },
  { value: "Artificial Intelligence", label: "Artificial Intelligence" },
  { value: "Data Science", label: "Data Science" },
  { value: "Machine Learning", label: "Machine Learning" },
  { value: "Cybersecurity", label: "Cybersecurity" },
  { value: "Cloud Computing", label: "Cloud Computing" },
  { value: "Big Data", label: "Big Data" },
  { value: "Database Management", label: "Database Management" },
  { value: "Network Administration", label: "Network Administration" },
  { value: "DevOps", label: "DevOps" },
  { value: "UI/UX Design", label: "UI/UX Design" },
  { value: "Game Development", label: "Game Development" },
  { value: "Embedded Systems", label: "Embedded Systems" },
  { value: "IT Project Management", label: "IT Project Management" },
  { value: "IT Consulting", label: "IT Consulting" },
  { value: "IT Support", label: "IT Support" },
  { value: "IT Infrastructure", label: "IT Infrastructure" },
  { value: "IT Operations", label: "IT Operations" },
  { value: "IT Governance", label: "IT Governance" },
  { value: "IT Risk Management", label: "IT Risk Management" },
  { value: "IT Audit", label: "IT Audit" },
  {
    value: "IT Training and Development",
    label: "IT Training and Development",
  },
  { value: "IT Sales and Marketing", label: "IT Sales and Marketing" },
  {
    value: "IT Research and Development",
    label: "IT Research and Development",
  },
  { value: "IT Quality Assurance", label: "IT Quality Assurance" },
  { value: "IT Procurement", label: "IT Procurement" },
  { value: "IT Compliance", label: "IT Compliance" },
  { value: "IT Strategy and Planning", label: "IT Strategy and Planning" },
  { value: "IT Architecture", label: "IT Architecture" },
  { value: "IT Service Management", label: "IT Service Management" },
  { value: "IT Help Desk", label: "IT Help Desk" },
  { value: "IT Change Management", label: "IT Change Management" },
  { value: "IT Asset Management", label: "IT Asset Management" },
  { value: "IT Vendor Management", label: "IT Vendor Management" },
  { value: "IT Documentation", label: "IT Documentation" },
];

export const profiles = [
  "Php/Symfony",
  "WordPress",
  "Java Spring",
  ".Net",
  "Sitecore",
  "Kotlin",
  "Angular",
  "Flutter",
  "Php/Laravel",
  "Shopware",
  "Spring Boot",
  "Unity",
  "Puthon/Django",
  "Swift",
  "React.js",
  "HTML",
  "Magento",
  "Typo3",
  "Umbraco",
  "Python/Flask",
  "Objective C",
  "Vue.js",
  "CSS",
  "Drupal",
  "Node.js",
  "Magnolia",
  "Sitefinity",
  "Wagtail",
  "React Native",
  "Typescript",
  "Javascript",
  "Others"
];

export const industries = [
  "Fintech",
  "FoodTech",
  "Cyber Security",
  "HealthTech",
  "E-commerce",
  "Travel and Hospitality",
  "Energy",
  "Automotive",
  "Others",
];

export const experienceLevel = [
  { name: "Beginner", desc: "(1-2 years of experience)" },
  { name: "Intermediate", desc: "(3-5 years of experience)" },
  { name: "Senior", desc: "(5-7 years of experience)" },
  { name: "Tech Lead", desc: "(7+ years of experience)" },
];

export const devRolesArr = [
  "Developers",
  "Designers",
  "Project and Product Managers",
  "Data Experts",
  "Quality Assurance",
];
export const devRolesArrVariation = [
  "Developers",
  "Projects and Product Managers",
  "Data Experts",
  "Quality Assurance",
  "Designers",
];
