import React, { useContext } from "react";
import {
  Aside,
  ContainerWrapper,
  SocialList,
} from "../components/navigation/navigation.styled";
import { COLORS, socialListObject } from "../constants/constants";
import {
  AboutSectionFlexContainer,
  SectionWrapper,
  Subtitle,
} from "../components/about-us-components/aboutus.styled";
import { useMediaQuery } from "react-responsive";
import { Context } from "../context/PrivacyPolicyDataContext";
import { Helmet } from "react-helmet";
import { PrivacyPolicyContainer } from "../components/contact-us-components/contact-us.styled";

export const PrivacyPolicy = () => {
  const { privacyPolicy } = useContext(Context);
  const breakPoint = useMediaQuery({ maxWidth: 1090 });
  return (
    <>
      <Helmet>
        <title>Moxelle | Privacy Policy</title>
        <meta name="description" content="Moxelle privacy policy page" />
      </Helmet>
      <ContainerWrapper style={{ backgroundColor: COLORS.background }}>
        <Aside style={{ minHeight: "800px" }}>
          {!breakPoint && (
            <SocialList
              style={{
                position: "relative",
                bottom: "-80px",
                left: "0",
              }}
            >
              {socialListObject.map((item, i) => (
                <li key={i}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {item.name}
                  </a>
                </li>
              ))}
            </SocialList>
          )}
        </Aside>
        <SectionWrapper
          style={{
            paddingTop: breakPoint ? "150px" : "100px",
            backgroundColor: COLORS.background,
            color: COLORS.light,
          }}
        >
          <Subtitle>{privacyPolicy && privacyPolicy.title}</Subtitle>
          <AboutSectionFlexContainer style={{ display: "block" }}>
            <PrivacyPolicyContainer
              dangerouslySetInnerHTML={{
                __html:
                  privacyPolicy && privacyPolicy.text ? privacyPolicy.text : "",
              }}
            ></PrivacyPolicyContainer>
          </AboutSectionFlexContainer>
        </SectionWrapper>
      </ContainerWrapper>
    </>
  );
};
