import React from "react";
import { ContainerWrapper } from "../navigation/navigation.styled";
import {
  AboutImage,
  SectionWrapper,
  ServiceImageContainer,
  SingleTechnologyIcon,
  TechnologyIconsContainer,
} from "../about-us-components/aboutus.styled";

import {
  AsideNoText,
  ServicesMainParagraph,
  TechnologiesParagraph,
} from "./services.styled";
import Python from "./../../img/programing-language-logos-colored/python.svg";
import JavaScript from "./../../img/programing-language-logos-colored/js.svg";
import React_JS from "./../../img/programing-language-logos-colored/react-color.svg";
import Php from "./../../img/programing-language-logos-colored/php.svg";
import Kotlin from "./../../img/programing-language-logos-colored/kotlin.svg";
import React_Native from "./../../img/programing-language-logos-colored/react-color.svg";
import Ionic from "./../../img/programing-language-logos-colored/ionic.svg";
import Flutter from "./../../img/programing-language-logos-colored/flutter.svg";
import AWS from "./../../img/cloud-logos/icons8-aws-logo.svg";
import Microsoft from "./../../img/cloud-logos/Microsoft_icon.svg";
import Miro from "./../../img/design-logos/miro-2.svg";
import Google_Cloud from "./../../img/cloud-logos/icons8-google-cloud.svg";
import Sketch from "./../../img/design-logos/Sketch_Logo.svg";
import Azure from "./../../img/cloud-logos/azure-1-logo-svgrepo-com.svg";
import Proto__io from "./../../img/design-logos/cdnlogo.com_proto-io.svg";
import Figma from "./../../img/design-logos/figma-1-logo-svg-vector.svg";
import Adobe from "./../../img/design-logos/ADBE.svg";
import { RelativeSubtitleLargeVariation } from "../project-components/projects.styled";
import { CSSProperties } from "styled-components";
import { useMediaQuery } from "react-responsive";

interface AbotUsComponentProps {
  title?: string;
  question?: string;
  answer?: string;
  expertise?: string;
  technologiesSection?: string;
  img?: string;
  css?: CSSProperties;
  space_between?: string;
}

export const ServiceListComponent = ({
  css,
  title,
  space_between,
  question,
  answer,
  expertise,
  technologiesSection,
  img,
}: AbotUsComponentProps) => {
  const breakpoint = useMediaQuery({ minWidth: 901 });
  const logosMobileArr = { Kotlin, React_Native, Ionic, Flutter };
  const logosWebArr = { Python, JavaScript, React_JS, Php };
  const cloudArr = { AWS, Azure, Google_Cloud, Microsoft };
  const designArr = { Adobe, Figma, Miro, Sketch, Proto__io };
  return (
    <ContainerWrapper>
      <AsideNoText />
      <SectionWrapper>
        {title && title.length > 0 ? (
          <RelativeSubtitleLargeVariation resizing top>
            {title}
            <span></span>
          </RelativeSubtitleLargeVariation>
        ) : null}
        <div style={css}>
          <div style={{ flexBasis: "40%" }}>
            <ServiceImageContainer>
              <AboutImage
                data-aos="zoom-in"
                style={{ height: "100%", objectFit: "cover" }}
                src={img}
                alt="people-in-office"
              />
            </ServiceImageContainer>
          </div>
          <div
            style={{
              flex: "0 1 55%",
              margin: space_between,
            }}
          >
            {/* <Subtitle data-aos="fade-in" style={{ marginTop: 0 }}>
              {subtitle}
            </Subtitle> */}
            <ServicesMainParagraph
              data-aos="fade-in"
              style={{
                marginTop: breakpoint ? "0px" : "24px",
              }}
            >
              {question}
            </ServicesMainParagraph>
            <ServicesMainParagraph data-aos="fade-in">
              {answer}
            </ServicesMainParagraph>
            <ServicesMainParagraph
              data-aos="fade-in"
              style={{ marginBottom: 0 }}
            >
              {expertise}
            </ServicesMainParagraph>
            {technologiesSection && (
              <>
                <TechnologiesParagraph data-aos="fade-in">
                  {technologiesSection !== "design"
                    ? "Technologies:"
                    : "Tools:"}
                </TechnologiesParagraph>
                <TechnologyIconsContainer data-aos="fade-in">
                  {Object.entries(
                    technologiesSection === "mobile"
                      ? logosMobileArr
                      : technologiesSection === "web"
                      ? logosWebArr
                      : technologiesSection === "design"
                      ? designArr
                      : cloudArr
                  ).map((logo, i) => (
                    <SingleTechnologyIcon key={i}>
                      <img src={logo[1]} alt={`${logo[0]}-logo`} />
                      <p>{logo[0].replaceAll("_", " ")}</p>
                    </SingleTechnologyIcon>
                  ))}
                </TechnologyIconsContainer>
              </>
            )}
            {/* {technologiesSection || title === "Design" ? (
              <Link data-aos="fade-in" to="/projects">
                <PrimaryButton style={{ marginTop: "35px" }}>
                  View Projects
                </PrimaryButton>
              </Link>
            ) : null} */}
          </div>
        </div>
      </SectionWrapper>
    </ContainerWrapper>
  );
};
