import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Form, Input } from "../form.styled";
import projects from "./screens/projects.svg";
import axios from "axios";
import { LoadingComponent } from "../../loading-component/LoadingComponent";
import {
  ProjectspageDatainterface,
  Context,
} from "../../../context/ProjectPageDataContext";

import { apiRoute } from "../../../constants/constants";
import ReactQuill from "react-quill";

interface EditProjectsPageProps {
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}

export const EditProjectsPage = ({ setCurrentTab }: EditProjectsPageProps) => {
  const { projectspage, setProductspage } = useContext(Context);
  const [bannerParagraphOne, setBannerParagraphOne] = useState(
    projectspage ? projectspage.banner_paragraphOne : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ProjectspageDatainterface>({
    defaultValues: {
      banner_title: projectspage ? projectspage.banner_title : "",
      banner_paragraphOne: projectspage ? projectspage.banner_paragraphOne : "",
      mobile_app_project_title: projectspage
        ? projectspage.mobile_app_project_title
        : "",
      mobile_app_project_description: projectspage
        ? projectspage.mobile_app_project_description
        : "",
      mobile_app_project_subtitle: projectspage
        ? projectspage.mobile_app_project_subtitle
        : "",
      ux_project_title: projectspage ? projectspage.ux_project_title : "",
      ux_project_description: projectspage
        ? projectspage.ux_project_description
        : "",
      ux_project_subtitle: projectspage ? projectspage.ux_project_subtitle : "",
      team_project_title: projectspage ? projectspage.team_project_title : "",
      team_project_description: projectspage
        ? projectspage.team_project_description
        : "",
      team_project_subtitle: projectspage
        ? projectspage.team_project_subtitle
        : "",
    },
  });
  const handeBannerParagraphValue = (content: string) => {
    setBannerParagraphOne(content);
  };
  // Register the stats paragraph content with react-hook-form
  useEffect(() => {
    register("banner_paragraphOne"); // Register the input field with the name "stats_paragraph_containerOne"
    setValue("banner_paragraphOne", bannerParagraphOne); // Set the initial value if needed
  }, [register, setValue, bannerParagraphOne]);

  const onSubmit: SubmitHandler<ProjectspageDatainterface> = async (data) => {
    if (projectspage) {
      setIsLoading(true);
      await axios
        .put(`${apiRoute}api/projectspage/${projectspage._id}`, data)
        .then((res) => {
          setProductspage(res.data);
          reset();
          setCurrentTab("");
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <div style={{ display: "flex" }}>
          {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
          <Form style={{ maxWidth: "90%" }} onSubmit={handleSubmit(onSubmit)}>
            {/* register your input into the hook by invoking the "register" function */}
            <div>
              <h3>Banner Section</h3>
              <label htmlFor="banner_title">Banner title:</label>
              <Input id="banner_title" {...register("banner_title")} />
              <label htmlFor="banner_paragraphOne">Banner Paragraph One:</label>
              <ReactQuill
                style={{ minHeight: "250px" }}
                value={bannerParagraphOne}
                onChange={handeBannerParagraphValue}
              />
              <label htmlFor="mobile_app_project_title">
                Mobile App Project Title
              </label>
              <Input
                id="mobile_app_project_title"
                {...register("mobile_app_project_title")}
              />
              <label htmlFor="mobile_app_project_subtitle">
                Mobile App Project Subtitle
              </label>
              <Input
                id="mobile_app_project_subtitle"
                {...register("mobile_app_project_subtitle")}
              />
              <label htmlFor="mobile_app_project_description">
                Mobile App Project Description
              </label>
              <Input
                id="mobile_app_project_description"
                {...register("mobile_app_project_description")}
              />
              <label htmlFor="ux_project_title">Ux Project Title</label>
              <Input id="ux_project_title" {...register("ux_project_title")} />
              <label htmlFor="ux_project_subtitle">Ux Project Subtitle</label>
              <Input
                id="ux_project_subtitle"
                {...register("ux_project_subtitle")}
              />{" "}
              <label htmlFor="ux_project_description">
                Ux Project Description
              </label>
              <Input
                id="ux_project_description"
                {...register("ux_project_description")}
              />
              <label htmlFor="team_project_title">Team Project Title</label>
              <Input
                id="team_project_title"
                {...register("team_project_title")}
              />
              <label htmlFor="team_project_subtitle">
                Team Project Subtitle
              </label>
              <Input
                id="team_project_subtitle"
                {...register("team_project_subtitle")}
              />
              <label htmlFor="team_project_description">
                Team Project Description
              </label>
              <Input
                id="team_project_description"
                {...register("team_project_description")}
              />
            </div>

            <Button type="submit">Submit Changes</Button>
          </Form>
          <div style={{ width: "350px", marginRight: "20px" }}>
            <img src={projects} alt="homepage-screenshot" />
          </div>
        </div>
      )}
    </>
  );
};
