import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../navigation/navigation.styled";

interface DeviceProps {
  links: { ios: string; android: string; desktop: string };
}

export const DeviceDetector = ({ links }: DeviceProps) => {
  const [deviceType, setDeviceType] = useState<string>("");

  useEffect(() => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen =
        (navigator as Navigator & { maxTouchPoints?: number }).maxTouchPoints! >
        0;
      const isIOS = () => {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
      };
      const isAndroid = () => {
        return /Android/i.test(navigator.userAgent);
      };
      if (isIOS()) {
        setDeviceType("IOS");
      } else if (isAndroid()) {
        setDeviceType("Android");
      }
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen =
        (navigator as Navigator & { msMaxTouchPoints: number })
          .msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia("(pointer:coarse)");
      if (mQ.matches) {
        hasTouchScreen = true;
      }
    }

    if (!hasTouchScreen) {
      setDeviceType("Desktop");
    }
  }, []);

  return (
    <div style={{ marginTop: "24px" }}>
      {links.android && links.android !== "/" && deviceType === "Android" && (
        <a href={links.android} target="_blank" rel="noopener noreferrer">
          <PrimaryButton>Download APP</PrimaryButton>
        </a>
      )}
      {links.android && links.android !== "/" && deviceType === "IOS" && (
        <a href={links.ios} target="_blank" rel="noopener noreferrer">
          <PrimaryButton>Download APP</PrimaryButton>
        </a>
      )}
      {links.desktop && links.desktop !== "/" && deviceType === "Desktop" && (
        <a href={links.desktop} target="_blank" rel="noopener noreferrer">
          <PrimaryButton>Visit Website</PrimaryButton>
        </a>
      )}
    </div>
  );
};
