import React, { useContext, useEffect, useState } from 'react'

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { AboutPage } from './pages/AboutPage'
import { HomePage } from './pages/HomePage'
import { ServicesPage } from './pages/ServicesPage'
import { ProjectsPage } from './pages/ProjectsPage'
import { BlogPage } from './pages/BlogPage'
import { CareerPage } from './pages/CareerPage'
import { ContactPage } from './pages/ContactPage'
import { Footer } from './components/footer/Footer'
import ScrollToTop from './components/ScrollToTop'
import { Navbar } from './components/navigation/Navbar'
import { ErrorPage } from './pages/ErrorPage'
import { ProtectedRoutes } from './components/admin-panel/protected'
import { AdminPanel } from './components/admin-panel/AdminPanel'
import { Login } from './components/admin-panel/login'
import { Context } from './context/HomePageDataContext'
// import { Register } from "./components/admin-panel/register";
import { GetQuotePage } from './pages/GetQuotePage'
import { PrivacyPolicy } from './pages/PrivacyPolicy'
import { ScrollToTopFunction } from './components/loading-component/ScrollToTopFunction'
import { IndividualBlog } from './pages/IndividualBlog'
import { IndividualJobPost } from './pages/IndividualJobPost'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

ReactGA.initialize('G-Z6G9WYFQT8')

const RouteHandler = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search
    })
  }, [location])

  return null
}

function App () {
  const { homepage } = useContext(Context)
  const [currentService, setCurrentService] = useState('')
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const appOrigin = window.location.origin
      navigator.serviceWorker
        .register('/service-worker.js', { scope: appOrigin })
        .then(registration => {
          // console.log("Service Worker registered:", registration);
        })
        .catch(error => {
          console.error('Service Worker registration failed:', error)
        })
    })
  }

  return (
    <BrowserRouter>
      <RouteHandler />
      <Navbar />
      <ScrollToTop />
      <Routes>
        {/* <Route path="/register" element={<Register/>}/> */}
        <Route path='/login' element={<Login />} />
        <Route element={<ProtectedRoutes />}>
          <Route path='/admin' element={<AdminPanel />} index />
        </Route>
        <Route
          path='/'
          element={<HomePage setCurrentService={setCurrentService} />}
        />
        <Route
          path='/home'
          element={<HomePage setCurrentService={setCurrentService} />}
        />
        <Route path='/about' element={<AboutPage />} />
        <Route
          path='/services'
          element={
            <ServicesPage
              setCurrentService={setCurrentService}
              currentService={currentService}
            />
          }
        />
        <Route path='/projects' element={<ProjectsPage />} />
        <Route path='/blog' element={<BlogPage />} />
        <Route path='/blog/:_id' element={<IndividualBlog />} />
        <Route path='/blog/*' element={<ErrorPage />} />
        <Route path='/career' element={<CareerPage />} />
        <Route path='/career/:_id' element={<IndividualJobPost />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/get-a-quote' element={<GetQuotePage />} />
        <Route path='/*' element={<Navigate to='/error' />} />
        <Route path='/error' element={<ErrorPage />} />
      </Routes>
      <ScrollToTopFunction />
      {homepage && <Footer />}
    </BrowserRouter>
  )
}

export default App
