import React from "react";
import { createContext, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";

import { apiRoute } from "../constants/constants";

export interface HomepageDatainterface {
    _id: string;
    banner_pretitle: string;
    banner_subtitle: string;
    about_us_title: string;
    about_us_subtitle: string;
    about_us_text_one: string;
    about_us_text_two: string;
    about_us_text_three: string;
    about_us_text_four: string;
    services_title: string;
    testimonials_title: string;
    testimonials_description: string;
    projects_title: string;
    projects_description: string;
    address_mk: string;
    address_us: string;
  }
export interface ContextData {
  homepage: HomepageDatainterface | undefined;
  setHomepage: (homepage: HomepageDatainterface) => void;
  isLoading:boolean;
  error:string;
}

export interface Props {
  children: React.ReactNode;
}


export const Context = createContext({} as ContextData);

export const Provider = ({ children }: Props) => {
  
  const [homepage, setHomepage] = useState<HomepageDatainterface | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | AxiosError | any >("");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${apiRoute}api/homepage`)
      .then((res) => setHomepage(res.data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Context.Provider
      value={{
        homepage,
        setHomepage,
        isLoading,
        error,
      }}
    >
      {children}
    </Context.Provider>
  );
};
