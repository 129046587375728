import React from 'react'
import styled from 'styled-components'
import { media } from '../../style-utils/media-queries'
export const StyledForm = styled.form`
  flex:0 0 40%;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: ${props => props.theme.colors.space_cadet};
   ${media.tabletToLaptop`
  flex:1 1 100%;
  width:80%;
  display:block;
  margin-bottom:30px;
  `}
  ${media.tablet`
  flex:1 1 100%;
  width:87%;
  margin-bottom:0;
  `}
  
  & > input,
  &  div  textarea{
    border: none;
    outline: none;
    border-radius:6px;
    padding: 10px 5px;
    font-weight: ${props => props.theme.font_weights.extraLight};
    font-size: ${props => props.theme.sizes.large};
    margin-bottom: 8px;
    line-height: 1.2;
    letter-spacing: ${props => props.theme.negative_values.minusOne};
    ${media.tabletToLaptop`
  flex:1 1 100%;
  display:block;
  width:calc(100% - 10px);
  `}
    &::-webkit-input-placeholder {
      font-family: "Montserrat", sans-serif;
    }

    &:-ms-input-placeholder {
      font-family: "Montserrat", sans-serif;
    }

    &:-moz-placeholder {
      font-family: "Montserrat", sans-serif;
    }

    &::-moz-placeholder {
      font-family: "Montserrat", sans-serif;
    }
  }
  & > div{
    position:relative;
    & textarea{
        width:calc(100% - 10px);
        resize:none;
    }
    &  input[type=submit] {
        position: absolute;
        right: -24px;
        bottom: -24px;
        color:${props => props.theme.colors.secondary};
        background: ${props => props.theme.colors.primary['500']};
        border-radius: 24px;
        padding: 13px 10px;
        outline: none;
        border: none;
        width:124px;
        font-weight: ${props => props.theme.font_weights.semiBold};
        font-size: ${props => props.theme.sizes.mediumSmall};
        line-height: 17px;
        transition: filter 0.2s ease-in-out;
        &:hover {
          filter: brightness(0.9);
        }
     ${media.tabletToLaptop`
     bottom: -32px;
    `}
  }
   
`

export const AddressInfoContainer = styled.div`
  align-self: flex-start;
  display: flex;
  flex: 1 0 30%;
  padding-left: 70px;
  margin-right: 100px;
  flex-direction: column;
  ${media.desktop`
  margin:0 0 30px 0;
  `}
  ${media.largeLaptop`
  margin:0 0 10px 0;
  `}
  ${media.tabletToLaptop`
  flex:1 1 100%;
  width:80%;
  padding-left: 40px;
  display:block;
  margin:0 0 30px 0;
  `}
  ${media.tablet`
  padding-left: 20px;
  width:90%;
`}
  ${media.phablet`
  padding-left:0;
  width:100%;
`}
  & > div {
    display: flex;

    & p,
    & a {
      margin-left: 30px;
      flex-grow: 0;
      flex-shrink: 0;
      font-weight: ${props => props.theme.font_weights.extraLight};
      font-size: ${props => props.theme.sizes.large};
      margin-bottom: 10px;
      line-height: 1.2;
      letter-spacing: ${props => props.theme.negative_values.minusOne};

      &:hover {
        transform: scale(1.02);
      }
      ${media.phablet`
     margin-left: 16px;
    `}
    }
  }
  & div:first-child {
    margin-bottom: 30px;
  }
`

export const CareerHeroParagraph = styled.p`
  font-weight: ${props => props.theme.font_weights.extraLight};
  font-size: ${props => props.theme.sizes.largeToExtra};
  line-height: 1.2;
  color: ${props => props.theme.colors.secondary};
  letter-spacing: ${props => props.theme.negative_values.minusOne};
  margin: 0 0 30px 0;
  ${media.tabletToLaptop`
  text-align:left;
  width:90%;
  margin:0 auto 30px;
`}
`

export const CareerDescription = styled.div`
  padding: 0 170px;
  ${media.laptop`
  padding: 0;
`}
  & > div > ul > li {
    font-weight: ${props => props.theme.font_weights.extraLight};
    margin-bottom: 8px;
    line-height: 1.2;
    font-size: ${props => props.theme.sizes.mediumLarge};
  }
  & > div > p {
    font-weight: ${props => props.theme.font_weights.extraLight};
    font-size: ${props => props.theme.sizes.mediumLarge};
    line-height: 1.2;
  }
`
export const PositionContainer = styled.div`
  flex: 0 0 33%;
  & .inner {
    min-width: 280px;
    border-radius: 24px;
    padding: 48px 40px;
    height: 200px;
    position: relative;
    margin-right: 50px;
    margin-bottom: 50px;
    color: ${props => props.theme.colors.secondary};
    background-color: ${props => props.theme.colors.space_cadet800};

    & .flexible {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
  & .posts-title {
    word-wrap: break-word;
    font-weight: ${props => props.theme.font_weights.semiBold};
    font-size: ${props => props.theme.sizes.extraLarge};
    line-height: 1.2;
    letter-spacing: ${props => props.theme.negative_values.minusOne};
  }
  & .posts-desc {
    margin-top: ${props => props.theme.sizes.largeToExtra};
    word-wrap: break-word;
    font-weight: ${props => props.theme.font_weights.neutral};
    font-size: ${props => props.theme.sizes.large};
    line-height: 1.2;
    letter-spacing: ${props => props.theme.negative_values.minusOne};
  }

  & h2 {
    pointer-events: none;
    font-weight: ${props => props.theme.font_weights.bold};
    font-size: ${props => props.theme.sizes.extraLarge2};
    line-height: 1.2;
    letter-spacing: ${props => props.theme.negative_values.minusTwo};
    margin: 0 0 40px 0;
  }
  & p {
    pointer-events: none;
    margin: 0;
    font-weight: ${props => props.theme.font_weights.regular};
    font-size: ${props => props.theme.sizes.mediumSmall};
    line-height: 1.2;
    letter-spacing: ${props => props.theme.negative_values.minusOne};
  }
  @media (max-width: 823px) {
    & .inner {
      margin-right: 0;
    }
    ${media.phablet`
    & .inner{
      min-width: 240px;
    }
    `}
  }
`
export const InfoParagraph = styled.div`
  font-weight: ${props => props.theme.font_weights.extraLight};
  font-size: ${props => props.theme.sizes.largeToExtra};
  line-height: 1.2;
  letter-spacing: ${props => props.theme.negative_values.minusOne};
  text-align: center;
  width: 85%;
  margin: 0 auto;
  & span {
    cursor: pointer;
    pointer-events: auto;
    text-decoration: underline;
  }
`

export const BenefitsList = styled.ul`
  padding: 56px 0;
  font-weight: ${props => props.theme.font_weights.extraLight};
  font-size: ${props => props.theme.sizes.extraLarge2};
  line-height: 1.2;
  letter-spacing: ${props => props.theme.negative_values.minusTwo};
  & li {
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    list-style: none;

    &:hover {
      color: ${props => props.theme.colors.primary['500']};
    }
    @media (max-width: 980px) {
      &:hover {
        color: ${props => props.theme.colors.dark};
      }
    }
  }

  & li:before {
    margin-right: 40px;
    content: '·';
    font-size: 60px;
    line-height: 1.2;
  }
  li.active-list-item {
    color: ${props => props.theme.colors.primary['500']};
  }
  li p {
    letter-spacing: ${props => props.theme.negative_values.minusOne};
    color: ${props => props.theme.colors.dark};
    font-weight: ${props => props.theme.font_weights.extraLight};
    font-size: ${props => props.theme.sizes.large};
    line-height: 1.2;
  }
  li.active-list-item p {
    margin: 24px 0;
    padding-left: 32px;
  }
  ${media.tablet`
  padding: 32px 0;
   & li:before {
    margin-right: 20px;
    content: "·";
    font-size: 32px;
  }
  `}
  ${media.phablet`
  padding: 16px 0;
   & li {
    font-size:30px;
  }
  `}
     ${media.mobile`
   & li {
    font-size:26px;
  }
  `}
`

export const OfficesFlexContainer = styled.div`
  display: flex;
  ${media.smallDesktop`
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  `}
  ${media.laptop`
  padding-top:50px;
  `}
`
export const OfficesRightSide = styled.div`
  flex: 0 0 45%;
  margin-left: 5%;
  ${media.smallDesktop`
   margin-top:30px;
   margin-left:0;
   `}
  & h2 {
    font-weight: ${props => props.theme.font_weights.bold};
    font-size: ${props => props.theme.sizes.extraLarge5};
    line-height: 1.2;
    letter-spacing: ${props => props.theme.negative_values.minusTwo};
    margin: 0;
  }
  & h3 {
    font-weight: ${props => props.theme.font_weights.extraLight};
    font-size: ${props => props.theme.sizes.largeToExtra};
    line-height: 1.2;
    letter-spacing: ${props => props.theme.negative_values.minusTwo};
  }
`

export const AddressInfoVariation = styled(AddressInfoContainer)`
  padding-left: 0;
  & > div {
    display: flex;
    & p {
      font-weight: ${props => props.theme.font_weights.extraLight};
      font-size: ${props => props.theme.sizes.mediumLarge};
      margin-bottom: 10px;
      line-height: 1.2;
      letter-spacing: ${props => props.theme.negative_values.minusOne};
    }
  }
  & div:first-child {
    margin-bottom: 30px;
  }
  ${media.desktop`
  margin:0;
  `}
`

export const PrivacyPolicyContainer = styled.div`
  & h1,
  & h2,
  & h3 {
    color: ${props => props.theme.colors.primary['500']};
  }

  & p,
  & ul li {
    font-size: ${props => props.theme.sizes.mediumLarge};
    line-height: 1.2;
  }
  & ul li {
    margin-bottom: 12px;
  }
`
