import React, { useContext } from "react";
import { Navigation } from "../components/navigation/Navigation";
import { HeroSection } from "../components/navigation/HeroSection";
import {
  HeroTitleBlogVariation,
  ServicePageHeroContainer,
} from "../components/navigation/navigation.styled";
import { BlogPosts } from "../components/blog-components/BlogPosts";
import { useParams } from "react-router-dom";
import { SingleBlog } from "../components/blog-components/SingleBlog";
import { BlogSwiper } from "../components/blog-components/BlogSwiper";
import { Context } from "../context/BlogContext";
import { clipPathVariable } from "../constants/constants";
import { GetAQuoteComponent } from "../components/contact-us-components/GetAQuoteComponent";
import { Helmet } from "react-helmet";

export const BlogPage = () => {
  const { blogs } = useContext(Context);
  return (
    <>
      <Helmet>
        <title>Moxelle | Blog</title>
        <meta
          name="description"
          content="Moxelle is an IT recruitment and digital solutions company with over 7 years of experience. We have a proven track record of success in helping businesses of all sizes find the right talent and achieve their goals."
        />
      </Helmet>
      <Navigation>
        <HeroSection
          clipPath={clipPathVariable}
          url={
            "https://res.cloudinary.com/dfolafoax/image/upload/v1695120872/blog-bg-img_h1kdqy.webp"
          }
        >
          <ServicePageHeroContainer justifyContent>
            <div>
              <HeroTitleBlogVariation>Blog</HeroTitleBlogVariation>
            </div>
          </ServicePageHeroContainer>
        </HeroSection>
      </Navigation>
      {blogs.length > 0 && <BlogPosts posts={blogs} />}
      <GetAQuoteComponent />
    </>
  );
};
