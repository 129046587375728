import React, { useContext, useState } from "react";
import {
  Button,
  Table,
  TableCell,
  TableHeader,
  TableImage,
  TableRow,
} from "../form.styled";
import { Context } from "../../../context/ProjectsContext";
// import { AddEditBlogs } from "./addBlogs";
import { PostsInt } from "../../blog-components/BlogPosts";
import axios from "axios";
import { LoadingComponent } from "../../loading-component/LoadingComponent";
import { apiRoute } from "../../../constants/constants";
import { ProjectsInterface } from "../../project-components/IndividualProjectVariation";
import { AddEditProjects } from "./addEditProjects";

export const Projects = () => {
  const [mode, setMode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editProject, setEditProject] = useState<
    ProjectsInterface | undefined
  >();
  const { projects, setProjects } = useContext(Context);
  const handleEditMode = (id: string) => {
    setMode("edit");
    scrollToTop();
    setEditProject(projects.find((blog) => blog._id === id));
  };
  const handleDelete = async (id: string) => {
    const confirmation = confirm(
      "The project will be deleted from the database.Please confirm that you want to delete the blog"
    );

    if (confirmation) {
      setIsLoading(true);
      await axios
        .delete(`${apiRoute}api/projects/${id}`)
        .then((res) => {
          setProjects(res.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      {isLoading && <LoadingComponent />}
      <div
        style={{
          display: "flex",
          width: "100%",
          marginBottom: "10px",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{ marginRight: "35px" }}
          onClick={() => {
            setMode("create");
            scrollToTop();
          }}
        >
          + Add new project
        </Button>
      </div>
      <Table>
        {projects &&
          projects.length > 0 &&
          projects.map((item, index) => (
            <tbody key={index}>
              <TableRow>
                <TableCell>
                  <b>Project {index + 1}</b>
                </TableCell>
                <TableCell>
                  <b> - - - - - - - - - - - - - - - - - - - - - - - - - - -</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Title</b>
                </TableCell>
                <TableCell>{item.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Side text</b>
                </TableCell>
                <TableCell>{item.side_text}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Company Profile</b>
                </TableCell>
                <TableCell
                  dangerouslySetInnerHTML={{ __html: item.company_profile }}
                ></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Project Goals</b>
                </TableCell>
                <TableCell
                  dangerouslySetInnerHTML={{ __html: item.project_goals }}
                ></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Technologies</b>
                </TableCell>
                <TableCell>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                  >
                    {item.technologies.map((tech, i) => (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p>{tech.name}</p>
                        <img
                          style={{
                            margin: "0 auto",
                            width: "30px",
                            height: "30px",
                          }}
                          src={tech.value.url}
                          alt={`logos${i + 1}`}
                        />
                      </div>
                    ))}
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Android Link</b>
                </TableCell>
                <TableCell>{item.links.android}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>IOS Link</b>
                </TableCell>
                <TableCell>{item.links.ios}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Web Link</b>
                </TableCell>
                <TableCell>{item.links.desktop}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Images</b>
                </TableCell>
                <TableCell>
                  {item.images.length > 0 &&
                    item.images.map((src, i) => (
                      <TableImage
                        style={{ marginRight: "10px", objectFit: "cover" }}
                        key={i}
                        src={src.url}
                        alt={`Item${i}`}
                      />
                    ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Actions</b>
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleEditMode(item._id)}>Edit</Button>
                  <Button bg onClick={() => handleDelete(item._id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            </tbody>
          ))}
      </Table>
      {mode !== "" && (
        <AddEditProjects
          setEditProject={setEditProject}
          editProject={editProject}
          setMode={setMode}
          mode={mode}
        />
      )}
    </>
  );
};
