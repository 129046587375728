import React, { CSSProperties, useContext, useState } from "react";
import { COLORS, COLORS_SECONDARY } from "../../../constants/constants";
import { ToggleContext } from "../../../theme/ThemeContext";

export const FacebookIcon = () => {
  const {toggleValue} = useContext(ToggleContext)
  const [isHovered, setIsHovered] = useState(false);
 
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const iconFilltransition: CSSProperties = {
    margin: "0",
    cursor: "pointer",
    transition: "fill 0.3s ease-in",
    fill: isHovered ? COLORS.light : toggleValue ? COLORS.primary["500"]: COLORS_SECONDARY.primary['500'],
  };
  return (
    <a
      href="https://www.facebook.com/moxelle"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Moxelle facebook page"
    >
      <svg
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={iconFilltransition}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <mask x="0" y="0" width="13" height="27">
          <path d="M0 0H12.5V26.1733H0V0Z" style={iconFilltransition} />
        </mask>
        <g mask="url(#mask0_825_833)">
          <path
            d="M9.61625 4.31085H12.5V0.00341797H8.81764V0.019869C3.97641 0.195621 2.97801 2.88235 2.89488 5.72262H2.88379V8.7115H0V13.0244H2.88379V26.1331H8.05783V13.0244H11.7679L12.5 8.7115H8.05783V6.03601C8.05783 5.08542 8.70126 4.31085 9.61625 4.31085Z"
            style={iconFilltransition}
          />
        </g>
      </svg>
    </a>
  );
};
