import React from "react";
import { createContext, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";

import { apiRoute } from "../constants/constants";

export interface TestimonialsInt {
  _id: string;
  testimonial_text: string;
  testimonial_position: string;
  testimonial_name: string;
  testimonial_logo: { public_id: string; url: string };
  testimonial_image: { public_id: string; url: string };
  index:string
}

export interface ContextData {
  testimonials: TestimonialsInt[];
  setTestimonials: (testimonials: TestimonialsInt[]) => void;
  isLoading: boolean;
  error: string;
}

export interface Props {
  children: React.ReactNode;
}

export const Context = createContext({} as ContextData);

export const Provider = ({ children }: Props) => {
  
  const [testimonials, setTestimonials] = useState<TestimonialsInt[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | AxiosError | any>("");

  useEffect(() => {
    if (testimonials.length === 0) {
      setLoading(true);
      axios
        .get(`${apiRoute}api/testimonials`)
        .then((res) => setTestimonials(res.data))
        .catch((err) => setError(err))
        .finally(() => setLoading(false));
    }
  }, []);
  return (
    <Context.Provider
      value={{
        testimonials,
        setTestimonials,
        isLoading,
        error,
      }}
    >
      {children}
    </Context.Provider>
  );
};
