import styled from 'styled-components'
import React from 'react'
import { media } from '../../style-utils/media-queries'
export const CardWrapper = styled.div`
  border-radius: 15px;
  height: 250px;
  background-color: ${props => props.theme.colors.light};
  position: relative;
  margin-top: 20px;
  padding: 45px 24px 0;
`
export const BracketsContainer = styled.div`
  position: absolute;
  left: 3px;
  top: -8px;
  zindex: 100;
`

export const CardTestimonial = styled.p`
  min-height: 100px;
  font-weight: ${props => props.theme.font_weights.regular};
  font-size: ${props => props.theme.sizes.mediumSmall};
  line-height: 1.2;
  color: ${props => props.theme.colors.dark};
`
export const CardPosition = styled.p`
  font-weight: ${props => props.theme.font_weights.extraLight};
  font-size: ${props => props.theme.sizes.mediumSmall};
  line-height: 1.2;
  text-align: right;
  color: ${props => props.theme.colors.primary['500']};
  margin-bottom: 4px;
`

export const CardFullName = styled.p`
  font-weight: ${props => props.theme.font_weights.extraBold};
  font-size: ${props => props.theme.sizes.medium};
  line-height: 1.2;
  letter-spacing: ${props => props.theme.negative_values.minusOne};
  text-align: right;
  color: ${props => props.theme.colors.primary['500']};
`
export const CardCompanyLogo = styled.div`
  display: flex;
  max-height: 60px;
  justify-content: flex-end;
  & img {
    width: 100px;
  }
`
export const CardImageContainer = styled.div`
  width: 100px;
  border-radius: 50%;
  position: absolute;
  bottom: 70px;
  left: 16px;
  & img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    inset: 0;
    border-radius: 50%;
    border: 2px solid white;
    position: absolute;
    overflow: hidden;
  }
`
export const SectionDescription = styled.h2`
  padding: ${props => props.theme.sizes.extraLarge2}
    ${props => props.theme.sizes.extraLarge6};
  font-weight: ${props => props.theme.font_weights.extraLight};
  font-size: ${props => props.theme.sizes.extraLarge2};
  line-height: 1.2;
  letter-spacing: ${props => props.theme.negative_values.minusOne};
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1357px;
  ${media.tablet`
padding: 24px 8px;
font-size:25px;
`}
`

export const BlogTextContainer = styled.div`
  padding: 0 170px;
  ${media.laptop`
    padding: 0;
  `}
  & img {
    float: left;
    width: 400px;
    object-fit: contain;
    object-position: center;
    margin-right: 36px;
    margin-bottom: 36px;
    border-radius: 24px;
  }
  & > div > ul > li {
    margin-bottom: 8px;
    line-height: 1.2;
    font-weight: ${props => props.theme.font_weights.extraLight};
  }
  & > div > p {
    font-weight: ${props => props.theme.font_weights.extraLight};
    font-size: ${props => props.theme.sizes.mediumLarge};
    line-height: 1.2;
  }

  ${media.tabletToLaptop`
  & img{
  width:100%;
 
  }
  `}
  ${media.mobile`
  & img{
    margin-left:-24px;
   border-radius:0;
  width:calc(100% + 48px);
  }
  `}
`
