import styled from "styled-components";
import { media } from "../../../style-utils/media-queries";
import { COLORS } from "../../../constants/constants";

export const BodyContentWrapper = styled.div`
  //  margin-bottom: 20px;
  min-height: 300px;
  //  border-radius: 24px;
  //  padding:1px 12px;
  //  background: ${(props) => props.theme.colors.space_cadet};
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.light};
  & .inner-bar {
    border-radius: 10px;
    height: 20px;
    background-color: ${(props) => props.theme.colors.primary["500"]};
  }
`;

export const CustomBtn = styled.button`
  border-radius: 24px;
  border: none;
  cursor: pointer;
  min-width: 100px;
  padding: 10px 16px;
  margin: auto 10px 10px;
  background-color: ${(props) => props.theme.colors.primary["500"]};
  outline: none;
  color: ${(props) => props.theme.colors.light};
  transition: 0.3s ease-in;
  position: relative;
  display: inline-block;

  & .tooltiptext {
    font-weight: ${(props) => props.theme.font_weights.regular};
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translate(-50%);
    visibility: hidden;
    width: 120px;
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.primary["500"]};
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.primary["600"]};
  }

  &:hover:disabled .tooltiptext {
    visibility: visible;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const RadioInput = styled.input`
  opacity: 0;
  position: absolute;
  &:checked + label::after {
    background-color: ${(props) => props.theme.colors.primary["500"]};
  }
`;

export const RadioLabel = styled.label`
  position: relative;
  flex-shrink: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.colors.light}; /* Change this color as needed */
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: transparent;
  }
`;

export const ModalWrapper = styled.div<{ changeWidth?: boolean }>`
  box-sizing: border-box;
  padding: 24px;
  transition: width 0.17s ease-in-out;
  border-radius: 24px;
  border: 1px solid ${(props) => props.theme.colors.primary["500"]};
  width: ${(props) => (props.changeWidth ? "100%" : "85%")};
  margin: 0 auto;
  max-width:1157px;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgb(38, 52, 78);
  justify-content: center;
  @media (max-width: 799px) {
    width: 100%;
  }
  @media (min-width: 800px) {
    align-items: flex-start;
  }
`;

export const ModalContent = styled.div`
  flex-grow: 1;
  // min-height: 65vh;
  & .labels {
    cursor: pointer;
    pointer-events: all;
    margin-left: 16px;
    margin-bottom: 0;
    font-weight: ${(props) => props.theme.font_weights.medium};
    font-size: ${(props) => props.theme.sizes.large};
    line-height: 1.2;
  }
  & .labels,
  & h2 {
    color: ${(props) => props.theme.colors.light};
  }
  @media (max-width: 800px) {
    width: 100%;
    margin-top: 0;
    // min-height: 100vh;
    border-radius: 0;
    box-shadow: none;
  }
`;

export const CloseButton = styled.button`
  margin: 0 0 12px auto;
  width: 32px;
  line-height: 32px;
  padding: 0;
  height: 32px;
  color: ${(props) => props.theme.colors.secondary};
  background-color: ${(props) => props.theme.colors.primary["500"]};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 50%;
  & p {
    margin: 0 0 2px 0;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.primary["600"]};
  }
  @media (max-width: 800px) {
    margin: 0px 12px 16px auto;
  }
`;
export const SelectRoles = styled.div`
  & .css-13cymwt-control {
  }
`;
export const StyledInput = styled.input`
  border: 1px solid ${(props) => props.theme.colors.space_cadet};
  outline: none;
  border-radius: 6px;
  padding: 10px 5px;
  font-weight: ${(props) => props.theme.font_weights.extraLight
};
  font-size: ${(props) => props.theme.sizes.large};
  margin-bottom: 8px;
  line-height: 1.2;
  letter-spacing: ${(props) => props.theme.negative_values.minusOne};
  ${media.tabletToLaptop`
flex:1 1 100%;
display:block;
width:calc(100% - 10px);
`}
  &::-webkit-input-placeholder {
    font-family: "Montserrat", sans-serif;
  }

  &:-ms-input-placeholder {
    font-family: "Montserrat", sans-serif;
  }

  &:-moz-placeholder {
    font-family: "Montserrat", sans-serif;
  }

  &::-moz-placeholder {
    font-family: "Montserrat", sans-serif;
  }
`;
export const FormMainWrapper = styled.div`
  padding-bottom: 48px;
  width: 90%;
  margin: 0 auto;
  ${media.laptop`
width:80%;`}
  ${media.tablet`
width:90%;`};
`;
export const MainCategoryButtonsContainer = styled.div<{
  changeWidth?: boolean;
}>`
  margin: 32px auto 0;
  justify-content: center;
  gap: 24px;
  width: 100%;
  overflow: hidden;
  display: flex;
  @media (min-width: 1920px) {
    width: 70%;
  }
  @media (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
    margin-top: 0px;
  }
  ${media.tablet`
  width:90%;

`}
`;

export const CategoryButton = styled.div<{
  selected: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 260px;
  max-width: 285px;
  width: 100%;
  transition: width 3s cubic-bezier(0.4, 0, 0.6, 1);
  border-radius: 74px;
  border: 2px solid
    ${(props) => (props.selected ? "#26344e" : COLORS.primary["500"])};
  background: ${(props) =>
    props.selected
      ? "linear-gradient(180deg, #26344e 0%, #162136 100%)"
      : "linear-gradient(180deg, #d6fffd 0%, #fff 100%)"};
  flex-grow: 1;
  padding: 24px 1px;
  text-align: center;
  cursor: pointer;
  .dot {
    margin-right: 12px;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.light};
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  & > p {
    font-weight: ${(props) => props.theme.font_weights.medium};
    color: ${(props) =>
      props.selected
        ? props.theme.colors.light
        : props.theme.colors.primary["500"]};
    font-size: ${(props) => props.theme.sizes.largeToExtra};
    margin-bottom: 0;
  }
  @media (max-width: 1000px) {
    max-width: 100%;
  }
`;

export const StyledTextarea = styled.textarea`
  width: calc(100% - 10px);
  resize: none;
  border: 1px solid ${(props) => props.theme.colors.space_cadet};
  outline: none;
  border-radius: 6px;
  font-weight: ${(props) => props.theme.font_weights.extraLight
};
  font-size: ${(props) => props.theme.sizes.large};
  line-height: 1.2;
  letter-spacing: ${(props) => props.theme.negative_values.minusOne};
  padding: 10px 5px;
  margin-bottom: 8px;
  &::-webkit-input-placeholder {
    font-family: "Montserrat", sans-serif;
  }

  &:-ms-input-placeholder {
    font-family: "Montserrat", sans-serif;
  }

  &:-moz-placeholder {
    font-family: "Montserrat", sans-serif;
  }

  &::-moz-placeholder {
    font-family: "Montserrat", sans-serif;
  }
  ${media.tabletToLaptop`
  flex:1 1 100%;
  display:block;
  width:calc(100% - 10px);
  `}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const CheckboxInput = styled.input`
  opacity: 0;
  position: absolute;
  &:checked + label::after {
    background-color: ${(props) => props.theme.colors.primary["500"]};
  }
  &:checked + label::before {
    background-color: ${(props) => props.theme.colors.primary["500"]};
  }
`;

export const CheckboxLabel = styled.label`
  position: relative;
  flex-shrink: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 15%;
  border: 2px solid ${(props) => props.theme.colors.light}; /* Change this color as needed */
  cursor: pointer;
  ::before {
    position: absolute;
    left: -2px;
    bottom: 5px;
    height: 8px;
    width: 4px;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
    background-color: transparent;
  }

  ::after {
    position: absolute;
    left: 0;
    bottom: 3px;
    height: 4px;
    width: 100%;
    background-color: transparent;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
  }
`;

export const DisplayText = styled.p`
  text-align: center;
  margin: 10% auto;
  text-shadow: -1px 0px 0px ${(props) => props.theme.colors.primary["500"]},
    1px 0px 0px ${(props) => props.theme.colors.primary["500"]},
    0px -1px 0px ${(props) => props.theme.colors.primary["500"]},
    0px 1px 0px ${(props) => props.theme.colors.primary["500"]};
  font-weight: ${(props) => props.theme.font_weights.bold};
  color: ${(props) => props.theme.colors.light};
  font-size: ${(props) => props.theme.sizes.extraLarge5};
  ${media.laptop`
margin:55% auto;
`}
`;
