import React from "react";
import { Link } from "react-router-dom";
import { LogoContainer } from "./navigation.styled";
export const LogoWrapper = () => {
  return (
      <LogoContainer>
        <p>
          <span>M</span>oxelle
        </p>
        <p>
          consider <span>it</span> done
        </p>
      </LogoContainer>
  );
};
