import { css } from "styled-components";
import { sizes } from "./sizes";

export const media = {
  smallMobile: (styles: TemplateStringsArray) =>
    css`
      @media (max-width: ${sizes.smallMobile}) {
        ${styles}
      }
    `,
  mobile: (styles: TemplateStringsArray) =>
    css`
      @media (max-width: ${sizes.mobile}) {
        ${styles}
      }
    `,
  phablet: (styles: TemplateStringsArray) =>
    css`
      @media (max-width: ${sizes.phablet}) {
        ${styles}
      }
    `,
  smallTablet: (styles: TemplateStringsArray) =>
    css`
      @media (max-width: ${sizes.smallTablet}) {
        ${styles}
      }
    `,
  tablet: (styles: TemplateStringsArray) =>
    css`
      @media (max-width: ${sizes.tablet}) {
        ${styles}
      }
    `,
  tabletToLaptop: (styles: TemplateStringsArray) => css`
    @media (max-width: ${sizes.tabletToLaptop}) {
      ${styles}
    }
  `,
  laptop: (styles: TemplateStringsArray) =>
    css`
      @media (max-width: ${sizes.laptop}) {
        ${styles}
      }
    `,
  largeLaptop: (styles: TemplateStringsArray) =>
    css`
      @media (max-width: ${sizes.largeLaptop}) {
        ${styles}
      }
    `,
  smallDesktop: (styles: TemplateStringsArray) =>
    css`
      @media (max-width: ${sizes.smallDesktop}) {
        ${styles}
      }
    `,

  desktop: (styles: TemplateStringsArray) =>
    css`
      @media (max-width: ${sizes.desktop}) {
        ${styles}
      }
    `,
  largeDesktop: (styles: TemplateStringsArray) =>
    css`
      @media (min-width: ${sizes.largeDesktop}) {
        ${styles}
      }
    `,
};
